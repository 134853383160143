import * as React from 'react';
import {CircularProgress, Tooltip, Typography, Box} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    bottom: {
        color: '#e7e7e7',
        position: 'relative',
        left: 17,
    },
    box: {
        position: 'relative',
        top: 10
    }
}));

const CircularProgressWithLabel = ({color, value, state}) => {
    const classes = useStyles();
    return (
        <Box className={classes.box} sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress className={classes.bottom} size={35} variant="determinate" value={100}/>
            <CircularProgress style={{ color: color, position: 'relative', right: 17 }} className={classes.color}
                              size={35} variant="determinate" value={state === 'E' ? 100 : value}/>
            <Box
                sx={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography style={{position: 'relative', top: -30, left: 1}} variant="caption" component="div"
                            color="text.secondary">
                        <span style={{fontSize: 10, fontWeight: 'bold', color: color}}>
                            {`${Math.round(state === 'E' ? 100 : value)}`}
                        </span>
                    <span style={{fontSize: 7}}>%</span>
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;