import React, {useEffect, useState} from 'react';
import PaperComponent from "../../layout/PaperComponent";
import {Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {getLaunchHistory, removeLauncherHistory} from "../../actions/LauncherActions";
import {useDispatch} from "react-redux";
import {ThemeContext} from "../../App";
import {strings} from "../../i18n/Strings";

const DeleteComponent = ({data, setPage, classes, open, onClose, color}) => {

    const {_cancelBtn, _isDeleted, _delete, _attention} = strings;
    const dispatch = useDispatch();

    const onDelete = async id => {
        await dispatch(removeLauncherHistory(id));
        onClose();
        setTimeout(
            () => {
                setPage(0);
                dispatch(getLaunchHistory(0, true, {}));
            },
            4000
        );
    };

    return (
        <div>
            <Dialog
                BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.25)'}}}
                open={open}
                classes={{
                    paper: classes.dialogg
                }}
                fullWidth
                onClose={onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title">
                <DialogTitle
                    style={{cursor: 'move', color: '#fff', backgroundColor: color}}>
                    {_attention}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p style={{fontFamily: "Roboto"}}>
                            {_isDeleted} <b
                            style={{color: color}}>{data.titre && data.titre.toUpperCase()}</b> ?
                        </p>
                        <br/>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                style={{
                                    marginRight: '15px',
                                    color: '#fff',
                                    backgroundColor: color
                                }}
                                size={"large"}
                                variant="contained"
                                onClick={() => onDelete(data.id)}
                            >
                                {_delete}
                            </Button>
                            <Button
                                style={{
                                    width: 120,
                                    color: '#fff',
                                    backgroundColor: color
                                }}
                                size={"large"}
                                variant="contained"
                                onClick={onClose}
                                //startIcon={<SearchRoundedIcon/>}
                            >
                                {_cancelBtn}
                            </Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DeleteComponent;
