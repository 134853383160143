import React from 'react';
import {CircularProgress, Dialog, DialogContent, Grid} from "@material-ui/core";
import PaperComponent from "../layout/PaperComponent";
import {makeStyles} from '@material-ui/core/styles';
import reactCSS from 'reactcss';

const useStylesFacebook = makeStyles((theme) => ({
    circle: {
        strokeLinecap: 'round',
    },
}));

const Loader = (props) => {
    const {open, color} = props;
    const styles = useStylesFacebook();

    const classes = reactCSS({
        'default': {
            root: {
                marginLeft: '50px'
            },
            bottom: {
                color: '#e7e7e7',
                position: 'relative',
                left: 50,
                top: 13
            },
            top: {
                color: color,
                animationDuration: '550ms',
                position: 'relative',
                left: -70,
                top: 13
            },
            circle: {
                strokeLinecap: 'round',
            },
        }
    })

    return (
        <div style={classes.root}>
            <Dialog
                open={open}
                fullWidth
                PaperComponent={PaperComponent}
                BackdropProps={{style: {backgroundColor: 'rgba(247, 255, 253, 0.4)'}}}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    }
                }}
            >
                <DialogContent>
                    <Grid container>
                        <Grid item xs={5}/>
                        <Grid item xs={6}>
                            <CircularProgress
                                variant="indeterminate"
                                disableShrink
                                style={classes.top}
                                classes={{
                                    circle: styles.circle,
                                }}
                                size={120}
                                thickness={4}
                                {...props}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        </div>
    );
};

export default Loader;
