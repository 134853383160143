import React, {useState, useEffect, useRef} from 'react';
import {
    Box,
    Button,
    Grid,
    Table,
    TableBody,
    TableHead,
    TableRow,
    withStyles,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Dialog, Tooltip, Fab, FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";
import {Field, reduxForm} from "redux-form";
import {styles} from "../../styles/CommonStyles";
import {CustomTableCell} from "../evs/EVsComponent";
import PaperComponent from "../../layout/PaperComponent";
import DescriptionIcon from '@material-ui/icons/Description';
import FileCopyTwoTone from '@material-ui/icons/FileCopyTwoTone';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {useDispatch, connect} from "react-redux";
import ImportExportIcon from '@material-ui/icons/FilterList';
import {
    allFilters,
    exportLaunchHistory, getExtractByFilter,
    getLaunchDetail,
    getLaunchExport,
    getLaunchHistoryById,
    validateLaunchHistory
} from "../../actions/LauncherActions";
import moment from "moment";
import Loader from "../../layout/Loader";
import PaginationComponent from "../../layout/PaginationComponent";
import Csv from "../../assets/images/csv.png";
import Excel from "../../assets/images/exel-xxl.png";
import {ThemeContext} from "../../App";
import {strings} from "../../i18n/Strings";
import NotFound from "../404/NotFound";

const renderSelectComponent = ({input, label, variant, values, meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={variant}
        {...input}
        {...custom}
    >
        <MenuItem value="">
            <em>-- SÉLÉCTIONNER UN FILTER --</em>
        </MenuItem>
        {values && values.map(val =>
            <MenuItem key={val.id} value={val.id}>{val.label}</MenuItem>
        )}
    </Select>
);

const PayDetailComponent = ({classes, loading, list, match, columns, data, filters}) => {
    const [open, setOpen] = useState(false);
    const [filterId, setFilterId] = useState('');
    const [openFilter, setOpenFilter] = useState(false);
    const [action, setAction] = useState('');
    const [page, setPage] = useState(0);
    const [type, setType] = useState('M');
    const [menu, setMenu] = useState(false);
    const anchorRef = useRef(null);
    const dispatch = useDispatch();

    const {
        _excelExport, _attention, _cancel, _exportJ, _exportM, _isRejected, _noDataFound, _isValidated, _payExport
        , _rejectBtn, _title, _validBtn
    } = strings.payrollDetail;
    const handleToggle = () => {
        setMenu((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setMenu(false);
    };


    useEffect(() => {
        dispatch(getLaunchDetail(match.params.id, type));
        dispatch(getLaunchHistoryById(match.params.id));
        //dispatch(allFilters('GLOBAL_HOURS'));
    }, []);

    const onClose = () => {
        setOpen(false);
    };
    const onCloseFilter = () => {
        setOpenFilter(false);
    };
    const getDate = date => {
        return moment(date).format("YYYY-MM-DD")
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage - 1);
    };

    const getColumns = elements => {
        let rows = [];
        for (const i in elements) {
            rows.push(<CustomTableCell align="center" component="th" scope="row">{elements[i]}</CustomTableCell>)
        }
        return rows
    }

    const validate = async () => {
        await setOpen(false);
        await dispatch(validateLaunchHistory(match.params.id, action))
        dispatch(getLaunchHistoryById(match.params.id))
    }

    const getDateFormat = date => {
        let check = moment(date, 'YYYY/MM/DD');
        return check.format('M') + check.format('YYYY');
    }


    return (
        <ThemeContext.Consumer>
            {context => (
                <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12} id="grid">
                            <h1 style={{
                                marginTop: 1,
                                color: context.selectedTheme.color,
                                fontWeight: 'normal'
                            }}>{_title}</h1>
                        </Grid>
                        <Grid item sm={6} xs={12} id="grid" style={{position: 'relative', right: -20}}>
                            {list && list.length > 0 &&
                            (
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        style={{
                                            marginRight: '10px',
                                            color: '#fff',
                                            background: context.selectedTheme.color,
                                            borderColor: 'transparent'
                                        }}
                                        disabled={data && !(data.statut === 'CREATED')}
                                        variant={"outlined"}
                                        onClick={() => {
                                            setOpen(true);
                                            setAction('V');
                                        }}
                                        startIcon={<CheckCircleIcon/>}
                                    >{_validBtn}
                                    </Button>
                                    <Button
                                        style={{
                                            marginRight: 10,
                                            color: '#fff',
                                            background: context.selectedTheme.color,
                                            borderColor: 'transparent'
                                        }}
                                        disabled={data && !(data.statut === 'CREATED')}
                                        variant={"outlined"}
                                        onClick={() => {
                                            setOpen(true);
                                            setAction('R');
                                        }}
                                        startIcon={<CancelIcon/>}
                                    >{_rejectBtn}
                                    </Button>
                                    {/*<Button*/}
                                    {/*    style={{*/}
                                    {/*        background: context.selectedTheme.color,*/}
                                    {/*        color: '#fff',*/}
                                    {/*        borderColor: 'transparent'*/}
                                    {/*    }}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        dispatch(getLaunchDetail(match.params.id, type === 'J' ? 'M' : 'J'))*/}
                                    {/*        setType(prevState => {*/}
                                    {/*            return prevState === 'J' ? 'M' : 'J'*/}
                                    {/*        })*/}
                                    {/*    }}*/}
                                    {/*    variant={"outlined"}*/}
                                    {/*    startIcon={<DescriptionIcon/>}*/}
                                    {/*>{type !== 'J' ? _exportJ : _exportM}*/}
                                    {/*</Button>*/}
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                    <hr color="#f5f5f5"/>
                    <br/>
                    {list && list.length > 0 && (
                        <Box display="flex" justifyContent="flex-end">

{data.modulationTypesDTO.ruleDTO.countryType == 'FRANCE_MOD' ?
<Tooltip title="xlsx" aria-label="search">
                                <Fab size={"small"}
                                     style={{
                                         color: '#fff',
                                         marginRight: 6,
                                         background: context.selectedTheme.color
                                     }}
                                     // onClick={() => setOpenFilter(prevState => !prevState)}
                                    onClick={() => {
                                        dispatch(getLaunchExport(match.params.id, type === 'J' ? 'M' : 'J','xlsx'));
                                        setType(prevState => {
                                            return prevState === 'J' ? 'M' : 'J'
                                        });
                                    }}
                                >
                                    {/*<ImportExportIcon/>*/}
                                    <FileCopyTwoTone/>
                                </Fab>
                            </Tooltip>
:
<div></div>
}
                            <Tooltip title={type !== 'J' ? _exportJ : _exportM} aria-label="search">
                                <Fab size={"small"}
                                     style={{
                                         color: '#fff',
                                         marginRight: 6,
                                         background: context.selectedTheme.color
                                     }}
                                     // onClick={() => setOpenFilter(prevState => !prevState)}
                                    onClick={() => {
                                        dispatch(getLaunchDetail(match.params.id, type === 'J' ? 'M' : 'J'));
                                        setType(prevState => {
                                            return prevState === 'J' ? 'M' : 'J'
                                        });
                                    }}
                                >
                                    {/*<ImportExportIcon/>*/}
                                    <DescriptionIcon/>
                                </Fab>
                            </Tooltip>
                            <Tooltip title={_payExport} aria-label="search">
                                <Fab size={"small"}
                                     style={{
                                         color: '#fff',
                                         marginRight: 6,
                                         background: context.selectedTheme.color
                                     }}
                                     onClick={() => dispatch(exportLaunchHistory(match.params.id, 'PAY', type))}
                                >
                                    <img src={Csv} alt="" width={25} height={22}/>
                                </Fab>
                            </Tooltip>

                            <Tooltip title={_excelExport} aria-label="search">
                                <Fab size={"small"}
                                     style={{color: '#fff', background: context.selectedTheme.color}}
                                     onClick={() => dispatch(exportLaunchHistory(match.params.id, 'GLOBAL', type))}
                                >
                                    <img src={Excel} alt="" width={25} height={22}/>
                                </Fab>
                            </Tooltip>
                        </Box>)}
                    <br/>
                    <Table className={classes.table}>
                        <TableHead style={{color: '#fff', background: context.selectedTheme.color}}>
                            <TableRow>
                                {columns && columns.map(column =>
                                    <CustomTableCell align="center">{column}</CustomTableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list && list.slice(page * 10, (page + 1) * 10).map(row => (
                                <TableRow className={classes.row} key={row.id}>
                                    {getColumns(row)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <PaginationComponent color={context.selectedTheme.color} rows={list} page={page} classes={classes}
                                         handleChangePage={handleChangePage}/>

                    {list && list.length === 0 && <NotFound msg={_noDataFound} />}

                    <Loader color={context.selectedTheme.color} open={loading}/>

                    <Dialog
                        BackdropProps={{style: {background: 'rgba(0,0,0,0.25)'}}}
                        open={openFilter}
                        classes={{
                            paper: classes.dialog
                        }}
                        fullWidth
                        onClose={onCloseFilter}
                        PaperComponent={PaperComponent}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogTitle style={{cursor: 'move', background: context.selectedTheme.color}}>
                            <span
                                style={{color: '#fff', fontWeight: 'normal', fontSize: 16}}>EXTRACTION PAR FILTER</span>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <br/>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControl size={"small"} variant={"standard"} fullWidth>
                                            <InputLabel>
                                                FILTER
                                            </InputLabel>
                                            <Field name="filter" label="FILTER" variant={'standard'}
                                                   values={filters} onChange={(e) => setFilterId(e.target.value)}
                                                   component={renderSelectComponent}/>
                                        </FormControl>
                                        <br/><br/>
                                    </Grid>
                                </Grid>
                                <br/>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        style={{
                                            marginRight: 15,
                                            color: '#fff',
                                            background: context.selectedTheme.color
                                        }}
                                        size={"large"}
                                        variant="contained"
                                        onClick={() => {
                                            dispatch(getExtractByFilter(filterId, match.params.id));
                                            setOpenFilter(false);
                                        }}
                                        endIcon={<CheckCircleIcon/>}
                                    >{_validBtn}
                                    </Button>
                                    <Button
                                        style={{
                                            width: 120,
                                            color: '#fff',
                                            background: context.selectedTheme.color
                                        }}
                                        size={"large"}
                                        variant="contained"
                                        endIcon={<ArrowRightAltIcon/>}
                                        onClick={onClose}>{_cancel}
                                    </Button>
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>


                    <Dialog
                        BackdropProps={{style: {background: 'rgba(0,0,0,0.25)'}}}
                        open={open}
                        classes={{
                            //paper: classes.dialog
                        }}
                        fullWidth
                        onClose={onClose}
                        PaperComponent={PaperComponent}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogTitle style={{cursor: 'move', color: '#fff', background: context.selectedTheme.color}}>
                            {_attention}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                <p style={{fontFamily: "Varela Round"}}>{action === 'V' ? _isValidated : _isRejected}</p>
                                <br/>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        style={{
                                            marginRight: 15,
                                            color: '#fff',
                                            background: context.selectedTheme.color
                                        }}
                                        size={"large"}
                                        variant="contained"
                                        onClick={validate}
                                        endIcon={action === 'V' ? <CheckCircleIcon/> : <CancelIcon/>}
                                    >{action === 'V' ? _validBtn : _rejectBtn}
                                    </Button>
                                    <Button
                                        style={{

                                            width: 120,
                                            color: '#fff',
                                            background: context.selectedTheme.color
                                        }}
                                        size={"large"}
                                        variant="contained"
                                        endIcon={<ArrowRightAltIcon/>}
                                        onClick={onClose}>{_cancel}
                                    </Button>

                                </Box>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>

                </div>
            )}
        </ThemeContext.Consumer>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.PayrollEngineReducer.loading,
        list: state.PayrollEngineReducer.details,
        data: state.PayrollEngineReducer.data,
        columns: state.PayrollEngineReducer.columns,
        filters: state.PayrollEngineReducer.filters
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: 'payDetail',
    enableReinitialize: true
})(withStyles(styles)(PayDetailComponent)));
