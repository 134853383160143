const initialState = {
    pays: [],
    counters: [],
    totalElements: 0,
    codes: [],
    page: 0,
    id: null,
    payss: '',
    population: '',
    code: '',
    description: '',
    valeur: '',
    loading: false,
    onDelete: false,
    onSave: false,
    blocks: [
        {
            id: 1,
            code: 'PLANNED',
            label: 'Planifié',
        }, {
            id: 2,
            code: 'PROCESSED',
            label: 'Réalisé',
        }, {
            id: 3,
            code: 'VALIDATED',
            label: 'Réalisé Validé',
        },
    ],
    blockMotifs: [
        {
            block: 'PLANNED',
            motifs: [
                {
                    id: 1,
                    code: 'PRODUCTION',
                    label: 'Production'
                }, {
                    id: 2,
                    code: 'SI_FORMATION',
                    label: 'Formation'
                }, {
                    id: 3,
                    code: 'HOLIDAY',
                    label: 'Congé'
                }, {
                    id: 4,
                    code: 'DAY_OFF',
                    label: 'Day-off'
                },
            ],
        }, {
            block: 'VALIDATED',
            motifs: [
                {
                    id: 1,
                    code: 'PRODUCTION',
                    label: 'Production'
                }, {
                    id: 2,
                    code: 'SI_FORMATION',
                    label: 'Formation'
                }, {
                    id: 3,
                    code: 'ALLAITEMENT',
                    label: 'Allaitement'
                }, {
                    id: 4,
                    code: 'DECHARGE',
                    label: 'Décharge'
                }, {
                    id: 5,
                    code: 'DESTAFF',
                    label: 'Déstaff'
                }, {
                    id: 6,
                    code: 'PROBLEME_POSITION',
                    label: 'Problème Position'
                }, {
                    id: 7,
                    code: 'INCIDENT_TECHNIQUE',
                    label: 'Incident Technique'
                }, {
                    id: 8,
                    code: 'INCIDENT_TAD',
                    label: 'Incident TAD'
                }, {
                    id: 9,
                    code: 'OUBLI_DELOG',
                    label: 'Oubli D\'arret Pause'
                }
            ]
        }],
    motifDetails: [
        {
            motif: 'PRODUCTION',
            details: [
                {
                    id: 1,
                    code: 'PRODUCTION',
                    label: 'Production'
                },
                {
                    id: 2,
                    code: 'ALLAITEMENT',
                    label: 'Allaitement'
                }
            ]
        },
        {
            motif: 'HOLIDAY',
            details: [
                {
                    id: 1,
                    code: 'CP',
                    label: 'Conge Payé'
                }, {
                    id: 2,
                    code: 'CSS',
                    label: 'Conge Sans Solde'
                }
            ]
        }
    ],
    filter: {
        codeCounter: null,
        codeCountry: null,
        idPopulation: null
    },
    values: [
        {
            id: 1,
            code: 'O',
            label: 'OUI'
        },{
            id: 2,
            code: 'N',
            label: 'NON'
        }
    ]
};

export default function CounterReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_DATA":
            return {
                ...state,
                id: action.result.id,
                payss: action.result.populationDTO && action.result.populationDTO.countryDTO.code,
                population: action.result.populationDTO.id,
                code: action.result.code,
                valeur: action.result.valeur,
                description: action.result.description
            };

        case "SET_CRITERIA":
            return {
                ...state,
                filter: action.values
            };

        case "INIT_DATA":
            return {
                ...state,
                id: null,
                payss: '',
                population: '',
                code: '',
                valeur: '',
                description: ''
            };

        case 'GET_ALL_COUNTERS':
            return {
                ...state,
                loading: true
            };
        case 'GET_ALL_COUNTERS_SUCCESS':
            return {
                ...state,
                loading: false,
                counters: action.result.content,
                totalElements: action.result.totalElements,
                codes: action.result.content.map(a => a.code)
            };
        case 'GET_ALL_COUNTERS_FAIL':
            return {
                ...state,
                loading: false,
                counters: [],
                codes: [],
                totalElements: 0
            };

        case 'SET_PAGES':
            return {
                ...state,
                page: action.page
            };

        case 'REMOVE_EVS':
            return {
                ...state,
                onDelete: true
            };
        case 'REMOVE_EVS_SUCCESS':
            return {
                ...state,
                onDelete: false
            };
        case 'REMOVE_EVS_FAIL':
            return {
                ...state,
                onDelete: false
            };

        case 'SAVE_EVS':
            return {
                ...state,
                onSave: true
            };
        case 'SAVE_EVS_SUCCESS':
            return {
                ...state,
                onSave: false
            };
        case 'SAVE_EVS_FAIL':
            return {
                ...state,
                onSave: false
            };

        default:
            return state;
    }
}
