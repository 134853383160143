import axios from "axios";
import {environmentLocalDev, environmentPreprod, environmentProd} from "../environments/Oauth";
import {store} from "../store/ConfigureStore";
import { OAUTH_LOGOUT_DEV, OAUTH_LOGOUT_PROD} from "../api/Endpoints";
import momentSetTimeZone from "../utilities/momentUtils";
import {setLanguage} from "../i18n/Strings";

const envMapping = {
    "preprod": environmentPreprod,
    "prod": environmentProd,
    "localDev": environmentLocalDev
};

const roleMappings = {};

const environment = envMapping["prod"];
//const environment = envMapping[process.env.REACT_APP_ENV];

export function getAccessTokenRequest(code) {
    return async dispatch => {
        const axiosInstance = axios.create(
            {
                headers: {
                    'Authorization': `Basic ${encodeCredentials(environment.oauth.client_id, environment.oauth.secret)}`,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });

        const body = 'client_id=' + environment.oauth.client_id
            + '&grant_type=authorization_code'
            + '&credentials=true'
            + '&code=' + code
            + '&client_secret=' + environment.oauth.secret
            + '&scope=' + environment.oauth.scope
            + '&redirect_uri=' + environment.oauth.redirectTo;
        const res = await axiosInstance.post(environment.oauth.token, body);
        const {userProfile} = res.data;
        const {userName} = userProfile;
        dispatch(getLanguage(setLanguage(userProfile.countryCode)));
        if(userName && String(userName).startsWith("P")) {
            userProfile.authorities.push({
                clientId: "salary",
                authority: "ROLE_IRS_PT",
                permissions: []
            })
        }
        const authority = userProfile.authorities;
        const authorities = authority.filter(function (auth){
            return auth.clientId === "salary";
        });

        dispatch(getAuthority(authorities));
        dispatch(getProfileSuccess(userProfile)); // set profile to redux store
        saveInSession(environment.oauth.remoteUser, JSON.stringify(userProfile));
        saveInSession("ACCESS_TOKEN", userProfile.sectret2FA);
        dispatch(isLoggedIn(true));
    }
}
export function getAxios() {
    const $access_token = getFromSession('ACCESS_TOKEN');
    const token1 = $access_token.substring(0, $access_token.length / 2);
    const token2 = $access_token.substring($access_token.length / 2, $access_token.length);
    const headers = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json; charset=utf-8",
            Intelcia1: token1,
            Intelcia2: token2
        }
    };
    return axios.create(headers);
}
export function getAuthorizationUrl(secret) {
    const callbackUrl = environment.oauth.redirectTo;
    const currentRoute = window.location.href;
    const code = getUriParam(currentRoute, "code");
    if (!/secret/.test(currentRoute)) {
        const uri = environment.oauth.authorize +
            '?response_type=' + environment.oauth.response_type +
            '&client_id=' + environment.oauth.client_id +
            '&scope=' + environment.oauth.scope +
            '&state=' + secret +
            '&redirect_uri=' + (typeof callbackUrl === undefined ? environment.oauth.redirectTo : callbackUrl);
        window.location.href = uri;
    } else {
        store.dispatch(getAccessTokenRequest(code));
        var uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState({}, document.title, clean_uri);
        }
        momentSetTimeZone()
    }

}
function encodeCredentials(client_id, client_secret) {
    const credentials = client_id + ':' + client_secret;
    return btoa(credentials);
}
export function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
function getUriParam(uri, key) {
    if (typeof (url) === 'undefined') {
        let match = uri.match('[?&]' + key + '=([^&]+)');
        return match ? match[1] : null;
    } else {
        return false;
    }
}
export function getLanguage(payload) {
    return {
        type: "GET_LANGUAGE",
        payload
    }
}
export function getProfileSuccess(payload) {
    return {
        type: "GET_PROFILE_SUCCESS",
        payload
    }
}
export function getAuthority(payload) {
    return {
        type: "GET_AUTHORITIES",
        payload
    }
}
export function isLoggedIn(payload) {
    return {
        type: "IS_LOGGED_IN",
        payload
    }
}
export function setSearchActive(payload) {
    return {
        type: "SEARCH_ACTIVE",
        payload
    }
}
export function setLoading(payload) {
    return {
        type: "IS_LOADING",
        payload
    }
}
export function setRoleMan(payload) {
    return {
        type: "IS_ROLE_MAN",
        payload
    }
}
export function hasPermission(permission) {
    let connectedUser = getCurrentUser();
    if (connectedUser === null || connectedUser === undefined) {
        return false;
    }
    let authorities = connectedUser.authorities;
    // check if user has authority
    for (let i in authorities) {
        if (authorities[i].clientId === "intranet-liferay") {
            // check if user has permission
            let permissions = authorities[i].permissions;
            for (let i in permissions) {
                if (permissions[i] === permission) {
                    return true
                }
            }
        }
    }
    return false;
}
export function hasAuthority($authorities) {
    let connectedUser = getCurrentUser();
    if (connectedUser === null || connectedUser === undefined) {
        return false;
    }
    for (let elem of connectedUser.authorities) {
        const {clientId, authority} = elem;
        if (clientId === "salary" && $authorities.includes(authority)) {
            return true;
        }
    }
    return false;
}
export function getAuthorities() {
    let authorities = [];
    let connectedUser = getCurrentUser();
    if (connectedUser === null || connectedUser === undefined) {
        return false;
    }
    for (let elem of connectedUser.authorities) {
        const {clientId, authority} = elem;
        if (clientId === "intranet-liferay") {
            authorities.push(authority)
        }
    }
    return authorities;
}
export function rolesToValidation() {
    let d = [];
    const authorities = getAuthorities();
    for (let authority of authorities) {
        if (Object.keys(roleMappings).includes(authority)) {
            d.push(roleMappings[authority])
        }
    }
    return d;
}
export function rolesToAdmin() {
    const roleMappings = {
        "ROLE_ADMIN_GUEST": { value: "ADMIN_PPV", label: "Admin PPV"},
    };
    const authorities = getAuthorities();
    for (let authority of authorities) {
        if (Object.keys(roleMappings).includes(authority)) {
            return roleMappings[authority]
        }
    }

}

function encodeQuery(data) {
    let ret = [];
    for (let d in data)
        ret.push("role=" + data[d]);
    return (ret.length > 0) ? ret.join("&") : "role=MANAGER_VALIDATION"
}

export function $rolesToValidationUrl() {
    const roles = [];
    const authorities = getAuthorities();
    for (let authority of authorities) {
        if (Object.keys(roleMappings).includes(authority)) {
            roles.push(roleMappings[authority])
        }
    }
    return encodeQuery([...new Set(roles)])
}
export function getCurrentUser() {
    return store.getState().auth.profile;
}

export function logout() {
    window.location.href = ["preprod","localDev"].includes(process.env.REACT_APP_ENV) ? OAUTH_LOGOUT_DEV :
        OAUTH_LOGOUT_PROD;
}

export function saveInSession(key, value) {
    sessionStorage.setItem(key, value);
}

export function getFromSession(key) {
    return sessionStorage.getItem(key);
}

export function removeFromSession(key) {
    return sessionStorage.removeItem(key);
}

export const setContext = payload => {
    return {
        type: "SET_CONTEXT",
        payload
    }
}

