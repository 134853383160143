import React, {Component} from 'react';
import {
    withStyles,
    Grid,
    Box,
    Tooltip,
    LinearProgress,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Fab,
    FormControl,
    InputLabel,
    Button,
    TextField,
    Select,
    MenuItem,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Dialog
} from "@material-ui/core";
import {connect} from "react-redux";
import {Pagination} from "@material-ui/lab";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import AddIcon from "@material-ui/icons/Add";
import {CustomTableCell} from "../evs/EVsComponent";
import {styles} from "../../styles/CommonStyles";
import Delete from "../../assets/images/delete.png";
import DeleteComponent from "../moteur/DeleteComponent";
import {Field, reduxForm} from "redux-form";
import {renderDate} from "../../layout/renderComponents";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment";
import PaperComponent from "../../layout/PaperComponent";
import {logger} from "redux-logger/src";

const columns = [
    {headerName: 'MATRICULE'},
    {headerName: 'NOM'},
    {headerName: 'PRÉNOM'},
    {headerName: 'UO'},
    {headerName: 'DATE FICHE'},
    {headerName: 'DATE MODIFICATION'},
    {headerName: 'MODIFIÉ PAR'},
    {headerName: 'ACTION'}
];
const rows = [
    {
        id: 1,
        matricule: "M14555",
        nom: "Mohammed",
        prenom: "BEN",
        UO: "SFR",
        dateFiche: "23/03/2021  ",
        dateModif: "23/03/2021",
        updatedBy: "K. IBRAHIM"
    }, {
        id: 2,
        matricule: "M14555",
        nom: "Mohammed",
        prenom: "BEN",
        UO: "SFR",
        dateFiche: "23/03/2021  ",
        dateModif: "23/03/2021",
        updatedBy: "K. IBRAHIM"
    }, {
        id: 3,
        matricule: "M14555",
        nom: "Mohammed",
        prenom: "BEN",
        UO: "SFR",
        dateFiche: "23/03/2021  ",
        dateModif: "23/03/2021",
        updatedBy: "K. IBRAHIM"
    }, {
        id: 4,
        matricule: "M14555",
        nom: "Mohammed",
        prenom: "BEN",
        UO: "SFR",
        dateFiche: "23/03/2021  ",
        dateModif: "23/03/2021",
        updatedBy: "K. IBRAHIM"
    }, {
        id: 5,
        matricule: "M14555",
        nom: "Mohammed",
        prenom: "BEN",
        UO: "SFR",
        dateFiche: "23/03/2021  ",
        dateModif: "23/03/2021",
        updatedBy: "K. IBRAHIM"
    }, {
        id: 6,
        matricule: "M14555",
        nom: "Mohammed",
        prenom: "BEN",
        UO: "SFR",
        dateFiche: "23/03/2021  ",
        dateModif: "23/03/2021",
        updatedBy: "K. IBRAHIM"
    }, {
        id: 7,
        matricule: "M14555",
        nom: "Mohammed",
        prenom: "BEN",
        UO: "SFR",
        dateFiche: "23/03/2021  ",
        dateModif: "23/03/2021",
        updatedBy: "K. IBRAHIM"
    }, {
        id: 8,
        matricule: "M14555",
        nom: "Mohammed",
        prenom: "BEN",
        UO: "SFR",
        dateFiche: "23/03/2021  ",
        dateModif: "23/03/2021",
        updatedBy: "K. IBRAHIM"
    }
];

const renderTextField = ({input, label, meta: {touched, error}, ...custom}) => (
    <TextField fullWidth id="outlined-basic" label={label} size={"small"} variant="standard" hintText={label}
               floatingLabelText={label}
               errorText={touched && error}
               {...input}
               {...custom}
    />
);

const renderSelectField = ({input, label, values, meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={"standard"}
        {...input}
        {...custom}
    >
        <MenuItem value="">
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val =>
            <MenuItem key={val} value={val}>{val}</MenuItem>
        )}
    </Select>
);

class MyshiftModifsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            search: false,
            data: {},
            selectedDate: moment().format("YYYY-MM-DD")
        }
    }

    render() {
        const {loading, classes, handleSubmit, pristine, reset, submitting} = this.props;
        const {open, search, data, selectedDate} = this.state;

        const onClose = () => {
            this.setState({
                open: false,
                search: false
            });
        };

        const onSubmit = async values => {
            values.dateFiche = selectedDate;
            console.log(values);
        };


        const handleDateChange = (date) => {
            this.setState({
                selectedDate: moment(date).format("YYYY-MM-DD")
            });
        };

        return (
            <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                <Grid container>
                    <Grid item xs={6}>
                        <h1 style={{
                            marginTop: 1,
                            color: '#2460A7FF'
                        }}>LISTE DES MODIFICATIONS MYSHIFT</h1>
                    </Grid>
                </Grid>

                {loading ? <LinearProgress classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary
                }}/> : <hr color="#f5f5f5"/>}
                <br/>
                <Box display="flex" justifyContent="flex-end">
                    <Tooltip title="AJOUTER" aria-label="init">
                        <Fab size={"small"} className={classes.fab}
                             onClick={reset}>
                            <AddIcon/>
                        </Fab>
                    </Tooltip>
                    <Tooltip title="RECHERCHER" aria-label="search">
                        <Fab size={"small"} style={{marginLeft: '4px'}} className={classes.fab}
                        onClick={() => {
                            this.setState({
                                search: true
                            })
                        }}>
                            <SearchIcon/>
                        </Fab>
                    </Tooltip>
                    <Tooltip title="EXTRAIRE" aria-label="export">
                        <Fab size={"small"} style={{marginLeft: '4px'}} className={classes.fab}>
                            <ImportExportIcon/>
                        </Fab>
                    </Tooltip>
                </Box>
                <br/>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {columns && columns.map(column =>
                                <CustomTableCell align="center">{column.headerName}</CustomTableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <TableRow className={classes.row} key={row.id}>
                                <CustomTableCell align="center">{row.matricule}</CustomTableCell>
                                <CustomTableCell align="center">{row.nom}</CustomTableCell>
                                <CustomTableCell align="center">{row.prenom}</CustomTableCell>
                                <CustomTableCell align="center">{row.UO}</CustomTableCell>
                                <CustomTableCell align="center">{row.dateFiche}</CustomTableCell>
                                <CustomTableCell align="center">{row.dateModif}</CustomTableCell>
                                <CustomTableCell align="center">{row.updatedBy}</CustomTableCell>
                                <CustomTableCell align="center">
                                    <a style={{cursor: 'pointer'}}
                                       onClick={() => {
                                           this.setState({
                                               open: true,
                                               data: row
                                           });
                                       }}>
                                        <Tooltip title="Supprimer" aria-label="add">
                                            <img src={Delete} width="25px" height="24px" alt="checked"/>
                                        </Tooltip>
                                    </a>
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {rows && rows.length === 0 && (
                    <div className={classes.root}>
                        <p style={{
                            paddingBottom: '0px', color: '#2460A7FF', textAlign: 'center', fontSize: '30px',
                            fontFamily: 'Consolas'
                        }}>Aucun résultat trouvé...
                        </p>
                    </div>
                )}

                {rows && rows.length > 0 && (
                    <Grid container style={{position: "relative", top: 20}}>
                        <Grid item xs={6}>
                            <Box display="flex" className={classes.root}>
                                <Pagination
                                    size={"small"}
                                    showFirstButton
                                    showLastButton
                                    // count={rows.length % 10 === 0 ? rows.length / 10 : Math.trunc(rows.length / 10) + 1}
                                    count={100 % 10 === 0 ? 100 / 10 : Math.trunc(100 / 10) + 1}
                                    page={1}
                                    // onChange={handleChangePage}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}

                <DeleteComponent classes={classes} data={data} open={open} onClose={onClose}/>
                <Dialog
                    BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.25)'}}}
                    open={search}
                    classes={{
                        paper: classes.dialog
                    }}
                    fullWidth
                    onClose={() => {
                        onClose();
                        reset()
                    }}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle className={classes.alert}>RECHERCHER</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <form style={{paddingTop: '20px'}} onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <Field name="matricule" component={renderTextField} label="Matricule"/>
                                    </Grid>
                                    <Grid item xs={4} style={{marginTop: '-18px'}}>
                                        <Field name="dateFiche" component={renderDate} handleDateChange={handleDateChange}
                                               selectedDate={selectedDate} label="Date fiche"/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                                            <InputLabel>
                                                UOs
                                            </InputLabel>
                                            <Field name="uo" label="UOs" component={renderSelectField}/>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br/>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        style={{
                                            marginRight: '15px',
                                            backgroundColor: '#2F80ED',
                                            color: '#fff',
                                        }}
                                        size={"medium"}
                                        variant="contained">
                                        RÉINITIALISER
                                    </Button>
                                    <Button
                                        style={{
                                            backgroundColor: '#2F80ED',
                                            color: '#fff',
                                        }}
                                        size={"medium"}
                                        variant="contained"
                                        onClick={() => console.log("search")}>
                                        RECHERCHER
                                    </Button>
                                </Box>
                            </form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.evsReducer.loading,
    };
};
export default connect(mapStateToProps)(reduxForm({
    form: 'myshift',
    enableReinitialize: true
})(withStyles(styles)(MyshiftModifsComponent)));
