import React, {useEffect, useState} from 'react';
import {Box, Button, FormControl, Grid, InputLabel, LinearProgress, withStyles} from "@material-ui/core";
import {styles} from "../../styles/CommonStyles";
import {connect, useDispatch} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {renderSelectField, renderTextField} from "../../layout/renderComponents";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import moment, {now} from "moment";
import {getAllCountries, getAllEVs, getAllPopulations} from "../../actions/EVsActions";
import {affectCounters, getAllCounters} from "../../actions/CounterActions";

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

const renderDate = ({input, label, selectedDate, handleDateChange, meta: {touched, error}, ...custom}) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                fullWidth
                maxDate={now()}
                margin="normal"
                id="date-picker-inline"
                label={label}
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </Grid>
    </MuiPickersUtilsProvider>
);

const CounterChoiceComponent = ({
                                    loading, classes, handleSubmit, pays, reset, codes, populations,
                                    counters, ids
                                }) => {

    const dispatch = useDispatch();

    useEffect(() => {
        const filter = {
            codeCounter: null,
            codeCountry: null,
            idPopulation: null
        };
        dispatch(getAllCounters(filter, 0));
        dispatch(getAllPopulations(""));
        dispatch(getAllCountries());
    }, []);

    const [checked, setChecked] = useState([]);
    const [checkedP, setCheckedP] = useState([]);
    const [leftC, setLeftC] = useState([]);
    const [rightC, setRightC] = useState([]);
    const [leftP, setLeftP] = useState([]);
    const [rightP, setRightP] = useState([]);
    const [selectedDate, setSelectedDate] = React.useState(moment().format("YYYY-MM-DD"));
    const leftChecked = intersection(checked, leftC);
    const rightChecked = intersection(checked, rightC);
    const leftCheckedP = intersection(checkedP, leftP);
    const rightCheckedP = intersection(checkedP, rightP);


    useEffect(() => {
        setLeftC(codes);
    }, [codes]);

    useEffect(() => {
        setLeftP(ids);
    }, [ids]);

    const init = () => {
        reset();
        setChecked([]);
        setCheckedP([]);
        setLeftC(codes);
        setRightC([]);
        setLeftP(ids);
        setRightP([]);
        setSelectedDate(moment().format("YYYY-MM-DD"));
    };

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
    const handleToggleP = (value) => () => {
        const currentIndex = checkedP.indexOf(value);
        const newChecked = [...checkedP];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheckedP(newChecked);
    };
    const handleAllRightP = () => {
        setRightP(rightP.concat(leftP));
        setLeftP([]);
    };
    const handleCheckedRightP = () => {
        setRightP(rightP.concat(leftCheckedP));
        setLeftP(not(leftP, leftCheckedP));
        setCheckedP(not(checkedP, leftCheckedP));
    };
    const handleCheckedLeftP = () => {
        setLeftP(leftP.concat(rightCheckedP));
        setRightP(not(rightP, rightCheckedP));
        setCheckedP(not(checkedP, rightCheckedP));
    };
    const handleAllLeftP = () => {
        setLeftP(leftP.concat(rightP));
        setRightP([]);
    };
    const handleAllRight = () => {
        setRightC(rightC.concat(leftC));
        setLeftC([]);
    };
    const handleCheckedRight = () => {
        setRightC(rightC.concat(leftChecked));
        setLeftC(not(leftC, leftChecked));
        setChecked(not(checked, leftChecked));
    };
    const handleCheckedLeft = () => {
        setLeftC(leftC.concat(rightChecked));
        setRightC(not(rightC, rightChecked));
        setChecked(not(checked, rightChecked));
    };
    const handleAllLeft = () => {
        setLeftC(leftC.concat(rightC));
        setRightC([]);
    };

    const handlePopulationName = id => {
        for (let p in populations) {
            if (populations[p].id === id) return populations[p].description
        }
    };
    const customList = (items) => (
        <Paper style={{minHeight: "255px"}} className={classes.paper}>
            <List dense component="div" role="list">
                {items.map((item) => {
                    const labelId = `transfer-list-item-${item}-label`;

                    return (
                        <ListItem key={item} role="listitem" button onClick={handleToggle(item)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    style={{color: '#2460a7'}}
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={item}/>
                        </ListItem>
                    );
                })}
                <ListItem/>
            </List>
        </Paper>
    );

    const customListP = (items) => (
        <Paper style={{minHeight: "255px"}} className={classes.paper}>
            <List dense component="div" role="list">
                {items.map((item) => {
                    const labelId = `transfer-list-item-${item}-label`;
                    return (
                        <ListItem key={item} role="listitem" button onClick={handleToggleP(item)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checkedP.indexOf(item) !== -1}
                                    tabIndex={-1}
                                    style={{color: '#2460a7'}}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={handlePopulationName(item)}/>
                        </ListItem>
                    );
                })}
                <ListItem/>
            </List>
        </Paper>
    );

    const handleCounters = () => {
        let ids = [];
        for (let i in rightC) {
            for (let k in counters) {
                if (counters[k].code === rightC[i]) {
                    ids.push(counters[k].id);
                    break;
                }
            }
        }
        return ids;
    };

    const onSubmit = async (values) => {
        const data = {
            idCounters: handleCounters(),
            idPopulations: rightP,
            affecationDate: selectedDate
        };

        await dispatch(affectCounters(data))
        init();
    };

    const handleDateChange = (date) => {
        setSelectedDate(moment(date).format("YYYY-MM-DD"));
    };
    return (
        <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
            <h1 style={{
                marginTop: 1,
                color: '#2460A7FF'
            }}>{window.location.pathname.includes("counters") ? `CHOIX DES COMPTEURS PAR POPULATION`
                : `CHOIX DES VARIABLES PAR POPULATION`}
            </h1>

            {loading ? <LinearProgress classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary
            }}/> : <hr color="#f5f5f5"/>}
            <br/>
            <form style={{paddingTop: '20px', paddingBottom: '20px'}} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item style={{position: "relative", top: "-18.5px"}} xs={6}>
                        <Field name="dateAffectation" component={renderDate} handleDateChange={handleDateChange}
                               selectedDate={selectedDate} label="Date fin d'affectaion"/>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl size={"small"} variant={"standard"} fullWidth>
                            <InputLabel>
                                Pays
                            </InputLabel>
                            <Field name="pays" label="Pays" values={pays} component={renderSelectField}
                                   onChange={e => dispatch(getAllPopulations(e.target.value))}
                            />
                        </FormControl>
                        <br/><br/>
                    </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.root}>
                    <Grid item xs={12}>
                        <p style={{fontFamily: "Roboto", fontSize: "15px"}}>
                            <FormatListBulletedIcon style={{width: "17px", marginBottom: "-6px"}}/>
                            {window.location.pathname.includes("counters") ? `  Compteur` : `  Variable`}
                        </p>
                    </Grid>
                    <Grid item xs={5}>{customList(leftC)}</Grid>
                    <Grid item xs={2} style={{top: "55px", position: "relative"}}>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.CheckedButtonDown}
                                onClick={handleAllRight}
                                disabled={leftC.length === 0}
                                aria-label="move all rightC"
                            >
                                ≫
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.CheckedButtonDown}
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected rightC"
                            >
                                &gt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.CheckedButtonDown}
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected leftC"
                            >
                                &lt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.CheckedButtonDown}
                                onClick={handleAllLeft}
                                disabled={rightC.length === 0}
                                aria-label="move all leftC"
                            >
                                ≪
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>{customList(rightC)}</Grid>


                    <Grid item xs={12}>
                        <p style={{fontFamily: "Roboto", fontSize: "15px"}}>
                            <FormatListBulletedIcon style={{width: "17px", marginBottom: "-6px"}}/> Population
                        </p>
                    </Grid>
                    <Grid item xs={5} style={{minHeight: "255px"}}>{customListP(leftP)}</Grid>
                    <Grid item xs={2} style={{top: "55px", position: "relative"}}>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.CheckedButtonDown}
                                onClick={handleAllRightP}
                                disabled={leftP.length === 0}
                                aria-label="move all rightC"
                            >
                                ≫
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.CheckedButtonDown}
                                onClick={handleCheckedRightP}
                                disabled={leftCheckedP.length === 0}
                                aria-label="move selected rightC"
                            >
                                &gt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.CheckedButtonDown}
                                onClick={handleCheckedLeftP}
                                disabled={rightCheckedP.length === 0}
                                aria-label="move selected leftC"
                            >
                                &lt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.CheckedButtonDown}
                                onClick={handleAllLeftP}
                                disabled={rightP.length === 0}
                                aria-label="move all leftC"
                            >
                                ≪
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} style={{minHeight: "255px"}}>{customListP(rightP)}</Grid>
                </Grid>
                <br/><br/>
                <Box display="flex" justifyContent="flex-end">
                    <Button style={{marginRight: '15px'}} variant="contained"
                            className={classes.button}
                            onClick={init}>
                        ANNULER
                    </Button>
                    <Button variant="contained" className={classes.button}
                        //disabled={pristine || submitting}
                            type="submit">
                        VALIDER
                    </Button>

                </Box>
            </form>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        loading: state.counterReducer.loading,
        codes: state.counterReducer.codes,
        counters: state.counterReducer.counters,
        pays: state.evsReducer.pays,
        populations: state.evsReducer.populations,
        ids: state.evsReducer.ids
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: 'counterAffectation',
    enableReinitialize: true
})(withStyles(styles)(CounterChoiceComponent)));
