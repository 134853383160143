const primaryPink = "#fff";
export const profile = {
    row: {
        display: 'flex',
        justifyContent: 'center',

    },
    padd_top_10: {
        marginTop: 0,
        marginLeft: 0
    },
    padd_bottom_10: {
        paddingTop: 25,
        marginTop: 25,
        marginLeft: 10,
        marginBottom: 10
    },
    bigAvatar: {
        width: 40,
        height: 40,
        borderStyle: "solid",
        borderColor: primaryPink,
        backgroundColor: "white"
    },
    leftIcon: {
        marginRight: 20,
    }
};
export const styles = theme => ({
    root: {
        '& .Mui-selected': {
            color: '#fff',
            background: 'linear-gradient(2deg, #2F80ED, #56CCF2) !important',
        }
    },
    custom: {
        position: 'relative',
        top: 50
    },
    SvgIconSize: {
        '& .MuiSvgIcon-fontSizeLarge': {
            fontSize: 27
        }
    },
    chip: {
        '& .MuiChip-deleteIcon': {
            color: "#59a4e9"
        },

        '& .MuiChip-label': {
            fontSize: 14
        }
    },
    smDropzone: {
        minHeight: '45vh',
        //backgroundColor: '#f9f9f9'
    },
    paragraph: {
        fontFamily: 'Roboto',
        fontSize: 26,
        color: '#575757'
    },
    textarea: {
        boxSizing: 'border-box',
        width: '100%',
        borderRadius: 3,
        resize: 'none',
        fontSize: 17,
        height: 400,
        padding: 8,
        paddingTop: 20,
        borderColor: '#c4c4c4',

        '&::placeholder': {
            color: 'gainsboro',
            fontSize: 18
        },

        '&:focus': {
            outline: 'none'
        }

    },
    success: {
        backgroundColor: '#addfad',
        color: 'white',
        padding: '5px 8px 5px 9px',
        fontWeight: 'bold'
    },
    pending: {
        backgroundColor: '#f88379',
        color: 'white',
        padding: 5,
        fontWeight: 'bold'
    },
    multilineColor: {
        color: 'cornflowerblue'
    },
    indicator: {
        display: 'none',
        backgroundColor: '#fff',
    },
    dialog: {
        position: 'absolute',
        top: 100
    },
    dialogg: {
        position: 'absolute',
        top: 11
    },
    alert: {
        color: '#fff',
        background: 'linear-gradient(2deg, #2F80ED, #56CCF2) !important',
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    },
    colorPrimary: {
        backgroundColor: '#459ff2',
    },
    barColorPrimary: {
        backgroundColor: '#4dc5f2',
    },
    table: {
        minWidth: 700,
    },
    tableHead: {
        background: 'linear-gradient(2deg, #2F80ED, #56CCF2) !important',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
            height: 64,
            margin: 'auto'
        },
        height: 64,
        margin: 'auto'
    },
    fab: {
        background: 'linear-gradient(2deg, #2F80ED, #2F80ED) !important',
        color: "#fff"
    },
    button: {
        background: 'linear-gradient(2deg, #2F80ED, #2F80ED) !important',
        color: '#fff'
    },
    CheckedButtonDown: {
        background: 'linear-gradient(2deg, #2F80ED, #2F80ED) !important',
        color: '#fff',
        marginBottom: "2px"
    },
    DisabledButton: {
        background: 'linear-gradient(2deg, #EEEEEE, #EEEEEE) !important',
        color: '#fff',
        marginBottom: "2px"
    },
    toast: {
        position: 'relative',
        top: 50,
        left: 30
    }
});
