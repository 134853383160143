import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, LinearProgress
} from "@material-ui/core";
import {Button, IconButton} from "@material-ui/core";
import {Field, reduxForm} from 'redux-form';
import Box from "@material-ui/core/Box";
import {strings} from "../../i18n/Strings";
import {connect, useDispatch} from "react-redux";
import PaperComponent from "../../layout/PaperComponent";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {renderSelectField, renderSelectPop, renderText} from "../../layout/renderComponents";
import {getAllEVs, getAllPopulations, init, saveEVs} from "../../actions/EVsActions";
import {getAllUsers} from "../../actions/ExcludeActions";

const useStyles = makeStyles(() => ({
    alert: {
        color: '#fff',
        background: 'linear-gradient(-45deg, #2F80ED, #56CCF2) !important',
    },
    selectEmpty: {
        paddingBottom: "8px"
    },
    buttonProgress: {
        position: 'absolute',
        left: '90%',
        marginTop: 6,
        marginLeft: -19,
    },
    button: {
        background: 'linear-gradient(-45deg, #2F80ED, #2F80ED) !important',
        color: '#fff'
    }
}));
const validate = values => {
    const errors = {};
    const requiredFields = [
        'payss',
        'idPopulation',
        'valeur',
        'description',
        'code'
    ];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });

    return errors;
};

const AddEVsComponent = props => {
    const classes = useStyles();
    const [err, setErr] = useState(false);

    const {action, handleSubmit, reset, open, onClose, codes, pristine, submitting, populations, pays, onSave, row} = props;
    const {_addTitle, _updateTitle, _detailsTitle} = strings;
    const dispatch = useDispatch();

    const onSubmit = async (values) => {
        const filter = {
            codeVariable: null,
            idCountry: null,
            idPopulation: null
        };
        values.id = action === 'edit' ? row.id : null;
        await dispatch(saveEVs(values, action));
        setTimeout(
            () => {
                dispatch(getAllEVs(filter, 0));
            },
            2000
        );
        await onCancel();
        reset();
    };

    const onCancel = async () => {
        await onClose();
        setErr(false);
        dispatch(init());
        reset();
    };

    const checkExistingCode = code => {
        setErr(codes.includes(code));
    };

    return (
        <div container>
            <Dialog
                BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.25)'}}}
                open={open}
                    fullWidth
                onClose={onCancel}
                PaperComponent={PaperComponent}
            >

                <DialogTitle className={classes.alert} disableTypography classe
                             style={{color: '#fff', paddingBottom: '1px', cursor: 'move'}}
                             id="draggable-dialog-title">
                    <h4>{action === 'add' ? _addTitle : action === 'edit' ? _updateTitle : _detailsTitle}</h4>

                </DialogTitle>
                {onSave && <LinearProgress classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary
                }}/>}
                <DialogContent>
                    <DialogContentText>
                        <form style={{paddingTop: '20px', paddingBottom: '20px'}} onSubmit={handleSubmit(onSubmit)}>
                            <FormControl fullWidth className={classes.selectEmpty}>
                                <InputLabel>
                                    Pays
                                </InputLabel>
                                <Field name="payss" label="Pays" values={pays} component={renderSelectField}
                                       className={classes.selectEmpty} disabled={action === 'consult'}
                                       onChange={e => dispatch(getAllPopulations(e.target.value))}/>
                            </FormControl>
                            <br/><br/>
                            <FormControl fullWidth className={classes.selectEmpty}>
                                <InputLabel>
                                    Population
                                </InputLabel>
                                <Field name="idPopulation" values={populations} component={renderSelectPop}
                                       className={classes.selectEmpty} disabled={action === 'consult'}/>
                            </FormControl>
                            <br/><br/>
                            <Field name="valeur" className={classes.selectEmpty} component={renderText}
                                   disabled={action === 'consult'} label="Donnée"/>
                            <br/><br/>
                            <Field name="code" className={classes.selectEmpty} component={renderText} label="Code"
                                   disabled={action !== 'add'} error={err}
                                   onChange={e => checkExistingCode(e.target.value)}
                                   helperText={err && "code existe déjà"}/>
                            <br/><br/>
                            <Field name="description" classes={classes.selectEmpty} component={renderText}
                                   label="Libellé" disabled={action === 'consult'}/>
                            <br/><br/><br/>
                            {action === 'consult' && (
                                <Box display="flex" justifyContent="flex-end">
                                    <Button style={{marginRight: '10px'}} size={"medium"} variant="contained"
                                            className={classes.button} onClick={onClose}>Retour</Button>
                                </Box>
                            )}

                            {action !== 'consult' && (
                                <Box display="flex" justifyContent="flex-end">
                                    <Button style={{marginRight: '10px'}} size={"medium"} variant="contained"
                                            className={classes.button} onClick={reset}>Réinitialiser</Button>
                                    <Button size={"medium"} variant="contained"
                                            className={classes.button} type="submit">{action === 'edit' ? "Modifier" :"Ajouter" }</Button>
                                </Box>
                            )}

                            </form>
                    </DialogContentText>
                </DialogContent>
                <DialogActions/>
            </Dialog>
        </div>
);
};

const mapStateToProps = state => {
    return {
    onSave: state.evsReducer.onSave,
    pays: state.evsReducer.pays,
    codes: state.evsReducer.codes,
    populations: state.evsReducer.populations,
    initialValues: {
    payss: state.evsReducer.payss,
    idPopulation: state.evsReducer.population,
    code: state.evsReducer.code,
    description: state.evsReducer.description,
    valeur: state.evsReducer.valeur,
}
};
};

export default connect(mapStateToProps)(reduxForm({
    form: 'AddEVs',
    enableReinitialize: true,
    validate
})(AddEVsComponent));
