import {GLOBAL_HOURS, GLOBAL_HOURS_SUCCESS, GLOBAL_HOURS_FAIL} from "../types/ExportTypes";

const initialState = {
    loading: false
};

export default function exportReducer(state = initialState, action) {
    switch (action.type) {
        case GLOBAL_HOURS:
            return {
                ...state,
                loading: true
            };
        case GLOBAL_HOURS_SUCCESS:
            return {
                ...state,
                loading: false,
                details: action.result,
                columns: Object.keys(action.result[0])
            };
        case GLOBAL_HOURS_FAIL:
            return {
                ...state,
                loading: false,
                details: []
            };
        default:
            return state;
    }
}