const initialState = {
    loading: false,
    success: false,
    inputVal: '',
    usersPage: 0,
    sitesPage: 0,
    totalUsersElements: 0,
    totalSitesElements: 0,
};

export default function excludeReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_INPUT_VAL":
            return {
                ...state,
                inputVal: action.inputVal
            };
        case "ADD_SITE":
            return {
                ...state,
                loading: true,
                success: false
            };
        case "ADD_SITE_SUCCESS":
            return {
                ...state,
                loading: false,
                success: true
            };
        case "ADD_SITE_FAIL":
            return {
                ...state,
                loading: false,
                success: false
            };
        case "SET_USERS_PAGE":
            return {
                ...state,
                usersPage: action.page
            };
        case "SET_SITES_PAGE":
            return {
                ...state,
                sitesPage: action.page
            };
        case "GET_ALL_USERS":
            return {
                ...state,
                loading: true
            };
        case "GET_ALL_USERS_SUCCESS":
            return {
                ...state,
                loading: false,
                users: action.result.content,
                totalUsersElements: action.result.totalElements
            };
        case "GET_ALL_USERS_FAIL":
            return {
                ...state,
                loading: false,
                users: [],
                totalUsersElements: 0
            };
        case "GET_ALL_SITES":
            return {
                ...state,
                loading: true
            };
        case "GET_ALL_SITES_SUCCESS":
            return {
                ...state,
                loading: false,
                sites: action.result.content,
                totalSitesElements: action.result.totalElements
            };
        case "GET_ALL_SITES_FAIL":
            return {
                ...state,
                loading: false,
                sites: [],
                totalSitesElements: 0
            };
        default:
            return state;
    }
};
