import {getAxios, getCurrentUser} from "./AuthActions";
import {API} from "../api/Endpoints";
import Swal from "sweetalert2";
import {
    GET_LAUNCH_DETAIL,
    GET_LAUNCH_DETAIL_FAIL,
    GET_LAUNCH_DETAIL_SUCCESS,
    GET_ROWS,
    GET_ROWS_FAIL,
    SET_FILTER,
    GET_ROWS_SUCCESS,
    EXPORT,
    EXPORT_SUCCESS,
    EXPORT_FAIL,
    VALIDATE,
    VALIDATE_SUCCESS,
    VALIDATE_FAIL,
    GET_LAUNCH_BY_ID,
    IMPORT,
    IMPORT_SUCCESS,
    IMPORT_FAIL,
    GET_LAUNCH_BY_ID_SUCCESS,
    GET_LAUNCH_BY_ID_FAIL,
    GET_SHEETS_SUCCESS,
    GET_SHEETS_FAIL,
    GET_SHEETS,
    GET_HISTORY,
    GET_HISTORY_SUCCESS, GET_HISTORY_FAIL
} from "../types/PayrollEngineTypes";
import moment from "moment";
import {alertx} from "../utilities/Alert";

export const launch = launchHistory => async dispatch => {
    const axios = getAxios();
    const profile = getCurrentUser();

    launchHistory.lancerPar = `${profile.firstName} ${profile.lastName}`

    axios({
        url: `${API}/api/launchMoteur`,
        method: 'post',
        data: launchHistory
    })
        .then(() => {
            alertx('success', 'OPÉRATION EN COURS DE TRAITEMENT');
        });
};

export const removeLauncherHistory = id => async dispatch => {
    const axios = getAxios();
    const {userName} = getCurrentUser();
    axios({
        url: `${API}/api/deleteLaunchHistory?id=${id}&username=${userName}`,
        method: 'get'
    })
        .then(res => {
            alertx('success', 'LE LANCEMENT A BIEN ÉTÉ SUPPRIMÉ');

        })
        .catch(() => {
            alertx('error', 'OPÉRATION ÉCHOUÉE');

        })
}


export const getLaunchHistory = (page, onload, filter) => async dispatch => {
    const axios = getAxios();

    if (onload) {
        dispatch({
            type: GET_ROWS,
        });
    }


    axios({
        url: `${API}/api/getHistoryRunning?page=${page}&size=10`,
        method: 'post',
        data: filter
    })
        .then(res => {
            dispatch({
                type: GET_ROWS_SUCCESS,
                result: res.data
            })
        })
        .catch(() => {
            dispatch({
                type: GET_ROWS_FAIL,
                result: []
            })
        })
};

export const retryLaunchMotor = dto => async dispatch => {
    const axios = getAxios();

    axios({
        url: `${API}/api/retryLaunchMoteur`,
        method: 'post',
        data: dto
    })
        .then(res => {
            alertx('success', 'Le lancement a bien été relancé');

        })
        .catch(() => {

        })
};
export const InitDetails = () => async dispatch => {

    dispatch({
        type: 'INIT_DETAILS'
    })

};

export const getLaunchDetail = (id, type) => async dispatch => {

    const axios = getAxios();
    dispatch({
        type: GET_LAUNCH_DETAIL,
    })
    axios({
        url: type === 'J' ? `${API}/api/extractv2?launch=${id}&p=J` : `${API}/api/extractv2?launch=${id}&p=M`,
        method: 'get'
    })
        .then(res => {
            dispatch({
                type: GET_LAUNCH_DETAIL_SUCCESS,
                result: res.data
            })
            setTimeout(() => {
                dispatch({
                    type: 'SET_LOADING',
                });
            }, 100)
        })
        .catch(() => {
            dispatch({
                type: GET_LAUNCH_DETAIL_FAIL,
                result: []
            })
        })
};


export const getLaunchExport = (id  , type , fileType) => async dispatch => {

    const axios = getAxios();
    dispatch({
        type: GET_LAUNCH_DETAIL,
    })
    axios({
        url: type === 'J' ? `${API}/api/exportFIle?launch=${id}&p=J&type=${fileType}` : `${API}/api/exportFile?launch=${id}&p=M&type=${fileType}`,
        method: 'GET',
        responseType: 'blob'
    })
        .then(res => {
            dispatch({
                type: EXPORT_SUCCESS
            })
            const url = URL.createObjectURL(new Blob([res.data], {type:  res.headers['content-type']}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',
                `FILE_${id}.${fileType}`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(() => {
            
        })
};

export const getLaunchHistoryById = id => async dispatch => {
    const axios = getAxios();
    dispatch({
        type: GET_LAUNCH_BY_ID,
    });
    axios({
        url: `${API}/api/findById?id=${id}`,
        method: 'get'
    })
        .then(res => {
            dispatch({
                type: GET_LAUNCH_BY_ID_SUCCESS,
                result: res.data
            })
        })
        .catch(() => {
            dispatch({
                type: GET_LAUNCH_BY_ID_FAIL,
                result: []
            })
        })
};
export const validateLaunchHistory = (id, action) => async dispatch => {
    const isValid = true;
    const axios = getAxios();
    dispatch({
        type: VALIDATE
    })
    axios({
        url: `${API}/api/setIsValide?id=${id}&status=${action === 'V' ? 'VALIDATED' : 'REJECTED'}`,
        method: 'get'
    })
        .then(res => {
            dispatch({
                type: VALIDATE_SUCCESS
            })
            alertx('success', action === 'V' ? 'LE RÉSULTAT DE CALCUL A BIEN ÉTÉ VALIDÉ'
                : 'LE RÉSULTAT DE CALCUL A BIEN ÉTÉ REJETÉ');

        })
        .catch(() => {
            dispatch({
                type: VALIDATE_FAIL
            })
            alertx('error', 'VALIDATION ÉCHOUÉE');
        })
};

export const exportLaunchHistory = (id, type, p) => async dispatch => {
    const axios = getAxios();

    dispatch({
        type: EXPORT
    })
    axios({
        url: `${API}/api/export2?launch=${id}&type=${type}&p=${p}`,
        method: 'get'
    })
        .then(res => {
            dispatch({
                type: EXPORT_SUCCESS
            })
            const url = URL.createObjectURL(new Blob([res.data], {type: "application/csv"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',
                `extraction_${moment(new Date()).format("YYYY-MM-DD")}.csv`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            dispatch({
                type: EXPORT_FAIL
            })
        })
};

export const dailyExtraction = (id) => async dispatch => {
    const axios = getAxios();

    dispatch({
        type: EXPORT
    })
    axios({
        url: `${API}/api/export?tableName=journalier_${id}`,
        method: 'get'
    })
        .then(res => {
            dispatch({
                type: EXPORT_SUCCESS
            })
            const url = URL.createObjectURL(new Blob([res.data], {type: "application/csv"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',
                `extraction_journalier_${moment(new Date()).format("YYYY-MM-DD")}.csv`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            dispatch({
                type: EXPORT_FAIL
            })
        })
};


export const getModulations = regulation => async dispatch => {
    const axios = getAxios();
    axios({
        url: `${API}/api/modulationTypes/getModulationsTypesByRyle?idRule=${regulation}`,
        method: 'get',
    })
        .then(res => {
            dispatch({
                type: 'GET_MODULATION_SUCCESS',
                data: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: 'GET_MODULATION_FAIL'
            })
        })
};

export const initFields = () => async dispatch => {
    dispatch({
        type: 'INIT_FIELDS',
    })
};

export const getRulesAll = () => async dispatch => {
    const axios = getAxios();
    axios({
        url: `${API}/api/rule/findAll`,
        method: 'get'
    })
        .then(res => {
            dispatch({
                type: 'GET_RULES_SUCCESS',
                result: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: 'GET_RULES_FAIL'
            })
        })
};

export const initSheets = () => async dispatch => {
    dispatch({
        type: GET_SHEETS_FAIL
    })
};


export const setFilter = filter => dispatch => {
    dispatch({
        type: SET_FILTER,
        data: filter
    })
}

export const getSheetsFromFile = file => async dispatch => {
    dispatch({
        type: GET_SHEETS
    });
    const axios = getAxios();
    let formData = new FormData();
    formData.append("file", file);
    await axios.post(`${API}/api/paie/getSheets`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
        .then(res => {
            dispatch({
                type: GET_SHEETS_SUCCESS,
                result: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_SHEETS_FAIL
            })
        })
}
export const importModulation = (file, data, period, date) => async dispatch => {
    const axios = getAxios();
    const {firstName, lastName} = getCurrentUser();
    const updatedBy = `${firstName} ${lastName}`;
    let formData = new FormData();
    formData.append("file", file);
    axios.post(`${API}/api/paie/upload?uploadedBy=${updatedBy}&sheets=${data}&year=${date}&month=${period}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    })
        .then(res => {

        })
        .catch(err => {
            alertx('error', 'OPÉRATION ÉCHOUÉE')
        })
}


export const getListHistory = filter => async dispatch => {
    dispatch({
        type: GET_HISTORY
    });
    const axios = getAxios();
    axios({
        url: `${API}/api/paie/getImportHistory?page=0&size=10`,
        method: 'post',
        data: filter
    })
        .then(res => {
            dispatch({
                type: GET_HISTORY_SUCCESS,
                result: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_HISTORY_FAIL
            });
        })
}

export const allFilters = type => async dispatch => {

    const axios = getAxios();
    axios({
        url: `${API}/api/filter/find?t=${type}`,
        method: 'get'
    })
        .then(res => {
            dispatch({
                type: 'GET_ALL_FILTERS_SUCCESS',
                result: res.data
            });
        })
        .catch(() => {
            dispatch({
                type: 'GET_ALL_FILTERS_FAIL'
            });
        })
}

export const getExtractByFilter = (filter, id) => async dispatch => {

    dispatch({
        type: GET_LAUNCH_DETAIL,
    });
    const axios = getAxios();
    axios({
        url: `${API}/api/extractv2?t=paie_${id}&f=${filter}`,
        method: 'get'
    })
        .then(res => {
            dispatch({
                type: 'GET_ALL_BY_FILTER_SUCCESS',
                result: res.data
            });
        })
        .catch(() => {
            dispatch({
                type: 'GET_ALL_BY_FILTER_FAIL'
            });
        })
}