const initialState = {
    modulations: [],
    regulations: []

};
export default function launcherReducer(state = initialState, action) {
    switch (action.type) {
        case "GET_MODULATION":
            return {
                ...state,
            };
        case 'GET_MODULATION_SUCCESS':
            return {
                ...state,
                modulations: action.data
            };
        case 'GET_MODULATION_FAIL':
            return {
                ...state,
                modulations: []
            };
        case 'GET_RULES_SUCCESS':
            return {
                ...state,
                regulations: action.result
            };
        case 'GET_RULES_FAIL':
            return {
                ...state,
                regulations: []
            };
        case 'INIT_FIELDS':
            return {
                ...state,
                modulations: [],
                regulations: [],
            };
        default:
            return state;
    }
};
