import React, {Component} from 'react';
import {
    withStyles,
    Grid,
    Box,
    Tooltip,
    LinearProgress,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Fab, DialogTitle, DialogContent, DialogContentText, Button, Dialog
} from "@material-ui/core";
import {connect} from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import {Pagination} from "@material-ui/lab";
import eye from "../../assets/images/123.png";
import Edit from "../../assets/images/112.png";
import Delete from "../../assets/images/delete.png";
import AddCompteur from "./Step1";
import PaperComponent from "../../layout/PaperComponent";
import {exportAllCounters, getAllCounters, removeCounter, setPagee} from "../../actions/CounterActions";
import SearchComponent from "../evs/SearchComponent";
import SearchIcon from "@material-ui/icons/Search";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import {exportAllEVs} from "../../actions/EVsActions";

const styles = theme => ({
    root: {
        '& .Mui-selected': {
            color: '#fff',
            background: 'linear-gradient(-45deg, #2F80ED, #56CCF2) !important',
        }
    },
    dialog: {
        position: 'absolute',
        top: 1
    },
    alert: {
        color: '#fff',
        background: 'linear-gradient(-45deg, #2F80ED, #56CCF2) !important',
    },
    colorPrimary: {
        backgroundColor: '#459ff2',
    },
    barColorPrimary: {
        backgroundColor: '#4dc5f2',
    },
    table: {
        minWidth: 700,
    },
    tableHead: {
        background: 'linear-gradient(-45deg, #2F80ED, #56CCF2) !important',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    fab: {
        background: 'linear-gradient(-45deg, #2F80ED, #2F80ED) !important',
        color: "#fff"
    },
    button: {
        background: 'linear-gradient(-45deg, #2F80ED, #2F80ED) !important',
        color: '#fff'
    }
});

const CustomTableCell = withStyles(theme => ({
    head: {
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 13,
    },
}))(TableCell);

const columns = [
    {headerName: 'Code'},
    {headerName: 'Libellé'},
    {headerName: 'Date création'},
    {headerName: 'Pays'},
    {headerName: 'Population'},
    {headerName: 'Action'}
];

class CounterComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openAdd: false,
            openSearch: false,
            action: 'add',
            row: {}
        };

        const filter = {
            codeCounter: null,
            codeCountry: null,
            idPopulation: null
        };
        // Toast.fire({
        //     icon: 'success',
        //     title: 'Signed in successfully'
        // });
        props.dispatch(getAllCounters(filter, 0));
    }

    render() {
        const {loading, classes, totalElements, page, counters, filter, dispatch, history} = this.props;
        const {open, openAdd, action, row, openSearch} = this.state;


        const handleChangePage = async (event, newPage) => {
            await dispatch(getAllCounters(filter, newPage - 1));
            dispatch(setPagee(newPage - 1));
        };

        const onClose = () => {
            this.setState({
                open: false
            });
        };
        const onCloseAdd = () => {
            this.setState({
                openAdd: false
            });
        };

        const onCloseSearch = () => {
            this.setState({
                openSearch: false
            });
        };
        const removeCnter = async id => {
            const filteer = {
                codeCounter: null,
                codeCountry: null,
                idPopulation: null
            };
            await dispatch(removeCounter(id));
            dispatch(getAllCounters(filteer, 0));
            this.setState({
                open: false,
                row: {}
            })
        };


        return (
            <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                <Grid container>
                    <Grid item xs={6}>
                        <h1 style={{
                            marginTop: 1,
                            color: '#2460A7FF'
                        }}>PARAMÉTRAGE COMPTEUR</h1>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" justifyContent="flex-end">
                            <Tooltip title="RECHERCHER" aria-label="search">
                                <Fab size={"small"} className={classes.fab}
                                     onClick={() => {
                                         this.setState({
                                             openSearch: true
                                         })
                                     }}>
                                    <SearchIcon/>
                                </Fab>
                            </Tooltip>
                            <Tooltip title="Ajouter compteur" aria-label="add counter">
                                <Fab size={"small"} style={{marginLeft: '4px'}} className={classes.fab}
                                     onClick={() => history.push("/add_counter")}>
                                    <AddIcon/>
                                </Fab>
                            </Tooltip>
                            <Tooltip title="EXTRAIRE" aria-label="export">
                                <Fab size={"small"} style={{marginLeft: '4px'}} className={classes.fab}
                                     onClick={() => dispatch(exportAllCounters())}>
                                    <ImportExportIcon/>
                                </Fab>
                            </Tooltip>
                        </Box>
                    </Grid>
                </Grid>

                {loading && <LinearProgress classes={{
                    colorPrimary: classes.colorPrimary,
                    barColorPrimary: classes.barColorPrimary
                }}/>}

                <br/>
                <SearchComponent component='counter' search={openSearch} onClose={onCloseSearch}/>
                <br/>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {columns && columns.map(column =>
                                <CustomTableCell align="center">{column.headerName}</CustomTableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {counters.map(row => (
                            <TableRow className={classes.row} key={row.id}>
                                <CustomTableCell align="center">{row.code}</CustomTableCell>
                                <CustomTableCell align="center">{row.libelle}</CustomTableCell>
                                <CustomTableCell align="center">{row.creationDate}</CustomTableCell>
                                <CustomTableCell align="center">{row.populationDTO && row.populationDTO.countryDTO
                                && row.populationDTO.countryDTO.description}</CustomTableCell>
                                <CustomTableCell align="center">{row.populationDTO && row.populationDTO.description}</CustomTableCell>
                                <CustomTableCell align="center">
                                    <a style={{cursor: 'pointer'}}>
                                        <Tooltip title="Visualiser" aria-label="see">
                                            <img style={{position: "relative", top: 7}} src={eye} width="35px"
                                                 height="35px" alt="checked"/>
                                        </Tooltip>
                                    </a>
                                    <a style={{cursor: 'pointer'}}>
                                        <Tooltip title="Modifier" aria-label="see">
                                            <img style={{position: "relative", top: 2}} src={Edit} width="28px"
                                                 height="26px" alt="edit"/>
                                        </Tooltip>
                                    </a>
                                    <a style={{cursor: 'pointer'}}
                                       onClick={() => {
                                           this.setState({
                                               open: true,
                                               row
                                           });
                                       }}>
                                        <Tooltip title="Supprimer" aria-label="delete">
                                            <img src={Delete} width="25px" height="24px" alt="checked"/>
                                        </Tooltip>
                                    </a>
                                </CustomTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {counters && counters.length === 0 && (
                    <div className={classes.root}>
                        <p style={{
                            paddingBottom: '0px', color: '#2460A7FF', textAlign: 'center', fontSize: '30px',
                            fontFamily: 'Consolas'
                        }}>Aucun résultat trouvé...
                        </p>
                    </div>
                )}

                {counters && counters.length > 0 && (
                    <Grid container style={{position: "relative", top: 20}}>
                        <Grid item xs={6}>
                            <Box display="flex" className={classes.root}>
                                <Pagination
                                    size={"small"}
                                    showFirstButton
                                    showLastButton
                                    count={totalElements % 10 === 0 ? totalElements / 10 : Math.trunc(totalElements / 10) + 1}
                                    page={page + 1}
                                    onChange={handleChangePage}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}

                <Dialog
                    BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.25)'}}}
                    open={open}
                    classes={{
                        paper: classes.dialog
                    }}
                    fullWidth
                    onClose={onClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle className={classes.alert} style={{cursor: 'move'}}>ATTENTION</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <p style={{fontFamily: "Roboto"}}>
                                VOULEZ-VOUS VRAIMENT SUPPRIMER CET ELEMENT VARIABLE ?
                            </p>
                            <br/>
                            <Box display="flex" justifyContent="flex-end">
                                <Button style={{marginRight: '15px'}} size={"medium"} variant="contained"
                                        className={classes.button} onClick={() => removeCnter(row.id)}>SUPPRIMER
                                </Button>
                                <Button size={"medium"} variant="contained" className={classes.button}
                                        onClick={onClose}>ANNULER
                                </Button>
                            </Box>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {/*<AddCompteur open={openAdd} action={action} onClose={onCloseAdd}/>*/}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.counterReducer.loading,
        counters: state.counterReducer.counters,
        totalElements: state.counterReducer.totalElements,
        page: state.counterReducer.page,
        filter: state.counterReducer.filter
    };
};
export default connect(mapStateToProps)(withStyles(styles)(CounterComponent));
