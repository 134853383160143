import * as React from 'react';
import clsx from 'clsx';
import {Paper, TableCell, Theme, styled} from '@material-ui/core';

import {
    AutoSizer,
    Column,
    Table,
    TableCellRenderer,
    TableHeaderProps,
} from 'react-virtualized';

const classes = {
    flexContainer: 'ReactVirtualizedDemo-flexContainer',
    tableRow: 'ReactVirtualizedDemo-tableRow',
    tableRowHover: 'ReactVirtualizedDemo-tableRowHover',
    tableCell: 'ReactVirtualizedDemo-tableCell',
    noClick: 'ReactVirtualizedDemo-noClick',
};

const styles = ({theme}) =>
    ({
        // temporary right-to-left patch, waiting for
        // https://github.com/bvaughn/react-virtualized/issues/454
        '& .ReactVirtualized__Table__headerRow': {
            ...(theme.direction === 'rtl' && {
                paddingLeft: '0 !important',
            }),
            ...(theme.direction !== 'rtl' && {
                paddingRight: undefined,
            }),
        },
        [`& .${classes.flexContainer}`]: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
        },
        [`& .${classes.tableRow}`]: {
            cursor: 'pointer',
        },
        [`& .${classes.tableRowHover}`]: {
            '&:hover': {
                backgroundColor: theme.palette.grey[200],
            },
        },
        [`& .${classes.tableCell}`]: {
            flex: 1,
        },
        [`& .${classes.noClick}`]: {
            cursor: 'initial',
        },
    });


class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
        headerHeight: 48,
        rowHeight: 40,
    };

    getRowClassName = ({index}) => {
        const {onRowClick} = this.props;

        return clsx(classes.tableRow, classes.flexContainer, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        });
    };

    cellRenderer = ({cellData, columnIndex}) => {
        const {columns, rowHeight, onRowClick} = this.props;
        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                })}
                variant="body"
                style={{height: rowHeight}}
                align={
                    (columnIndex != null && columns[columnIndex].numeric) || false
                        ? 'right'
                        : 'left'
                }
            >
                {cellData}
            </TableCell>
        );
    };

    headerRenderer = ({
                          label,
                          columnIndex
                      }) => {
        const {headerHeight, columns} = this.props;

        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{height: headerHeight}}
                align={columns[columnIndex].numeric || false ? 'right' : 'left'}
            >
                <span>{label}</span>
            </TableCell>
        );
    };

    render() {
        const {columns, rowHeight, headerHeight, ...tableProps} = this.props;
        return (
            <>
                <AutoSizer>
                    {({height, width}) => (
                        <Table
                            height={height}
                            width={width}
                            rowHeight={rowHeight}
                            gridStyle={{
                                direction: 'inherit',
                            }}
                            headerHeight={headerHeight}
                            {...tableProps}
                            rowClassName={this.getRowClassName}
                        >
                            {columns.map(({dataKey, ...other}, index) => {
                                return (
                                    <Column
                                        key={dataKey}
                                        headerRenderer={(headerProps) =>
                                            this.headerRenderer({
                                                ...headerProps,
                                                columnIndex: index,
                                            })
                                        }
                                        className={classes.flexContainer}
                                        cellRenderer={this.cellRenderer}
                                        dataKey={dataKey}
                                        {...other}
                                    />
                                );
                            })}
                        </Table>
                    )}
                </AutoSizer>
            </>
        );
    }
}

const VirtualizedTable = styled(MuiVirtualizedTable)(styles);


export default VirtualizedTable;
