import {getAxios, getCurrentUser} from "./AuthActions";
import {API} from "../api/Endpoints";
import {GLOBAL_HOURS, GLOBAL_HOURS_FAIL, GLOBAL_HOURS_SUCCESS} from "../types/ExportTypes";

export const exportPaieByUser = (year, period, type) => async dispatch => {
    const axios = getAxios();

    dispatch({
        type: GLOBAL_HOURS
    })

    axios({
        url: `${API}/api/myextract?y=${year}&m=${period - 1}&p=${type}`,
        method: 'get'
    })
        .then(res => {
            dispatch({
                type: GLOBAL_HOURS_SUCCESS,
                result: res.data
            })
        })
        .catch(() => {
            dispatch({
                type: GLOBAL_HOURS_FAIL
            })
        })
}