import React, {useEffect, useRef, useState} from 'react';
import {
    Grid,
    Fab,
    Table,
    Tooltip,
    Box,
    TableBody,
    TableHead,
    TableRow,
    withStyles,
    Paper,
    Collapse,
    FormControl, InputLabel, Button
} from "@material-ui/core";
import {CustomTableCell} from "../evs/EVsComponent";
import PaginationComponent from "../../layout/PaginationComponent";
import Loader from "../../layout/Loader";
import {connect, useDispatch} from "react-redux";
import {styles} from "../../styles/CommonStyles";
import {exportPaieByUser} from "../../actions/exportActions";
import moment from "moment";
import ImportExportIcon from '@material-ui/icons/FilterList';
import {Field, reduxForm} from "redux-form";
import {renderSelectField} from "../moteur/PayrollEngineComponent";
import CancelIcon from "@material-ui/icons/Cancel";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import Excel from "../../assets/images/exel-xxl.png";
import {ThemeContext} from "../../App";
import {strings} from "../../i18n/Strings";
import NotFound from "../404/NotFound";

const validate = (values, props) => {
    const errors = {};
    const requiredFields = [
        'month',
        'year'
    ];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });

    return errors;
};

const ExtractionByUser = ({
                              classes,
                              columns,
                              list,
                              loading,
                              periods,
                              years,
                              pristine,
                              reset,
                              handleSubmit,
                              submitting
                          }) => {

    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState('J');
    const [year, setYear] = useState(moment(new Date()).format('YYYY'));
    const [month, setMonth] = useState(moment(new Date()).format('M'));

    const dispatch = useDispatch();

    const {_noDataFound, _search, _global, _exportJ, _exportM, _period, _year, _reset} = strings.payrollDetail;

    useEffect(() => {
        dispatch(exportPaieByUser(year, month, type));
    }, []);

    const getColumns = elements => {
        let rows = [];
        for (const i in elements) {
            rows.push(<CustomTableCell align="center" component="th" scope="row">{elements[i]}</CustomTableCell>)
        }
        return rows
    }

    const handleChangePage = async (event, newPage) => {
        setPage(newPage - 1);
    };

    const onSubmit = async values => {
        setMonth(values.month);
        setYear(values.year);
        await dispatch(exportPaieByUser(values.year, values.month, type));
        reset()
    }

    return (
        <ThemeContext.Consumer>
            {context => (
                <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={6} id="grid">
                            <h1 style={{
                                marginTop: 1,
                                color: context.selectedTheme.color,
                                fontWeight: 'normal'
                            }}>{_global}</h1>
                        </Grid>
                        {list && list.length > 0 && (
                            <Grid item xs={6}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Tooltip title={_search} aria-label="search">
                                        <Fab size={"small"}
                                             disabled={list && list.length === 0 }
                                             style={{
                                                 color: '#fff',
                                                 marginRight: 6,
                                                 background: context.selectedTheme.color
                                             }}
                                             onClick={() => setOpen(prevState => !prevState)}
                                        >
                                            <ImportExportIcon/>
                                        </Fab>
                                    </Tooltip>

                                    <Tooltip title={type === 'J' ? _exportM : _exportJ}
                                             aria-label="search">
                                        <Fab size={"small"}
                                             disabled={list && list.length === 0 }
                                             style={{color: '#fff', background: context.selectedTheme.color}}
                                             onClick={() => {
                                                 setType(prevState => {
                                                     if (prevState === 'J') {
                                                         return 'M';
                                                     }
                                                     return 'J'
                                                 });
                                                 dispatch(exportPaieByUser(year, month, type === 'J' ? 'M' : 'J'));
                                             }}
                                        >
                                            <img src={Excel} alt="" width={25} height={22}/>
                                        </Fab>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        )}

                    </Grid>
                    <hr color="#f5f5f5"/>
                    <br/>
                    <Collapse in={open} timeout={800}>
                        <Paper style={{backgroundColor: 'rgb(251 251 251)', padding: "30px 30px 14px 30px"}}>
                            <form style={{paddingTop: '20px', paddingBottom: '20px'}} onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                                            <InputLabel>
                                                {_period}
                                            </InputLabel>
                                            <Field name="month" label={_period} variant={'outlined'}
                                                   values={periods}
                                                   component={renderSelectField}/>
                                        </FormControl>
                                        <br/><br/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                                            <InputLabel>
                                                {_year}
                                            </InputLabel>
                                            <Field name="year" label={_year} variant={'outlined'} values={years}
                                                   component={renderSelectField}/>
                                        </FormControl>
                                        <br/><br/>
                                    </Grid>
                                </Grid>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button style={pristine ? {
                                        marginRight: '15px',
                                        color: '#fff',
                                        background: context.selectedTheme.color
                                    } : {
                                        marginRight: '15px',
                                        color: '#fff',
                                        background: context.selectedTheme.color
                                    }} size={"medium"} variant="contained"
                                            disabled={pristine} onClick={reset}
                                            startIcon={<CancelIcon/>}>{_reset}
                                    </Button>
                                    <Button size={"medium"} variant="contained" disabled={pristine || submitting}
                                            type="submit"
                                            style={pristine || submitting ? {
                                                color: '#fff',
                                                background: context.selectedTheme.color
                                            } : {
                                                color: '#fff',
                                                background: context.selectedTheme.color
                                            }}
                                            startIcon={<SearchRoundedIcon/>}
                                    >{_search}
                                    </Button>
                                </Box>
                            </form>
                        </Paper>
                        <br/>
                        <br/>
                    </Collapse>

                    <Table className={classes.table}>
                        <TableHead style={{ background: context.selectedTheme.color }}>
                            <TableRow>
                                {columns && columns.map(column =>
                                    <CustomTableCell align="center">{column}</CustomTableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list && list.slice(page * 10, (page + 1) * 10).map(row => (
                                <TableRow className={classes.row} key={row.id}>
                                    {getColumns(row)}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <PaginationComponent color={context.selectedTheme.color} rows={list} page={page} classes={classes} handleChangePage={handleChangePage}/>

                    {list && list.length === 0 && (
                        <NotFound msg={_noDataFound} />
                    )}

                    <Loader color={context.selectedTheme.color} open={loading}/>

                </div>
            )}
        </ThemeContext.Consumer>
    );
};

const mapStateToProps = state => {
        return {
            loading: state.exportReducer.loading,
            list: state.exportReducer.details,
            columns: state.exportReducer.columns,
            periods: state.PayrollEngineReducer.periods,
            years: state.PayrollEngineReducer.years,
            lang: state.salaryReducer.lang
        };
    }
;

export default connect(mapStateToProps)(reduxForm({
    form: 'exportByUser',
    validate,
    enableReinitialize: true
})(withStyles(styles)(ExtractionByUser)));