export const environmentPreprod = {
    production: false,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'http://dev.oauth.intelcia.com/uaa/oauth/token',
        authorize: 'http://dev.oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: 'snjsnknfeznv4s5dp',
        client_id: 'mypaie',
        response_type: 'code',
        redirectTo: new URL(window.location.href).origin
    }
};




export const environmentProd = {
    production: true,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://oauth.intelcia.com/uaa/oauth/token',
        //token: 'https://oauth-ext2.intelcia.com/uaa/oauth/token',
        authorize: 'https://oauth.intelcia.com/uaa/oauth/authorize',
        //authorize: 'https://oauth-ext2.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '45c4aV35Z1Eg52c',
        client_id: 'salary',
        response_type: 'code',
        redirectTo:  new URL(window.location.href).origin
    }
};


export const environmentLocalDev = {
    production: false,
    cross: {
        withCredentials: true
    },
    oauth : {
        token: 'https://oauth.intelcia.com/uaa/oauth/token',
        authorize: 'https://oauth.intelcia.com/uaa/oauth/authorize',
        state: 'INTELCIA_OAUTH_SESSION_STATE',
        code: 'INTELCIA_OAUTH_SESSION_CODE',
        remoteUser: 'INTELCIA_OAUTH_REMOTE_USER',
        scope: 'openid',
        secret: '45c4aV35Z1Eg52c',
        client_id: 'salary',
        response_type: 'code',
        redirectTo: 'http://localhost:3000'
    }
};

