import Swal from "sweetalert2";

export const alertx = async (type, msg) => {

    const color = type === 'success' ? '#a4db86' : '#f27474'
    const animation = type === 'success' ? 'animate__tada' : 'animate__shakeX'
    await Swal.fire({
        icon: type,
        backdrop: 'rgba(0,0,0,0.25)',
        html: `<hr style="position: relative;bottom: 167px; width: 512px;right: 49px;" color=${color} size="12"/>
               <p style="position: relative; bottom: 22px; font-size: 20px; font-family: 'Varela Round',serif; color: #4e4e4e">
                ${msg}<br>
                <hr style="display: none; position: relative;top: 38px; width: 512px;right: 49px;" color=${color} size="8"/>
               </p>`,
        showClass: {
            popup: `animate__animated ${animation}`
        },
        showConfirmButton: false,
        timer: 3000
    })

}