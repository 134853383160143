import {createMuiTheme } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink'
export const theme = createMuiTheme({
    palette: {
        primary: {main: '#3c4b64'},
        secondary: {main: pink[400]},
    },
    typography: {
        useNextVariants: true,
        "fontFamily": "\"Varela Round\", serif",
        "fontSize": 13,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500
    },
    shape: {
        borderRadius: 2
    },

});
