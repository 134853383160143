const initialState = {
    profile: {
        "userName": "",
        "firstName": "",
        "lastName": "",
        "email": "",
        "avatar": "",
        "countryCode": "",
        "sectret2FA": null,
        "qrCode": null,
        "authorities": [],
        "userApps": []
    },
    isLoggedIn: false,
    searchActive: false,
    loading: false,
    authorities: [],
    roleMan: false,
    language: {}
};

export default function authReducer(state = initialState, {type, payload}) {
    switch (type) {
        case "GET_LANGUAGE":
            return {...state, language: payload};
        case "GET_PROFILE_SUCCESS":
            console.log(payload.authorities)
            return {
                ...state,
                profile: payload,
                authorities: payload.authorities
                    .filter(authority => authority.clientId === 'salary')
                    .map(item => item.authority)
            };
        case "GET_AUTHORITIES":
            return {...state, authorities: payload};
        case "IS_LOGGED_IN":
            return {...state, isLoggedIn: payload};
        case "SEARCH_ACTIVE":
            return {...state, searchActive: payload};
        case "IS_LOADING":
            return {...state, loading: payload};
        case "IS_ROLE_MAN":
            return {...state, roleMan: payload};
        case "SET_CONTEXT":
            return {...state, context: payload};
        default:
            return state
    }
}
