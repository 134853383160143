import {getAxios, getCurrentUser} from "./AuthActions";
import {API} from "../api/Endpoints";
import {
    GET_ALL_COUNTERS,
    GET_ALL_COUNTERS_FAIL,
    GET_ALL_COUNTERS_SUCCESS,
    REMOVE_COUNTER,
    SET_CRITERIA
} from "../types/CounterTypes";
import Swal from "sweetalert2";
import {EXPORT_EVS, EXPORT_EVS_FAIL, SET_PAGES} from "../types/EvsTypes";

export const exportAllCounters = () => async dispatch => {
    const axios = getAxios();
    axios({
        url: `${API}/myPaie/counter/export`,
        method: 'get'
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export_counters.csv');
            document.body.appendChild(link);
            link.click();
            Swal.fire({
                icon: 'success',
                text: 'Le fichier a bien été exporté',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 3500
            });
        })
        .catch(() => {
            // dispatch({
            //     type: EXPORT_EVS_FAIL,
            //     result: []
            // });

            Swal.fire({
                icon: 'error',
                text: 'Opération échouée',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 3500
            });
        })
};
export const getAllCounters = (filter, page) => async dispatch => {
    const axios = getAxios();
    dispatch({
        type: GET_ALL_COUNTERS
    });
    axios({
        url: `${API}/myPaie/counter/findAll?page=${page}&size=10`,
        method: 'post',
        data: filter,
    })
        .then(response => {
            dispatch({
                type: GET_ALL_COUNTERS_SUCCESS,
                result: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: GET_ALL_COUNTERS_FAIL,
                result: []
            });
        })
};
export const removeCounter = id => async dispatch => {
    const axios = getAxios();
    const profile = getCurrentUser();
    const {userName} = profile;

    dispatch({
        type: REMOVE_COUNTER
    });
    axios({
        url: `${API}/myPaie/counter/deleteCounter?id=${id}&username=${userName}`,
        method: 'get',
    })
        .then(() => {
            Swal.fire({
                icon: 'success',
                text: 'OPÉRATION RÉUSSITE',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 2000
            });
        })
        .catch(() => {
            Swal.fire({
                icon: 'error',
                text: 'OPÉRATION ÉCHOUÉE',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 2000
            });
        })
};
export const affectCounters = data => async dispatch => {
    const axios = getAxios();
    // dispatch({
    //     type: REMOVE_COUNTER
    // });
    axios({
        url: `${API}/myPaie/counter/affectation`,
        method: 'post',
        data: data
    })
        .then((res) => {
            console.log(res);
            Swal.fire({
                icon: 'success',
                text: 'OPÉRATION RÉUSSITE',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 2000
            });
        })
        .catch(() => {
            Swal.fire({
                icon: 'error',
                text: 'OPÉRATION ÉCHOUÉE',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 2000
            });
        })
};
export const setCriteriaa = values => async dispatch => {
    dispatch({
        type: SET_CRITERIA,
        values
    });
};
export const setPagee = page => {
    return {
        type: SET_PAGES,
        page
    };
};
