import React, {useEffect, useState} from 'react';
import PaperComponent from "../../layout/PaperComponent";
import {Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {useDispatch} from "react-redux";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {strings} from "../../i18n/Strings";
import {getAllSites, getAllUsers, removeExcluded, setPageSites, setPageUsers} from "../../actions/ExcludeActions";

const DeleteExclusion = ({type, data, classes, open, onClose, color}) => {

    const {_cancelBtn, _deleteUser, _deleteSite, _delete, _attention} = strings;
    const dispatch = useDispatch();

    const onDelete = async id => {
        await dispatch(removeExcluded(type, id));
        onClose();
        setTimeout(
            () => {
                if (type === 'users') {
                    dispatch(setPageUsers(0));
                    dispatch(getAllUsers('', 0));
                } else {
                    dispatch(setPageSites(0));
                    dispatch(getAllSites('', 0));
                }
            },
            4000
        );
    };

    return (
        <div>
            <Dialog
                BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.25)'}}}
                open={open}
                classes={{
                    paper: classes.dialogg
                }}
                fullWidth
                onClose={onClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title">
                <DialogTitle
                    style={{cursor: 'move', color: '#fff', backgroundColor: color}}>
                    {_attention}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>

                        <p style={{fontFamily: "Roboto"}}>
                            {type === 'users' ? _deleteUser : _deleteSite}
                            <span style={{color: color}}>{type === 'users' ? data.registrationNumber : data.codeSirh &&
                                data.codeSirh.toUpperCase()} </span>?
                        </p>
                        <br/>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                style={{
                                    marginRight: '15px',
                                    color: '#fff',
                                    backgroundColor: color
                                }}
                                size={"large"}
                                variant="contained"
                                onClick={() => onDelete(data.id)}
                                endIcon={<HighlightOffIcon/>}
                            >
                                {_delete}
                            </Button>
                            <Button
                                style={{
                                    width: 120,
                                    color: '#fff',
                                    backgroundColor: color
                                }}
                                size={"large"}
                                variant="contained"
                                onClick={onClose}
                                endIcon={<ArrowRightAltIcon/>}
                            >
                                {_cancelBtn}
                            </Button>
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DeleteExclusion;
