import React, {useState} from 'react';
import {Button, Collapse, Grid, Chip, Fab, Tooltip, DialogContentText} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Field, reduxForm} from "redux-form";
import {renderBlockMotif, renderMotifDetails, renderSelectComponent, renderSelectPop} from "../evs/SearchComponent";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import {useStyles} from "./Step1";
import DoneIcon from '../../assets/images/1.jpg';
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Delete from "../../assets/images/delete.png";

const Step2 = ({reset, handleNext, handleBack, handleSubmit, motifDetails, blocks, blockMotifs}) => {
    const [listBlocks, setListBlocks] = useState(blocks);
    const [motifs, setMotifs] = useState([]);
    const [details, setDetails] = useState([]);
    const [motif, setMotif] = useState("");
    const [detail, setDetail] = useState("");
    const [bloc, setBloc] = useState("");
    const [typeArrondi, setTypeArrondi] = useState("");
    const [arrondi, setArrondi] = useState("");
    const [datas, setDatas] = useState([]);
    const classes = useStyles();

    const onSubmit = async () => {
        const step2 = {
            typeArrondi: typeArrondi,
            arrondi: arrondi,
            blocs: handleData()
        };
        console.log(step2);
        handleNext();
    };

    const handleData = () => {
        let list = [];
        datas.map( data => list.push(data.bloc + ',' + data.motif + ',' + data.detail));
        return list;
    };

    const handleBlocksChange = e => {
        const data = blockMotifs.filter(elem => elem.block === e.target.value);
        setBloc(e.currentTarget.innerText);
        setMotifs(data);
    };

    const handleMotifsChange = e => {
        const data = motifDetails.filter(elem => elem.motif === e.target.value);
        setDetails(data);
        setMotif(e.currentTarget.innerText);
    };

    const handleDetailChange = value => {
        setDetail(value);
    };

    const handleDelete = async (label) => {
        const filter = await blocks.filter(elem => elem.label === label);
        const list = await datas.filter(data => data.bloc !== label);
        await listBlocks.push(filter[0]);
        setListBlocks(listBlocks);
        setDatas(list);
    };

    const addBlock = async () => {
        const data = {
            bloc: bloc,
            motif: motif,
            detail: detail,
        };
        const filter = listBlocks.filter(elem => elem.label !== bloc);
        await datas.push(data);
        await setDatas(datas);
        setListBlocks(filter);
        setBloc("");
        setMotif("");
        setDetail("");
        setMotifs([]);
        setDetails([]);
        reset();
    };

    return (
        <div container>
            <br/>
            <form style={{padding: '17px 50px 35px 50px'}} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={6} style={{paddingLeft: 0}}>
                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                            <InputLabel>
                                Type Arrondi
                            </InputLabel>
                            <Field name="typeArrondi" label="Type Arrondi" variant={"outlined"} component={renderSelectComponent}
                                   values={[{id: 1, code: 'J', label: 'Jours'},{id: 2, code: 'M', label: 'Mois'}]}
                                   onChange={(e) => setTypeArrondi(e.target.value)}
                            />
                        </FormControl>
                        <br/><br/><br/>
                    </Grid>
                    <Grid item xs={6} style={{paddingRight: 0}}>
                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                            <InputLabel>
                                Choix Arrondi
                            </InputLabel>
                            <Field name="arrondi" variant={"outlined"} label="Choix Arrondi" component={renderSelectComponent}
                                   values={[{id: 1, code: '0.001', label: '0.001'},{id: 2, code: '0.0001', label: '0.0001'}]}
                                   onChange={(e) => setArrondi(e.target.value)}
                            />
                        </FormControl>
                        <br/><br/><br/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Tooltip title="Ajouter" aria-label="add">
                        <Fab disabled={bloc !== "Réalisé" && (bloc === "" || motif === "")} size={"small"}
                             style={{width: "36px", height: "35px", marginBottom: '20px'}}
                             className={bloc !== "Réalisé" && (bloc === "" || motif === "") ? "" : classes.fab}
                             onClick={addBlock}>
                            <AddIcon/>
                        </Fab>
                    </Tooltip>
                    <Tooltip title="Réinitialiser" aria-label="add">
                        <Fab size={"small"}
                             style={{width: "36px", height: "35px", marginBottom: '35px', marginLeft: '4px'}}
                             className={classes.fab} onClick={reset}>
                            <CloseIcon/>
                        </Fab>
                    </Tooltip>
                </Grid>
                <Grid container spacing={2}>
                    <FormControl size={"small"} variant={"outlined"} fullWidth>
                        <InputLabel>
                            Types Blocs
                        </InputLabel>
                        <Field name="block" variant={"outlined"} label="Type Blocs" values={listBlocks}
                               onChange={e => handleBlocksChange(e)} component={renderSelectComponent}
                        />
                    </FormControl>
                    <br/><br/><br/><br/>
                </Grid>

                <Collapse in={motifs && motifs.length > 0}>
                    <Grid container spacing={2}>
                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                            <InputLabel>
                                Motifs
                            </InputLabel>
                            <Field name="blockMotif" variant={"outlined"} label="Motifs" values={motifs}
                                   onChange={e => handleMotifsChange(e)} component={renderBlockMotif}
                            />
                        </FormControl>
                        <br/><br/><br/><br/>
                    </Grid>
                </Collapse>
                <Collapse in={details && details.length > 0}>
                    <Grid container spacing={2}>
                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                            <InputLabel>
                                Détail Motifs
                            </InputLabel>
                            <Field name="motifDetails" variant={"outlined"} label="Détail Motifs" values={details}
                                   onChange={(e) => handleDetailChange(e.currentTarget.innerText)}
                                   component={renderMotifDetails}
                            />
                        </FormControl>
                    </Grid>
                </Collapse>
                <Collapse style={{position: 'relative', top: '40px', left: '-6px'}} in={datas && datas.length > 0}>
                    <span style={{fontSize: '16px'}}>Données sélectionnées :</span><br/><br/>
                    {datas && datas.map(data =>
                        <React.Fragment>
                            <Grid container spacing={2}>
                                <Grid item xs={11}>
                                    <Chip label={data.bloc}
                                          style={{fontWeight: 'bold', color: "#fff", backgroundColor: "#2F80ED"}}/>
                                    {data.motif !== "" && (
                                        <React.Fragment>
                                            <img alt="" src={DoneIcon} height="20px" width="30px"
                                                 style={{position: 'relative', top: "7px"}}/>
                                            <Chip label={data.motif}
                                                  style={{
                                                      fontWeight: 'bold',
                                                      color: "#fff",
                                                      backgroundColor: "#2F80ED"
                                                  }}/>
                                        </React.Fragment>
                                    )}
                                    {data.detail !== "" && (
                                        <React.Fragment>
                                            <img alt="" src={DoneIcon} height="20px" width="30px"
                                                 style={{position: 'relative', top: "7px"}}/>
                                            <Chip label={data.detail}
                                                  style={{
                                                      fontWeight: 'bold',
                                                      color: "#fff",
                                                      backgroundColor: "#2F80ED"
                                                  }}/>
                                        </React.Fragment>
                                    )}
                                </Grid>
                                <Grid item xs={1} style={{paddingRight: '0px'}}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <a style={{cursor: 'pointer'}} onClick={() => {
                                            handleDelete(data.bloc).then();
                                        }}>
                                            <Tooltip title="Supprimer" aria-label="delete">
                                                <img src={Delete} width="30px" height="28px" alt="checked"/>
                                            </Tooltip>
                                        </a>
                                    </Box>
                                </Grid>
                                <br/><br/>
                            </Grid>
                            <br/><br/>
                        </React.Fragment>
                    )}
                </Collapse>
                <Box style={{marginRight: "-8px", marginTop: "50px"}} display="flex" justifyContent="flex-end">
                    <Button style={{marginRight: '10px'}} size={"medium"} variant="contained"
                            className={classes.button} onClick={handleBack}>Retour</Button>
                    <Button size={"medium"} variant="contained"
                            className={classes.button} type="submit">Valider</Button>
                </Box>
            </form>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        blocks: state.counterReducer.blocks,
        blockMotifs: state.counterReducer.blockMotifs,
        motifDetails: state.counterReducer.motifDetails,
        values: state.counterReducer.values
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: 'typeBlocks',
    enableReinitialize: true
})(Step2));
