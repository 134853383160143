export const LOAD_MONTHS = 'LOAD_MONTHS';
export const LOAD_MONTHS_SUCCESS = 'LOAD_MONTHS_SUCCESS';
export const LOAD_MONTHS_FAIL = 'LOAD_MONTHS_FAIL';

export const LOAD_IRS = 'LOAD_IRS';
export const LOAD_IRS_SUCCESS = 'LOAD_IRS_SUCCESS';
export const LOAD_IRS_FAIL = 'LOAD_IRS_FAIL';

export const SET_DEFAULT_PASSWORD = 'SET_DEFAULT_PASSWORD';

export const DOWNLOAD = 'DOWNLOAD';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAIL = 'DOWNLOAD_FAIL';

export const SET_LANGUAGE = 'SET_LANGUAGE';
