import React from "react";

export const language =
    window.navigator.userLanguage || window.navigator.language;

export function hasLanguage() {
    return language === "fr-FR" ? "DD/MM/YYYY" : "YYYY/MM/DD";
}

export const stringsLangs = {
    fr: {
        title: {
            _launch: 'LANCEMENT DU CALCUL',
            _periodicLaunch: 'LANCEMENT PÉRIODIQUE',
            _global: 'GLOBAL HEURES',
            _modulation: 'MODULATION',
            _exclude: 'CAS EXCLUS',
            _payEngine: 'MOTEUR DE PAIE',
            _salary: 'BULLETIN DE PAIE',
            _IR: 'DÉCLARATION IRS',
        },
        columns: {
            payroll: {
                _title: 'TITRE',
                _modulation: 'MODULATION',
                _period: 'PÉRIODE',
                _startDate: 'DATE DÉBUT LANCEMENT',
                _endDate: 'DATE FIN LANCEMENT',
                _launchBy: 'LANCÉ PAR',
                _status: 'STATUT',
                _percentage: 'POURCENTAGE',
                _actions: 'ACTIONS',
            },
            modulationHistory: {
                _launchBy: 'LANCÉ PAR',
                _period: 'PÉRIODE',
                _startDate: 'DATE DÉBUT OPÉRATION',
                _endDate: 'DATE FIN OPÉRATION',
                _status: 'STATUT',
                _sheets: 'FEUILLE',
            },
            excluderUser: {
                _matricule: 'MATRICULE',
                _firstname: 'NOM',
                _lastname: 'PRÉNOM',
                _email: 'EMAIL',
                _creationDate: 'DATE OPÉRATION',
                _entryDate: 'DATE ENTRÉE',
                _actions: 'ACTION'
            },
            excluderSite: {
                _label: 'LIBELLÉ',
                _code: 'CODE',
                _sirh: 'CODE SIRH',
                _creationDate: 'DATE OPÉRATION',
                _actions: 'ACTION'
            }
        },
        payrollDetail: {
            _title: 'DÉTAIL RÉSULTAT DE CALCUL',
            _global: 'GLOBAL HOURES',
            _validBtn: 'VALIDER',
            _rejectBtn: 'REJTER',
            _exportJ: 'EXPORT JOURNALIER',
            _exportM: 'EXPORT MENSUEL',
            _payExport: 'EXPORT PAIE',
            _excelExport: 'EXPORT EXCEL',
            _noDataFound: 'AUCUN RÉSULTAT TROUVÉ',
            _attention: 'ATTENTION',
            _isValidated: 'VOULEZ-VOUS VALIDER CE RÉSULTAT DE CALCUL ?',
            _isRejected: 'VOULEZ-VOUS REJETER CE RÉSULTAT DE CALCUL ?',
            _cancel: 'ANNULER',
            _reset: 'Réinitialiser',
            _search: 'RECHERCHER',
            _period: 'PÉRIODE',
            _year: 'ANNÉE',
        },
        launch: {
            _title: 'LANCEMENT DU CALCUL',
            _label: 'TITRE',
            _modulation: 'MODULATION',
            _country: 'PAYS',
            _rules: 'RÉGLEMENTATION',
            _startDate: 'DATE PAIE DÉBUT',
            _endDate: 'DATE PAIE FIN',
            _periodicity: 'PÉRIODICITÉ',
            _validate: 'VALIDER',
            _reset: 'Réinitialiser',
            _textArea: 'ENTRER LES MATRICULES ...',
            _isRequired: 'Tous les champs sont obligatoires',
            _verify: 'Merci de vérifier les matricules saisies !',
        },
        modulation: {
            _title: 'IMPORT MODULATION',
            _history: 'HISTORIQUE',
            _reset: 'Réinitialiser',
            _import: 'IMPORTER',
            _msg: 'Le fichier est rejecté. Seuls les fichiers EXCEL sont pris en charge',
            _file: 'Déposez un fichier ici ou cliquez',
            _year: 'ANNÉE',
            _period: 'PÉRIODE',
            _pending: 'OPÉRATION EN COURS DE TRAITEMENT',
            _select_file: 'Sélectionner un fichier'
        },
        history: {
            _title: 'HISTORIQUE MODULATION',
            _select: 'SÉLECTIONNER',
            _status: 'STATUT',
            _dateDeb: 'DATE DÉBUT',
            _dateFin: 'DATE FIN',
            _period: 'PÉRIODE',
            _reset: 'RÉINITIALISER',
            _search: 'RECHERCHER',
        }, userExclusion: {
            _title: 'PARAMÉTRAGE DES UTILISATEURS EXCLUS',
            _agentSearch: 'Rechercher un agent...',
            _add: 'AJOUTER',
            _list: 'LISTE',
            _delete: 'SUPPRIMER',
            _search: 'RECHERCHER'
        }
        , siteExclusion: {
            _title: 'PARAMÉTRAGE DES SITES EXCLUS',
            _search: 'RECHERCHER',
            _add: 'AJOUTER',
            _list: 'LISTE',
            _delete: 'SUPPRIMER',
            _siteSearch: 'RECHERCHER UN SITE...'
        },
        _isDeleted: 'VOULEZ-VOUS SUPPRIMER LE LANCEMENT ',
        _deleteUser: 'VOULEZ-VOUS SUPPRIMER LE COLLABORATEUR ',
        _deleteSite: 'VOULEZ-VOUS SUPPRIMER LE SITE ',
        _choixCompteur: 'COMPTEURS PAR POPULATION',
        _choixVariable: 'VARIABLES PAR POPULATION',
        _updateTitle: 'MODIFIER EVs ',
        _detailsTitle: 'DÉTAILS EVs ',
        _addTitle: 'AJOUTER UN EVs',
        _paramCompteur: 'COMPTEUR',
        _engine: 'MOTEUR DES ÉLÉMENTS VARIABLES',
        _status: 'Statut',
        _period: 'Période',
        _state: 'État de lancement',
        _title: 'Titre',
        _init: 'Réinitialiser',
        _search: 'Rechercher',
        _visualise: 'VISUALISER',
        _pending: 'EN COURS DE PRÉPARATION',
        _delete: 'SUPPRIMER',
        _deleted: 'SUPPRIMÉ',
        _retry: 'RELANCER LE CALCUL',
        _noDataFound: 'AUCUN RÉSULTAT TROUVÉ',
        _validate: 'EN ATTENTE DE VALIDATION',
        _valid: 'VALIDÉ',
        _reject: 'REJETÉ',
        _failed: 'ÉCHOUÉ',


        _myshiftModifs: 'MYSHIFT',
        _salaryTitle: 'MES BULLETINS DE PAIE',
        _irsTitle: 'DÉCLARATION IRS',
        _downloadBtn: 'TÉLÉCHARGER',
        _refreshBtn: 'RAFRAICHIR',
        _backBtn: 'RETOUR',
        _securedPDF: 'Pdf sécurisé',
        _allStatements: 'TOUS LES BULLETINS ',
        _allIrs: 'TOUS LES DÉCLARATION IRS ',
        _statement: 'BULLETINS ',
        _irs: 'IR ',
        _warning: 'Vos bulletins de paie ne seront pas protégés par un code d\'accès personnel',
        _warningIR: 'Vos déclarations IRS ne seront pas protégées par un code d\'accès personnel',
        _continueBtn: 'CONTINUER',
        _cancelBtn: 'ANNULER',
        _okBtn: 'CONFIRMER',
        _JAN: 'JAN',
        _FEV: 'FÉV',
        _MAR: 'MAR',
        _AVR: 'AVR',
        _MAI: 'MAI',
        _JUN: 'JUN',
        _JUL: 'JUL',
        _AOU: 'AOÛ',
        _SEP: 'SEP',
        _OCT: 'OCT',
        _NOV: 'NOV',
        _DEC: 'DÉC',
        lang: "fr",
        _notFound: 'Aucun bulletin de paie trouvé',
        _notFoundIR: 'Aucune déclarations IRS  trouvée',
        _attention: 'ATTENTION',
        _code: 'CODE DE SÉCURITÉ',
        _msg: 'Merci de renseigner votre code personnel pour sécuriser votre bulletin de paie',
        _msgIR: 'Merci de renseigner votre code personnel pour sécuriser votre déclaration IRS',
        _desc: '<p>Votre code d\'accès personnel vous permet de protéger l\'accès à vos bulletins de paie, notamment quand ' +
            'ceux-ci sont téléchargés sur des ordinateurs dont l\'utilisation est possible par d\'autres collaborateurs.' +
            '<br/> Nous vous recommandons fortement de garder votre code personnel et de protéger ainsi l\'accès' +
            ' à vos bulletins de paie</p>',
        _descIR: '<p>Votre code d\'accès personnel vous permet de protéger l\'accès à vos déclarations IRS, notamment quand ' +
            'ceux-ci sont téléchargés sur des ordinateurs dont l\'utilisation est possible par d\'autres collaborateurs.' +
            '<br/> Nous vous recommandons fortement de garder votre code personnel et de protéger ainsi l\'accès' +
            ' à vos déclarations IRS</p>',
        _submitBtn: 'VALIDER',
        _addCounter: 'AJOUTER UN COMPTEUR',
        _updateCounter: 'MODIFIER LE COMPTEUR'
    },
    en: {
        title: {
            _launch: 'START CALCULATION',
            _periodicLaunch: 'PERIODIC LAUNCH',
            _global: 'TOTAL HOURS',
            _modulation: 'MODULATION',
            _exclude: 'EXCLUDED CASES',
            _salary: 'PAY SLIP',
            _payEngine: 'PAYROLL ENGINE',
            _IR: 'TAX declarations'
        },
        columns: {
            payroll: {
                _title: 'TITLE',
                _modulation: 'MODULATION',
                _period: 'PERIOD',
                _startDate: 'LAUNCH START DATE',
                _endDate: 'LAUNCH END DATE',
                _launchBy: 'LAUNCHED BY',
                _status: 'STATUS',
                _percentage: 'PERCENTAGE',
                _actions: 'ACTIONS',
            },
            modulationHistory: {
                _launchBy: 'LAUNCHED BY',
                _period: 'PERIOD',
                _startDate: 'START DATE OF OPERATION',
                _endDate: 'END DATE OF OPERATION',
                _status: 'STATUS',
                _sheets: 'SHEET',
            },
            excluderUser: {
                _matricule: 'EMPLOYEE ID NUMBER',
                _firstname: 'LAST NAME',
                _lastname: 'FIRST NAME',
                _email: 'EMAIL',
                _creationDate: 'OPERATION DATE',
                _entryDate: 'DATE ENTERED',
                _actions: 'ACTION'
            },
            excluderSite: {
                _label: 'TITLE',
                _code: 'CODE',
                _sirh: 'HRIS CODE',
                _creationDate: 'OPERATION DATE',
                _actions: 'ACTION'
            }
        },
        payrollDetail: {
            _title: 'CALCULATION RESULT DETAILS',
            _validBtn: 'APPROVE',
            _rejectBtn: 'REJECT',
            _exportJ: 'DAILY EXPORT',
            _exportM: 'MONTHLY EXPORT',
            _payExport: 'PAYROLL EXPORT',
            _excelExport: 'EXCEL EXPORT',
            _noDataFound: 'NO RESULTS FOUND',
            _attention: 'CAUTION',
            _isValidated: 'DO YOU WANT TO APPROVE THIS CALCULATION RESULT?',
            _isRejected: 'DO YOU WANT TO REJECT THIS CALCULATION RESULT?',
            _cancel: 'CANCEL',
            _reset: 'Reset',
            _search: 'SEARCH',
            _period: 'PERIOD',
            _year: 'YEAR',
            _global: 'TOTAL HOURS'
        },
        launch: {
            _title: 'START CALCULATION',
            _label: 'TITLE',
            _modulation: 'MODULATION',
            _country: 'COUNTRY',
            _rules: 'REGULATION',
            _startDate: 'PAYROLL START DATE',
            _endDate: 'PAYROLL END DATE',
            _periodicity: 'PERIODICITY',
            _validate: 'APPROVE',
            _reset: 'Reset',
            _textArea: 'ENTER THE EMPLOYEE ID NUMBERS ...',
            _isRequired: 'All fields are mandatory',
            _verify: 'Please check the employee numbers you have entered!',
        },
        history: {
            _title: 'MODULATION HISTORY',
            _reset: 'RESET',
            _search: 'SEARCH',
            _status: 'STATUS',
            _dateDeb: 'START DATE',
            _select: 'SELECT',
            _dateFin: 'END DATE',
            _period: 'PERIOD',
        },
        modulation: {
            _title: 'IMPORT MODULATION',
            _history: 'HISTORY',
            _reset: 'Reset',
            _import: 'IMPORT',
            _msg: 'The file has been rejected. Only EXCEL files are supported',
            _file: 'Drop a file here or click',
            _year: 'YEAR',
            _period: 'PERIOD',
            _pending: 'OPERATION IN PROGRESS',
            _select_file: 'Select file'
        },
        userExclusion: {
            _title: 'CONFIGURATION OF EXCLUDED USERS',
            _agentSearch: 'Search for an agent...',
            _add: 'ADD',
            _list: 'LIST',
            _delete: 'DELETE',
            _search: 'SEARCH',
        }, siteExclusion: {
            _title: 'CONFIGURATION OF EXCLUDED SITES',
            _siteSearch: 'SEARCH A SITE...',
            _add: 'ADD',
            _list: 'LIST',
            _delete: 'DELETE',
            _search: 'SEARCH',
        },
        _isDeleted: 'DO YOU WANT TO DELETE THE LAUNCH ',
        _deleteUser: 'DO YOU WANT TO DELETE THE EMPLOYEE ',
        _deleteSite: 'DO YOU WANT TO DELETE THE SITE ',
        _engine: 'VARIABLE ELEMENT ENGINE',
        _status: 'Status',
        _period: 'Period',
        _state: 'Launch status',
        _title: 'Title',
        _init: 'Reset',
        _search: 'Search',
        _visualise: 'VIEW',
        _pending: 'IN PREPARATION',
        _delete: 'DELETE',
        _deleted: 'DELETED',
        _retry: 'RESTART THE CALCULATION',
        _noDataFound: 'NO RESULTS FOUND',
        _validate: 'PENDING APPROVAL',
        _valid: 'APPROVED',
        _reject: 'REJECTED',
        _failed: 'FAILED',
        _choixCompteur: 'METERS BY POPULATION',
        _choixVariable: 'VARIABLES BY POPULATION',
        _updateTitle: 'MODIFY VEs ',
        _detailsTitle: 'VE DETAILS ',
        _addTitle: 'ADD VEs',
        _paramCompteur: 'METER',
        _myshiftModifs: 'MYSHIFT',
        _desc: '<p>Your personal access code allows you to protect access your pay slips, especially when they are ' +
            'downloaded to computers that can be used by other employees.' +
            '<br/><br/> We strongly recommend that you keep your personal code to protect access your pay slips.</p>',
        _descIR: '<p>Your personal access code allows you to protect access to your tax declarations, especially when they are ' +
            'downloaded to computers that can be used by other employees.' +
            '<br/><br/> We strongly recommend that you keep your personal code to protect access to your tax declarations.</p>',
        _salaryTitle: 'MY PAY SLIPS',
        _irsTitle: 'IRS RETURN',
        _downloadBtn: 'DOWNLOAD',
        _refreshBtn: 'REFRESH',
        _backBtn: 'BACK',
        _securedPDF: 'Secured PDF',
        _allStatements: 'ALL PAY SLIPS ',
        _allIrs: 'All tax declarations ',
        _statement: 'PAY SLIPS ',
        _irs: 'TAX ',
        _warning: 'Your pay slips will not be protected by a personal access code',
        _warningIR: 'Your tax declarations will not be protected by a personal access code',
        _continueBtn: 'CONTINUE',
        _cancelBtn: 'CANCEL',
        _okBtn: 'CONFIRM',
        _JAN: 'JAN',
        _FEV: 'FEB',
        _MAR: 'MAR',
        _AVR: 'APRIL',
        _MAI: 'MAY',
        _JUN: 'JUNE',
        _JUL: 'JULY',
        _AOU: 'AUG',
        _SEP: 'SEPT',
        _OCT: 'OCT',
        _NOV: 'NOV',
        _DEC: 'DEC',
        lang: "en",
        _attention: 'ATTENTION',
        _code: 'SECURITY CODE',
        _msg: 'Please enter your personal code to secure your pay slip',
        _msgIR: 'Please enter your personal code to secure your tax declaration',
        _submitBtn: 'APPROVE',
        _notFound: 'No pay slips found',
        _notFoundIR: 'No tax declaration found',
        _addCounter: 'ADD A METER',
        _updateCounter: 'MODIFY THE METER'
    }
};
export let strings = stringsLangs.fr;

export const setLanguage = (countryCode) => {
    if (countryCode === "PT") {
        strings = stringsLangs.en;
        return stringsLangs.en;
    } else {
        strings = stringsLangs.fr;
        return stringsLangs.fr;
    }
};
