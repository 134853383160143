import React, {Component} from 'react';
import './App.css';
import AppShell from './layout/AppShell2'
import connect from "react-redux/es/connect/connect";
import Tour from "reactour";
import {setContext} from "./actions/AuthActions";

export const ThemeContext = React.createContext();

const setCookie = (cvalue) => {
    document.cookie = ['theme=', JSON.stringify(cvalue)].join('');
};

const getCookie = () => {
    let result = document.cookie.match(new RegExp('theme=([^;]+)'));
    result && (result = JSON.parse(result[1]));
    return result;
};

class App extends Component {

    constructor(props) {
        super(props);
        props.dispatch(setContext(getCookie() === null ?
            {
                code: "theme_ui_default",
                color: "#1181B2"
            } : getCookie()));

    }

    state = {
        isTourOpen: false,
        isShowingMore: false,
        options: [
            {
                code: "theme_ui_default",
                color: "rgb(0, 71, 171)",
                backgroundColor: "linear-gradient(to left, #0096FF, #0047AB)",
                backgroundHover: "linear-gradient(to left, #aeccf5, #aeccf5)"
            },
            {
                code: "theme_ui_1",
                color: "rgb(0, 182, 194)",
                backgroundColor: "linear-gradient(to left, #AADAC3, #00b6c2)",
                backgroundHover: "linear-gradient(to left, rgb(170, 218, 195), rgb(170, 218, 195))"
            },
            {
                code: "theme_ui_2",
                color: "#11998e",
                backgroundColor: "linear-gradient(to left, #11998e, #38ef7d",
                backgroundHover: "linear-gradient(to left, rgb(61 222 163), rgb(61 222 163))"
            },
            {
                code: "theme_ui_3",
                color: "rgb(248, 7, 89)",
                backgroundColor: "linear-gradient(to left, rgb(248, 7, 89), rgb(188, 78, 156))",
                backgroundHover: "linear-gradient(to left, rgb(241,158,208), rgb(241,158,208))"
            },
            {
                code: "theme_ui_4",
                color: "#f12711",
                backgroundColor: "linear-gradient(to right, #f5af19, #f12711)",
                backgroundHover: "linear-gradient(to right, rgb(243 189 74),rgb(243 189 74)"
            }, {
                code: "theme_ui_5",
                color: "#00dbde",
                backgroundColor: "linear-gradient(to left, #00dbde, #fc00ff)",
                backgroundHover: "linear-gradient(to left, RGB(179,167,207), RGB(179,167,207))"
            },
        ],
        selectedTheme: getCookie() === null ?
            {
                code: "theme_ui_default",
                color: "#1181B2",
                backgroundColor: "linear-gradient(to left, #0096FF, #0047AB)",
                backgroundHover: "linear-gradient(to left, #aeccf5, #aeccf5)"
            } : getCookie(),
    };

    toggleShowMore = () => {
        this.setState((prevState) => ({
            isShowingMore: !prevState.isShowingMore
        }));
    };

    closeTour = () => {
        this.setState({isTourOpen: false});
    };

    openTour = () => {
        this.setState({isTourOpen: true});
    };

    render() {
        const {isLoggedIn} = this.props.auth;
        const {dispatch} = this.props;
        const {options, selectedTheme, isShowingMore, isTourOpen, darkTheme, dark} = this.state;
        const accentColor = "#5cb7b7";

        const setTheme = (cvalue) => {
            const obj = Object.assign({}, selectedTheme);
            obj.color = cvalue
            setCookie(obj);
            dispatch(setContext(obj));
            this.setState(
                {
                    selectedTheme: obj
                });
        };

        return (
            <div style={{position: "relative"}}>
                {isLoggedIn &&
                <ThemeContext.Provider value={{
                    options: options,
                    selectedTheme: selectedTheme,
                    setCookie: setTheme,
                    getCookie: getCookie
                }}>
                    <AppShell
                        openTour={this.openTour}
                        toggleShowMore={this.toggleShowMore}
                        isShowingMore={isShowingMore}
                        auth={this.props.auth}/>
                    <Tour
                        onRequestClose={this.closeTour}
                        steps={tourConfig}
                        isOpen={isTourOpen}
                        maskClassName="mask"
                        className="helper"
                        rounded={5}
                        accentColor={accentColor}
                    />
                </ThemeContext.Provider>
                }
            </div>
        );
    }
}

const tourConfig = [
    {
        selector: '[data-tut="reactour__iso"]',
        content: `Ok, let's start with the name of the Tour that is about to begin.`
    },
    {
        selector: '[data-tut="reactour__logo"]',
        content: `And this is our cool bus...`
    },
    {
        selector: '[data-tut="reactour__copy"]',
        content: `Keep in mind that you could try and test everithing during the Tour. 
      For example, try selecting the highlighted text…`
    }
];

function mapStateToProps(state) {
    return {auth: state.auth};
}

export default connect(
    mapStateToProps
)(App);
