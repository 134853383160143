import React from "react";
import {Grid, MenuItem, Select, TextField} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {now} from "moment";

export const renderTextField = ({input, label, meta: {touched, error}, ...custom}) => (
    <TextField fullWidth id="outlined-basic" label={label} size={"small"} variant="outlined" hintText={label}
               floatingLabelText={label}
               errorText={touched && error}
               {...input}
               {...custom}
    />
);

export const renderText = ({input, label, meta: {touched, error}, ...custom}) => (
    <TextField fullWidth label={label} size={"small"} variant="standard" hintText={label}
               floatingLabelText={label}
               errorText={touched && error}
               {...input}
               {...custom}
    />
);

export const renderDate = ({input, label, selectedDate, handleDateChange, meta: {touched, error}, ...custom}) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="space-around">
            <KeyboardDatePicker
                disableToolbar
                format="dd/MM/yyyy"
                fullWidth
                maxDate={now()}
                margin="normal"
                id="date-picker-inline"
                label={label}
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </Grid>
    </MuiPickersUtilsProvider>
);

export const renderSelectField = ({input, label, values, meta: {touched, error}, ...custom}) => (
    <Select
        label={label}
        {...input}
        {...custom}
    >
        <MenuItem value={""}>
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val => val.name &&
            <MenuItem key={val.id} value={val.code}>{val.name}</MenuItem>
        )}
    </Select>
);

export const renderSelectPop = ({input, label, values, ...custom}) => (
    <Select
        label={label}
        {...input}
        {...custom}
    >
        <MenuItem value="">
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val => val.description &&
            <MenuItem key={val.id} value={val.id}>{val.description}</MenuItem>
        )}
    </Select>
);
