import React from 'react';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import ListItems from "./TitleData";
import MainToolBar from './MainToolBar';
import Routes from '../routes/Index';
import {BrowserRouter} from "react-router-dom";
import {connect} from "react-redux";
import {ThemeContext} from "../App";
import AccessDenied from "../components/403/AccessDenied";

const drawerWidth = 270;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        height: 56,
        //background: 'linear-gradient(-45deg, #2F80ED, #56CCF2) !important',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    barShift: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    appBarShift: {
        //boxShadow: '0 3px 8px -5px black',
        //width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        overflowX: "hidden",
        boxShadow: '0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 1px 0 rgba(0, 0, 0, 0.19)',
        zIndex: 99,
        background: '#F4F9F9',
        marginTop: 50
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 0',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        borderBottom: '1px solid #cfd8dc'
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    loader: {
        position: 'fixed',
        top: '50%',
        left: '50%'
    },
    err: {
        position: 'fixed',
        top: '20%',
        textAlign: 'center',
        left: '35%',
        color: '#3c4b64'

    },
    colorPrimary: {
        backgroundColor: '#e8eaf6'
    },
    barColorPrimary: {
        backgroundColor: '#00b6c2'
    }
});

class PersistentDrawerLeft extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: true,
        };
    }

    render() {
        const {classes, handleState, auth, auth: {profile: {countryCode}}} = this.props;

        const {open} = this.state;
        const {openTour, isShowingMore, toggleShowMore} = this.props;

        const handleDrawerToggle = () => {
            this.setState(state => ({open: !state.open}));
        };


        console.log(auth.authorities)

        return (
            <ThemeContext.Consumer>
                {context => (
                    <BrowserRouter basename="/">
                        {auth.authorities.includes('ROLE_USER_SALARY') ?
                            // loadMonthsByYear && loadMonthsByYear.length > 0 ?
                            (
                                <div className={classes.root}>
                                    <CssBaseline/>
                                    <React.Fragment>
                                        <AppBar
                                            style={{
                                                background: context.selectedTheme.color
                                            }}
                                            elevation={0}
                                            position="fixed"
                                            className={classNames("appMenu", classes.appBar, {
                                                [classes.appBarShift]: open,
                                            })}
                                        >
                                            <MainToolBar countryCode={countryCode} handleState={handleState}
                                                         handleDrawerToggle={handleDrawerToggle}/>
                                        </AppBar>
                                        <React.Fragment data-tut="reactour__iso">
                                            <Drawer
                                                id="drawer"
                                                variant="persistent"
                                                anchor="left"
                                                open={open}
                                                classes={{
                                                    paper: classes.drawerPaper
                                                }}
                                            >
                                                <ListItems color={context.selectedTheme.color}
                                                           countryCode={countryCode}/>
                                            </Drawer>
                                            <main className={classNames(classes.content, {
                                                [classes.contentShift]: open,
                                            })}
                                            >
                                                <div className={classes.toolbar}/>
                                                <div style={{marginLeft: `${drawerWidth}px`}}>
                                                    <Routes/>
                                                </div>
                                            </main>
                                        </React.Fragment>
                                    </React.Fragment>
                                </div>
                            )
                            // : (
                            //     <div className={classes.root}>
                            //         <CssBaseline/>
                            //         <React.Fragment>
                            //             <AppBar
                            //                 elevation={0}
                            //                 position="fixed"
                            //                 className={classNames("appMenu", classes.appBar, {
                            //                     [classes.barShift]: open,
                            //                 })}
                            //             >
                            //                 <MainToolBar countryCode={countryCode} handleDrawerToggle={handleDrawerToggle}/>
                            //             </AppBar>
                            //             <React.Fragment>
                            //             </React.Fragment>
                            //         </React.Fragment>
                            //     </div>
                            //
                            // )
                            : (<AccessDenied/>)}

                    </BrowserRouter>
                )}
            </ThemeContext.Consumer>
        );
    }
}

const mapStateToProps = state => {
    return {
        loadMonthsByYear: state.salaryReducer.loadMonthsByYear
    };
};
export default connect(
    mapStateToProps
)(withStyles(
    styles, {withTheme: true})
(PersistentDrawerLeft));




