import React, {useEffect, useState} from "react";
import {strings} from "../../i18n/Strings";
import {useDispatch} from "react-redux";
import {getAllSites, getAllUsers, setPageSites, setPageUsers} from "../../actions/ExcludeActions";
import {Avatar, Box, Grid, Table, TableBody, TableHead, TableRow, Tooltip} from "@material-ui/core";
import {CustomTableCell} from "../evs/EVsComponent";
import {Pagination} from "@material-ui/lab";
import DeleteComponent from "../moteur/DeleteComponent";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {CORE_API, DEAFULT_AVATAR_CORE_SERVER} from "../../api/Endpoints";
import {profile} from "../../styles/CommonStyles";
import DeleteExclusion from "./DeleteExclusion";
import {makeStyles} from "@material-ui/core/styles";

const styles = makeStyles({
    ul: {
        "& .MuiPaginationItem-root": {
            background: props => props.color,
            color: '#fff'
        }
    },
});

const Tablex = (props) => {
    const {
        classes,
        columns,
        color,
        rows,
        type,
        usersPage,
        sitesPage,
        totalSitesElements,
        totalUsersElements
    } = props;

    const {ul} = styles(props);

    const [row, setRow] = useState({});
    const [count, setCount] = useState(0);
    const [open, setOpen] = useState(false);
    const {_noDataFound, _delete} = strings;
    const dispatch = useDispatch();
    const {bigAvatar, padd_top_10} = profile;

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (type === "users") {
            console.log(totalUsersElements)
            if (totalUsersElements % 10 === 0) {
                setCount(totalUsersElements / 10);
            } else {
                setCount(Math.trunc(totalUsersElements / 10) + 1);
            }
        }
    }, [type, totalUsersElements]);

    useEffect(() => {
        if (type === "sites") {
            if (totalSitesElements % 10 === 0) {
                setCount(totalSitesElements / 10);
            } else {
                setCount(Math.trunc(totalSitesElements / 10) + 1);
            }
        }
    }, [type, totalSitesElements]);

    const handleChangePage = async (event, newPage) => {
        if (type === 'users') {
            await dispatch(getAllUsers("", newPage - 1));
            dispatch(setPageUsers(newPage - 1));
        } else {
            await dispatch(getAllSites("", newPage - 1));
            dispatch(setPageSites(newPage - 1));
        }

    };

    return (
        <div>
            <Table className={classes.table}>
                <TableHead style={{background: color}}>
                    <TableRow>
                        {columns && columns.map(column =>
                            <CustomTableCell align="center">{column.headerName}</CustomTableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows && rows.map(row => (
                        <TableRow className={classes.row} key={row.id}>
                            {row.registrationNumber && <CustomTableCell>
                                <Avatar
                                    style={Object.assign({}, bigAvatar, padd_top_10, {position: 'relative', left: 23})}
                                >
                                    <img style={{height: 52, marginTop: 10}}
                                         src={`${CORE_API}/${DEAFULT_AVATAR_CORE_SERVER}/${row.registrationNumber}`}
                                         alt=""/>

                                </Avatar>
                            </CustomTableCell>}

                            {row.shortName && <CustomTableCell align="center">{row.shortName}</CustomTableCell>}
                            {row.code && <CustomTableCell align="center">{row.code}</CustomTableCell>}
                            {row.codeSirh && <CustomTableCell align="center">{row.codeSirh}</CustomTableCell>}
                            {row.registrationNumber &&
                            <CustomTableCell align="center">{row.registrationNumber}</CustomTableCell>}
                            {row.firstName && <CustomTableCell align="center">{row.firstName.toUpperCase()}</CustomTableCell>}
                            {row.lastName && <CustomTableCell align="center">{row.lastName.toUpperCase()}</CustomTableCell>}
                            {row.email && <CustomTableCell align="center">{row.email}</CustomTableCell>}
                            {row.creationDate &&
                            <CustomTableCell align="center">{row.creationDate.replace('T', ' ')}</CustomTableCell>}
                            {row.entryDate && <CustomTableCell align="center">{row.entryDate}</CustomTableCell>}
                            <CustomTableCell align="center">
                                <a style={{cursor: 'pointer'}} onClick={() => {
                                    setRow(row);
                                    setOpen(true);
                                }}>
                                    <Tooltip title={_delete} aria-label="add">
                                        <DeleteForeverIcon
                                            style={{
                                                color: color,
                                                position: 'relative', top: 6,
                                                transform: 'scale(1.17)'
                                            }}
                                        />
                                    </Tooltip>
                                </a>
                            </CustomTableCell>
                        </TableRow>
                    ))}

                </TableBody>
            </Table>


            {rows && rows.length === 0 && (
                <div className={classes.root}>
                    <p style={{
                        paddingBottom: '0px', color: color, textAlign: 'center', fontSize: '25px',
                        fontFamily: 'Roboto'
                    }}>{_noDataFound}
                    </p>
                </div>
            )}

            {rows && rows.length > 0 && (
                <Grid container style={{position: "relative", top: 20}}>
                    <Grid item xs={6}>
                        <Box display="flex">
                            <Pagination
                                size={"small"}
                                showFirstButton
                                showLastButton
                                classes={{
                                    ul: ul
                                }}
                                count={count}
                                page={type === 'users' ? usersPage + 1 : sitesPage + 1}
                                onChange={handleChangePage}
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}

            <DeleteExclusion type={type} classes={classes} color={color} open={open} onClose={onClose} data={row}/>
        </div>
    );
};

export default Tablex;