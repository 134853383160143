import React from 'react';
import Lock from "../../assets/images/21.jpg";
import {strings} from "../../i18n/Strings";

const NotFound = ({msg}) => {
    return (
        <div style={{fontFamily: "Roboto", textAlign: 'center', position: 'relative', top: 48}}>
            <img src={Lock} height="500px" width="500px" alt="not found"/>
            <p style={{position: "relative", top: "-94px", fontFamily:'Roboto', textAlign: 'center', color: '#646464', fontSize: '25px'}}>
                {msg}<br/>
            </p>
        </div>
    );
};

export default NotFound;
