import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Directory from '../../assets/images/Folder-icon.png'
import File from '../../assets/images/Folder-icon.png'
import FolderIcon from '@material-ui/icons/Folder';
import {Box, Grid, Button, withStyles, LinearProgress, Switch, Tooltip, Fab} from "@material-ui/core";
import {FormControlLabel, Radio, Checkbox} from '@material-ui/core';
import {
    download,
    downloadPerYears,
    loadMonthsByYear
} from "../../actions/salaryActions";
import {connect} from "react-redux";
import Swal from 'sweetalert2';
import NotFound from "../404/NotFound";
import {strings} from "../../i18n/Strings";
import {ThemeContext} from "../../App";
import Loader from "../../layout/Loader";
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ForwardIcon from '@material-ui/icons/Forward';

const styles = props => ({
    colorPrimary: {
        backgroundColor: '#459ff2',
    },
    barColorPrimary: {
        backgroundColor: '#4dc5f2',
    },
    caption: {
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // cursor: 'pointer',
        // transform: 'translate( -50%, -50%)',
        // textAlign: 'center',
        //

        display: 'inline',
        /* float: left; */
        position: 'relative',
        /* font-size: 0.7em; */
        right: '37%',
        /* z-index: 100; */
        /* align-content: revert; */
        bottom: 67,
        color: 'white'
    }
});

const PurpleSwitch = withStyles({
    switchBase: {
        //color: '#2460A7FF',
        '&$checked': {
            color: '#0472b4',
        },
        '&$checked + $track': {
            backgroundColor: '#0472b4',
        },
    },
    checked: {},
    track: {},
})(Switch);

const Radios = withStyles({
    root: {
        color: '#fff',
        '&$checked': {
            color: '#fff',
        },
    },
    checked: {},
})((props) => <Checkbox on color="default" size={"small"} {...props} />);

const Radioss = withStyles({
    root: {
        color: '#fff',
        '&$checked': {
            color: '#fff',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" size={"small"} {...props}/>);


class SalaryComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: '',
            selectedMonth: [],
            selectedYears: [],
            securePDF: true,
            months: []
        };
        props.dispatch(loadMonthsByYear(false, false));

        // Swal.fire({
        //     icon: 'info',
        //     html: props.profile.countryCode !== 'PT' ? '<p style="font-family: Verdana">Chers Collaborateurs, \n' +
        //         'Nous vous informons qu’une nouvelle mesure de sécurité a été mise en place sur MyPaie.\n' +
        //         'Lors du téléchargement de votre fiche de paie, un code de vérification vous sera transmis par mail,' +
        //         ' à renseigner pour le téléchargement et pour l\'ouverture de la fiche de paie.</p>' :
        //         '<p style="font-family: Verdana">Dear collaborators,\n' +
        //         'A new security measure has been implemented on MyPaie.\n' +
        //         'When downloading your payslip, a verification code will be sent to you by email.\n' +
        //         'Please enter this code for downloading and securePDFing the paylislip.</p>',
        //     width: 700,
        //     backdrop: 'rgba(191, 191, 191, 0.4)',
        //     showConfirmButton: true,
        //     confirmButtonColor: '#56CCF2',
        //     focusConfirm: false,
        //     confirmButtonText: "D'accord",
        //     confirmButtonAriaLabel: 'Thumbs up, great!',
        //     customClass: {
        //         confirmButton: 'sweet_confirmbuttonImportant',
        //     },
        //     showClass: {
        //         popup: 'animated tada',
        //         icon: 'animated heartBeat delay-1s'
        //     },
        //     hideClass: {
        //         popup: 'animated zoomOut',
        //     }
        // })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.lang !== this.props.lang) {
            this.forceUpdate();
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.forceLoadMonths();
    }

    forceLoadMonths() {
        const {dispatch} = this.props;
        let {year, months, hasForced} = this.state;
        const d = new Date();
        const inMonths = (m) => (months || []).includes(m)
        const currMonth = String(d.getMonth() + 1);
        const prevMonth = String(d.getMonth());
        const monthNotIn = months.length && (!inMonths(prevMonth) || (d.getDate() >= 20 && !inMonths(currMonth)));

        if (year == d.getFullYear() && monthNotIn && !hasForced) {
            this.setState({
                hasForced: true
            })
            dispatch(loadMonthsByYear(false, true))
        }
    }

    render() {
        const {year, securePDF, months, selectedMonth, selectedYears} = this.state;
        const {loading, dispatch, loadMonthsByYearr, classes, hasDefaultPassword} = this.props;
        const {
            _salaryTitle, _statement, _SEP, _allStatements, _securedPDF, _OCT, _NOV, _MAR, _MAI, _JUN, _JUL, _JAN
            , _FEV, _DEC, _downloadBtn, _refreshBtn, _backBtn, _AVR, _AOU, _attention, _desc, _okBtn, _notFound
        } = strings;

        const handleDirectory = year => {
            const obj = loadMonthsByYearr.filter(item => item.year === year);
            this.setState({
                selectedMonth: [],
                selectedYears: [],
                year: year,
                months: obj ? obj[0].months : []
            })
        };

        const handleMonth = month => {
            switch (month) {
                case '01':
                    return _JAN;
                case '02':
                    return _FEV;
                case '03':
                    return _MAR;
                case '04':
                    return _AVR;
                case '05':
                    return _MAI;
                case '06':
                    return _JUN;
                case '07':
                    return _JUL;
                case '08':
                    return _AOU;
                case '09':
                    return _SEP;
                case '10':
                    return _OCT;
                case '11':
                    return _NOV;
                case '12':
                    return _DEC;
                default:
                    return '';
            }
        };

        const initMonths = () => {
            months.map(month => {
                this.setState({
                    [month]: false,
                    selectedMonth: []
                })
            });
        };

        const initYears = () => {
            loadMonthsByYearr.map(item => {
                this.setState({
                    selectedYears: [],
                    [item.year]: false
                })
            });
        };

        const downloadByYear = async () => {
            await dispatch(downloadPerYears(selectedYears, loadMonthsByYearr, hasDefaultPassword, securePDF, false));
            initYears();
        };

        const downloadByMonthsOfYear = async () => {
            await dispatch(download(year, selectedMonth, hasDefaultPassword, securePDF, false));
            initMonths();
        };

        const handleChange = () => {
            if (securePDF) {
                Swal.fire({
                    title: `${_attention}`,
                    html: `<p style="font-size: 18px">${_desc}<p/>`,
                    icon: 'warning',
                    backdrop: 'rgba(0,0,0,0.25)',
                    showCancelButton: true,
                    width: 700,
                    showConfirmButton: false,
                    cancelButtonColor: '#f0ad4e',
                    cancelButtonText: `${_okBtn}`,

                    customClass: {
                        confirmButton: 'sweet_confirmbuttonImportant',
                        cancelButton: 'sweet_confirmbuttonImportant',
                    },
                });
            }
            this.setState({
                securePDF: !securePDF
            })
        };

        const handleClick = (month) => {
            this.setState({
                [month]: !this.state[month]
            });
            if (!selectedMonth.includes(month)) {
                selectedMonth.push(month)
            }
            if (this.state[month] && selectedMonth.includes(month)) {
                this.setState({
                    selectedMonth: selectedMonth.filter(m => m !== month)
                })
            }
        }

        return (
            <ThemeContext.Consumer>
                {context => (
                    <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                        {/*<Paper variant={"outlined"}  elevation={0}>*/}
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <h1 style={{
                                    marginTop: 1,
                                    color: context.selectedTheme.color,
                                    //color: '#0472b4',
                                    fontWeight: 'normal'
                                }}>{_salaryTitle}</h1>
                            </Grid>
                            <Grid item xs={6}>
                                {loadMonthsByYearr && loadMonthsByYearr.length !== 0 && (
                                    <Box display="flex" justifyContent="flex-end">
                                        <FormControlLabel
                                            control={
                                                <PurpleSwitch
                                                    checked={securePDF}
                                                    onChange={handleChange}
                                                    name="checkedB"
                                                />
                                            }
                                            label={<span style={{fontFamily: 'Roboto'}}>{_securedPDF}</span>}
                                        />
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                        <hr color="#f5f5f5"/>
                        {year !== '' && (
                            <React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item sm={6} xs={12}>
                                        <p style={{
                                            //marginLeft: '37px',
                                            color: context.selectedTheme.color,
                                        }}>{_statement}
                                            - {year} > </p>
                                    </Grid>
                                    <Grid item sm={6} xs={12} id="grid">
                                        <Box display="flex" justifyContent="flex-end">
                                            <Tooltip title={_refreshBtn} aria-label="search">
                                                <Fab size={"small"}
                                                     style={{
                                                         marginRight: 10,
                                                         backgroundColor: context.selectedTheme.color, color: '#fff'
                                                     }}
                                                     variant={"round"}
                                                     onClick={() => {
                                                         dispatch(loadMonthsByYear(false, true));
                                                         initMonths();
                                                         initYears();
                                                         this.setState({
                                                             year: '',
                                                             selectedMonth: []
                                                         })
                                                     }}
                                                >
                                                    <RefreshIcon/>
                                                </Fab>
                                            </Tooltip>
                                            <Tooltip title={_downloadBtn} aria-label="search">
                                                <Fab size={"small"}
                                                     style={{
                                                         marginRight: 10,
                                                         backgroundColor: context.selectedTheme.color, color: '#fff'
                                                     }}
                                                     disabled={selectedMonth.length === 0 || loading}
                                                     variant={"round"}
                                                     onClick={downloadByMonthsOfYear}
                                                >
                                                    <GetAppIcon/>
                                                </Fab>
                                            </Tooltip>
                                            <Tooltip title={_backBtn} aria-label="search">
                                                <Fab size={"small"}
                                                     style={{
                                                         backgroundColor: context.selectedTheme.color, color: '#fff'
                                                     }}
                                                     variant={"round"}
                                                     onClick={() => {
                                                         initMonths();
                                                         initYears();
                                                         this.setState({
                                                             year: '',
                                                             selectedMonth: []
                                                         })
                                                     }}
                                                >
                                                    <ForwardIcon/>
                                                </Fab>
                                            </Tooltip>

                                        </Box>
                                    </Grid>
                                </Grid>
                                <hr color="#f5f5f5"/>
                            </React.Fragment>)}
                        {year === '' && loadMonthsByYearr && loadMonthsByYearr.length > 0 && (
                            <React.Fragment>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <p style={{
                                            //marginLeft: '37px',
                                            color: context.selectedTheme.color,
                                        }}>{_allStatements} > </p>
                                    </Grid>
                                    <Grid item xs={6} style={{paddingTop: 10, paddingRight: '20px'}}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Tooltip title={_refreshBtn} aria-label="search">
                                                <Fab size={"small"}
                                                     style={{
                                                         marginRight: 10,
                                                         backgroundColor: context.selectedTheme.color, color: '#fff'
                                                     }}
                                                     variant={"round"}
                                                     onClick={() => dispatch(loadMonthsByYear(false, true))}
                                                >
                                                    <RefreshIcon/>
                                                </Fab>
                                            </Tooltip>
                                            <Tooltip title={_downloadBtn} aria-label="search">
                                                <Fab size={"small"}
                                                     style={{
                                                         backgroundColor: context.selectedTheme.color, color: '#fff'
                                                     }}
                                                     disabled={selectedYears.length === 0 || loading}
                                                     variant={"round"}
                                                     onClick={downloadByYear}
                                                >
                                                    <GetAppIcon/>
                                                </Fab>
                                            </Tooltip>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <hr color="#f5f5f5"/>
                            </React.Fragment>)}
                        {loadMonthsByYearr && loadMonthsByYearr.length === 0 && <NotFound msg={_notFound}/>}
                        <br/>
                        {year !== "" ?
                            <React.Fragment>
                                <div style={{display: "flex", flexWrap: "wrap", marginLeft: -12}}>
                                    {months && months.sort().map(month => (
                                        <div style={{position: "relative", width: 150, height: 150, marginRight: 20}}>
                                            <div style={{position: "absolute"}}>
                                                <FolderIcon
                                                    //onClick={() => handleClick(month)}
                                                    style={{
                                                        color: context.selectedTheme.color,
                                                        width: 150,
                                                        height: 150
                                                    }}/>
                                            </div>
                                            <div style={{position: "absolute", top: 19, left: 17}}>
                                                <FormControlLabel
                                                    control={<Radios onChange={() => handleClick(month)}
                                                                     checked={this.state[month]} name="checkedG"/>}
                                                />
                                            </div>
                                            <div style={{
                                                position: "absolute", display: "flex", color: 'white',
                                                justifyContent: "center", alignItems: "center", height: 150, width: 150,
                                                fontFamily: 'Varela Round', cursor: "pointer"
                                            }}
                                                //onClick={() => handleClick(month)}
                                            >
                                                {
                                                    handleMonth(month)
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment> :
                            <React.Fragment>
                                <div style={{display: "flex", flexWrap: "wrap", marginLeft: -12}}>
                                    {loadMonthsByYearr && loadMonthsByYearr.map(item => (
                                        <div style={{position: "relative", width: 150, height: 150, marginRight: 20}}>
                                            <div style={{position: "absolute"}}>
                                                <FolderIcon
                                                    onClick={() => handleDirectory(item.year)}
                                                    style={{
                                                        color: context.selectedTheme.color,
                                                        width: 150,
                                                        height: 150
                                                    }}/>
                                            </div>
                                            <div style={{position: "absolute", top: 19, left: 17}}>
                                                <FormControlLabel
                                                    control={<Radioss onChange={() => {
                                                        this.setState({
                                                            [item.year]: !this.state[item.year]
                                                        });
                                                        if (!selectedYears.includes(item.year)) {
                                                            selectedYears.push(item.year)
                                                        }
                                                        if (this.state[item.year] && selectedYears.includes(item.year)) {
                                                            this.setState({
                                                                selectedYears: selectedYears.filter(m => m !== item.year)
                                                            })
                                                        }
                                                    }} checked={this.state[item.year]} name="checkedG"/>}
                                                />
                                            </div>
                                            <div style={{
                                                position: "absolute", display: "flex", color: 'white',
                                                justifyContent: "center", alignItems: "center", height: 150, width: 150,
                                                fontFamily: 'Varela Round', cursor: "pointer"
                                            }}
                                                 onClick={() => handleDirectory(item.year)}
                                            >
                                                {
                                                    item.year
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment>
                        }

                        <Loader color={context.selectedTheme.color} open={loading}/>
                    </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}

const
    mapStateToProps = state => {
        return {
            profile: state.auth.profile,
            loadMonthsByYearr: state.salaryReducer.loadMonthsByYear,
            pdfSecurity: state.salaryReducer.pdfSecurity,
            hasDefaultPassword: state.salaryReducer.hasDefaultPassword,
            loading: state.salaryReducer.loading,
            lang: state.salaryReducer.lang
        };
    };
export default connect(mapStateToProps)(withStyles(styles)(SalaryComponent));
