import React from 'react';
import {Box, CircularProgress, Dialog, DialogContent, Grid, Paper, Tooltip, Typography} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import Draggable from "react-draggable";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import {getMonth} from "./PayrollEngineComponent";
import {ThemeContext} from "../../App";

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: '50px'
    },
    title: {
        top: -50,
        position: 'relative',
        fontSize: 22,
        fontFamily: 'Roboto',
        color: '#797979',
        fontWeight: 'bold'
    }, paragraph: {
        top: -44,
        position: 'relative',
        fontSize: 12,
        fontFamily: 'monospace',
        color: '#797979'
    },
    bottom: {
        color: '#e7e7e7',
        position: 'relative',
        left: 68,
        top: 20
    },
    top: {
        color: '#0195d8',
        animationDuration: '550ms',
        position: 'relative',
        left: -80,
        top: 20
    },
}));

const Paperr = props => {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper elevation={6} style={{borderRadius: 50, width: '350px'}} {...props} />
        </Draggable>
    );
};

const Detail = (props) => {
    const classes = useStyles();
    const {open, data, onClose, periods, color} = props;
    return (
        <div className={classes.root}>
            <Dialog
                BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.25)'}}}
                open={open}
                classes={{
                    // paper: classes.dialog
                }}
                fullWidth
                onClose={onClose}
                PaperComponent={Paperr}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogContent style={{backgroundColor: 'rgb(247, 247, 247)', borderRadius: 30, textAlign: 'center'}}>

                    <CircularProgress className={classes.bottom} size={150} variant="determinate" value={100}/>
                    <CircularProgress className={classes.top} size={150} variant="determinate"
                                      value={data.etatLancemet === 'E' ? 100 : data.pourcentage}/>
                    <Box
                        sx={{
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography style={{position: 'relative', top: -100}} variant="caption" component="div"
                                    color="text.secondary">
                                    <span style={{fontSize: 50, color: '#0195d8'}}>
                                        {`${Math.round(data.etatLancemet === 'E' ? 100 : data.pourcentage)}`}
                                    </span>
                            <span style={{fontSize: 15}}>%</span>
                        </Typography>
                    </Box>
                    <p className={classes.title}>{data.titre}</p>
                    <p className={classes.paragraph}>Le calcul a été lancé
                        le {data.dateLancement && data.dateLancement.split('T')[0]} par <br/> {data.lancerPar}</p>
                    <Grid container>
                        <Grid item xs={3}/>
                        <Grid item xs={6}>
                            <Brightness1Icon style={{color: '#0195d8', width: 15}}/>
                            <span style={{
                                position: 'relative',
                                top: -6,
                                left: 4
                            }}>{getMonth(data.periode, periods)}</span>
                        </Grid>
                    </Grid>


                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Detail;