const apiMapping = {
    "prod": "https://myblt.intelcia.com",
    "preprod": "https://myblt.intelcia.com",
    "localDev": "https://myblt.intelcia.com",

};
export const API = apiMapping["prod"];

export const CORE_API = "https://mscore.intelcia.com";
export const DEAFULT_AVATAR_CORE_SERVER = "picture/default";
export const OAUTH_LOGOUT_DEV = "http://oauth-ext2.intelcia.com/uaa/logout";
export const OAUTH_LOGOUT_PROD = "https://oauth-ext2.intelcia.com/uaa/logout";
