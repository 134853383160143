import React, {useEffect} from 'react'
import {Field, reduxForm} from 'redux-form';
import {
    TextField,
    Button,
    Grid,
    Box,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    withStyles,
    DialogTitle, DialogContent, DialogContentText, Dialog
} from "@material-ui/core";
import {styles} from "../../styles/CommonStyles";
import {connect, useDispatch} from "react-redux";
import {
    getAllCountries,
    getAllEVs,
    getAllPopulations,
    setCriteria,
    setPage
} from "../../actions/EVsActions";
import PaperComponent from "../../layout/PaperComponent";
import {getAllCounters, setCriteriaa, setPagee} from "../../actions/CounterActions";

const renderTextField = ({input, label, meta: {touched, error}, ...custom}) => (
    <TextField fullWidth id="outlined-basic" label={label} size={"small"} variant="standard" hintText={label}
               floatingLabelText={label}
               errorText={touched && error}
               {...input}
               {...custom}
    />
);

export const renderSelectPop = ({input, label, variant, values, meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={variant ? variant : "standard"}
        {...input}
        {...custom}
    >
        <MenuItem value="">
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val => val.description &&
            <MenuItem key={val.id} value={val.id}>{val.description}</MenuItem>
        )}
    </Select>
);

export const renderSelectComponent = ({input, label, variant, values, meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={variant ? variant : "standard"}
        {...input}
        {...custom}
    >
        <MenuItem value="">
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val =>
            <MenuItem key={val.id} value={val.code}>{val.label}</MenuItem>
        )}
    </Select>
);

export const renderBlockMotif = ({input, label, variant, values,  meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={variant ? variant : "standard"}
        {...input}
        {...custom}
    >
        <MenuItem value="">
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(block =>
            block && block.motifs.map(val => <MenuItem key={val.id} value={val.code}>{val.label}</MenuItem>)
        )}
    </Select>
);

export const renderMotifDetails = ({input, label, variant, values,  meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={variant ? variant : "standard"}
        {...input}
        {...custom}
    >
        <MenuItem value="">
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(motif =>
            motif && motif.details.map(val => <MenuItem key={val.id} value={val.code}>{val.label}</MenuItem>)
        )}
    </Select>
);

export const renderSelectField = ({input, label, values, meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        variant={"standard"}
        {...input}
        {...custom}
    >
        <MenuItem value={""}>
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val => val.name &&
            <MenuItem key={val.id} value={val.code}>{val.name}</MenuItem>
        )}
    </Select>
);
export const selectField = ({input, label, values, value, meta: {touched, error}, ...custom}) => (
    <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        label={label}
        value={value}
        variant={"standard"}
        {...input}
        {...custom}
    >
        <MenuItem value={""}>
            <em>-- SÉLÉCTIONNER --</em>
        </MenuItem>
        {values && values.map(val =>
            <MenuItem key={val.code} value={val.code}>{val.code}</MenuItem>
        )}
    </Select>
);

const SearchComponent = props => {
    const {handleSubmit, pristine, reset, component, populations, pays, search, classes, onClose} = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllPopulations(""));
        dispatch(getAllCountries());
    }, []);


    const onSubmit = async values => {
        if (component === 'counter') {
            await dispatch(setCriteriaa(values));
            await dispatch(getAllCounters(values, 0));
        } else {
            await dispatch(setCriteria(values));
            await dispatch(getAllEVs(values, 0));
        }
    };
    const onInit = async () => {
        reset();
        const filter = {
            codeVariable: null,
            codeCounter: null,
            idCountry: null,
            idPopulation: null
        };

        if (component === 'counter') {
            dispatch(setCriteriaa(filter));
            await dispatch(getAllCounters(filter, 0));
            dispatch(setPagee(0));
        } else {
            dispatch(setCriteria(filter));
            await dispatch(getAllEVs(filter, 0));
            dispatch(setPage(0));
        }


    };
    return (

        <Dialog
            BackdropProps={{style: {backgroundColor: 'rgba(0,0,0,0.25)'}}}
            open={search}
            classes={{
                paper: classes.dialog
            }}
            fullWidth
            onClose={() => {
                onClose();
            }}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle className={classes.alert}>RECHERCHER</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <form style={{paddingTop: '20px'}} onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                {component === 'counter' ?
                                    <Field name="codeCounter" component={renderTextField} label="Code"/>
                                    : <Field name="codeVariable" component={renderTextField} label="Code"/>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl size={"small"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        Pays
                                    </InputLabel>
                                    <Field name="codeCountry" label="Pays" values={pays} component={renderSelectField}
                                           onChange={e => dispatch(getAllPopulations(e.target.value))}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl size={"small"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        Population
                                    </InputLabel>
                                    <Field name="idPopulation" label="Population" values={populations}
                                           component={renderSelectPop}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <br/><br/>
                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                style={{
                                    marginRight: '15px',
                                    backgroundColor: '#2F80ED',
                                    color: '#fff',
                                }}
                                size={"medium"}
                                variant="contained"
                                onClick={onInit}>
                                RÉINITIALISER
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: '#2F80ED',
                                    color: '#fff',
                                }}
                                size={"medium"}
                                type="submit"
                                variant="contained"
                                onClick={() => console.log("search")}>
                                RECHERCHER
                            </Button>
                        </Box>
                    </form>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
};

const mapStateToProps = state => {
    return {
        pays: state.evsReducer.pays,
        populations: state.evsReducer.populations
    };
};
export default connect(mapStateToProps)(reduxForm({
    form: 'search',
    enableReinitialize: true
})(withStyles(styles)(SearchComponent)));


