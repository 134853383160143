import React from 'react';
import {Box, Grid} from "@material-ui/core";
import {Pagination} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    ul: {
        "& .MuiPaginationItem-root": {
            backgroundColor: props => props.color,
            color: '#fff'
        }
    }
});

const PaginationComponent = (props) => {
    const {rows, page, handleChangePage} = props;
    const {ul} = useStyles(props);

    return (
        <>
            {rows && rows.length > 0 && (
                <Grid container style={{position: "relative", top: 20}}>
                    <Grid item xs={6}>
                        <Box display="flex">
                            <Pagination
                                size={"small"}
                                showFirstButton
                                showLastButton
                                count={rows.length % 10 === 0 ? rows.length / 10 : Math.trunc(rows.length / 10) + 1}
                                page={page + 1}
                                onChange={handleChangePage}
                                classes={{
                                    ul: ul
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            )}
        </>

    );
};

export default PaginationComponent;