import React, {useEffect} from 'react';
import {addExcludedSite, getAllSites, getAllUsers} from "../../actions/ExcludeActions";
import {strings} from "../../i18n/Strings";
import {connect, useDispatch} from "react-redux";
import {useStyles} from "./ExcludedUsers";
import {reduxForm} from "redux-form";
import {Box, Divider, Grid, TextField, Tooltip, withStyles} from "@material-ui/core";
import {styles} from "../../styles/CommonStyles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ApartmentRoundedIcon from "@material-ui/icons/ApartmentOutlined";
import SearchIcon from "@material-ui/icons/GroupAddOutlined";
import SearchIconn from "@material-ui/icons/SearchRounded";
import {ThemeContext} from "../../App";
import Tablex from "./Tablex";
import Loader from "../../layout/Loader";

const ExcludedSites = ({classes, loading, inputVal, sites, usersPage, sitesPage, success, totalSitesElements}) => {
    const [inputValue, setInputValue] = React.useState("");
    const classess = useStyles();
    const {_title, _add, _list, _search, _siteSearch} = strings.siteExclusion;
    const {_creationDate, _actions, _code, _label, _sirh} = strings.columns.excluderSite;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllSites("", 0));
    }, []);

    useEffect(() => {
        if (success) {
            setInputValue("");
        }
    }, [success]);


    const addExcluded = async () => {
        await dispatch(addExcludedSite(inputValue));
    };

    const searchExcludedSite = async () => {
        dispatch(getAllSites(inputValue, 0));
    };

    const handleChangeInputValue = newValue => {
        setInputValue(newValue);
    };

    return (
        <ThemeContext.Consumer>
            {context => (
                <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <h1 style={{
                                marginTop: 1,
                                color: context.selectedTheme.color,
                                fontWeight: 'normal'
                            }}>{_title}</h1>
                        </Grid>
                    </Grid>
                    <hr color="#f5f5f5"/>
                    <br/><br/>
                    <Box display="flex" justifyContent="flex-end">
                        <form style={{paddingTop: '20px', paddingBottom: '20px'}}>
                            <Paper component="form" className={classess.root}>
                                <IconButton style={{color: context.selectedTheme.color}} className={classess.iconButton}
                                            onClick={() => {
                                                dispatch(getAllSites('', 0));
                                                setInputValue('');
                                            }}
                                            aria-label="menu">
                                    <Tooltip title={_list} aria-label="add">
                                        <ApartmentRoundedIcon/>
                                    </Tooltip>
                                </IconButton>
                                <TextField
                                    style={{width: "253px", marginBottom: "27px"}}
                                    label={_siteSearch}
                                    variant="standard"
                                    value={inputValue}
                                    onChange={(event) => handleChangeInputValue(event.target.value)}
                                />
                                <IconButton style={{color: context.selectedTheme.color}} className={classess.iconButton}
                                            aria-label="add"
                                            disabled={inputValue === ''}
                                            onClick={() => addExcluded()}>
                                    <Tooltip title={_add} aria-label="add">
                                        <SearchIcon/>
                                    </Tooltip>
                                </IconButton>
                                <Divider className={classess.divider} orientation="vertical"/>
                                <IconButton style={{color: context.selectedTheme.color}} className={classess.iconButton}
                                            aria-label="directions"
                                            onClick={searchExcludedSite}>
                                    <Tooltip title={_search} aria-label="add">
                                        <SearchIconn/>
                                    </Tooltip>
                                </IconButton>
                            </Paper>
                        </form>
                    </Box>

                    <Tablex color={context.selectedTheme.color} classes={classes} rows={sites} usersPage={usersPage}
                            sitesPage={sitesPage}
                            type={"sites"} totalSitesElements={totalSitesElements} columns={
                        [
                            {headerName: _label},
                            {headerName: _code},
                            {headerName: _sirh},
                            {headerName: _creationDate},
                            {headerName: _actions}
                        ]
                    }/>
                    <Loader color={context.selectedTheme.color} open={loading}/>
                </div>
            )}
        </ThemeContext.Consumer>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.exclusReducer.loading,
        totalSitesElements: state.exclusReducer.totalSitesElements,
        sites: state.exclusReducer.sites,
        sitesPage: state.exclusReducer.sitesPage,
        inputVal: state.exclusReducer.inputVal,
        success: state.exclusReducer.success,
        lang: state.salaryReducer.lang
    };
};
export default connect(mapStateToProps)(reduxForm({
    form: 'excludedSites',
    enableReinitialize: true
})(withStyles(styles)(ExcludedSites)));
