import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Directory from '../../assets/images/Folder-icon.png'
import File from '../../assets/images/Folder-icon.png'
import {Box, Grid, Button, withStyles, LinearProgress, Switch, Tooltip, Fab} from "@material-ui/core";
import {FormControlLabel, Radio, Checkbox} from '@material-ui/core';
import {
    download,
    downloadPerYears, loadIRsByYear, loadMonthsByYear,
} from "../../actions/salaryActions";
import {connect} from "react-redux";
import Swal from 'sweetalert2';
import NotFound from "../404/NotFound";
import {strings} from "../../i18n/Strings";
import {ThemeContext} from "../../App";
import Loader from "../../layout/Loader";
import FolderIcon from "@material-ui/icons/Folder";
import GetAppIcon from "@material-ui/icons/GetApp";
import ForwardIcon from "@material-ui/icons/Forward";
import RefreshIcon from "@material-ui/icons/Refresh";

const PurpleSwitch = withStyles({
    switchBase: {
        //color: '#2460A7FF',
        '&$checked': {
            color: '#0472b4',
        },
        '&$checked + $track': {
            backgroundColor: '#0472b4',
        },
    },
    checked: {},
    track: {},
})(Switch);

const styles = props => ({
    colorPrimary: {
        backgroundColor: '#459ff2',
    },
    barColorPrimary: {
        backgroundColor: '#4dc5f2',
    }
});

class IRsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: '',
            selectedMonth: [],
            selectedYears: [],
            securePDF: true,
            months: []
        };
        props.dispatch(loadIRsByYear(true, false));
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.lang !== this.props.lang) {
            this.forceUpdate();
        }
    }

    render() {
        const {year, securePDF, months, selectedMonth, selectedYears} = this.state;
        const {loading, dispatch, loadMonthsByYear, classes, hasDefaultPassword} = this.props;
        const {
            _irsTitle, _irs, _allIrs, _SEP, _securedPDF, _OCT, _NOV, _MAR, _MAI, _JUN, _JUL, _JAN
            , _FEV, _DEC, _downloadBtn, _refreshBtn, _backBtn, _AVR, _AOU, _attention, _descIR, _okBtn,_notFoundIR
        } = strings;

        const handleClose = () => {
            this.setState({
                securePDF: false
            })
        };

        const Radios = withStyles({
            root: {

                color: '#fff',
                '&$checked': {
                    color: '#fff',
                },
            },
            checked: {},
        })((props) => <Checkbox on color="default" size={"small"} {...props} />);

        const Radioss = withStyles({
            root: {
                color: '#fff',
                '&$checked': {
                    color: '#fff',
                },
            },
            checked: {},
        })((props) => <Checkbox color="default" size={"small"} {...props}/>);

        const handleDirectory = year => {
            const obj = loadMonthsByYear.filter(item => item.year === year);
            this.setState({
                selectedMonth: [],
                selectedYears: [],
                year: year,
                months: obj ? obj[0].months : []
            })
        };

        const handleMonth = month => {
            switch (month) {
                case '01':
                    return _JAN;
                case '02':
                    return _FEV;
                case '03':
                    return _MAR;
                case '04':
                    return _AVR;
                case '05':
                    return _MAI;
                case '06':
                    return _JUN;
                case '07':
                    return _JUL;
                case '08':
                    return _AOU;
                case '09':
                    return _SEP;
                case '10':
                    return _OCT;
                case '11':
                    return _NOV;
                case '12':
                    return _DEC;
                default:
                    return '';
            }
        };

        const initMonths = () => {
            months.map(month => {
                this.setState({
                    [month]: false,
                    selectedMonth: []
                })
            });
        };

        const initYears = () => {
            loadMonthsByYear.map(item => {
                this.setState({
                    selectedYears: [],
                    [item.year]: false
                })
            });
        };

        const downloadByYear = async () => {
            await dispatch(downloadPerYears(selectedYears, loadMonthsByYear, hasDefaultPassword, securePDF, true));
            initYears();
        };

        const downloadByMonthsOfYear = async () => {
            await dispatch(download(year, selectedMonth, hasDefaultPassword, securePDF, true));
            initMonths();
        };

        const handleChange = () => {
                if (securePDF) {
                    Swal.fire({
                        title: `${_attention}`,
                        html: `<p style="font-size: 18px">${_descIR}<p/>`,
                        icon: 'warning',
                        backdrop: 'rgba(0,0,0,0.25)',
                        showCancelButton: true,
                        width: 700,
                        showConfirmButton: false,
                        cancelButtonColor: '#f0ad4e',
                        cancelButtonText: `${_okBtn}`,

                        customClass: {
                            confirmButton: 'sweet_confirmbuttonImportant',
                            cancelButton: 'sweet_confirmbuttonImportant',
                        },
                    });
                }
                this.setState({
                    securePDF: !securePDF
                })
            };

        const handleClick = (month) => {
            this.setState({
                [month]: !this.state[month]
            });
            if (!selectedMonth.includes(month)) {
                selectedMonth.push(month)
            }
            if (this.state[month] && selectedMonth.includes(month)) {
                this.setState({
                    selectedMonth: selectedMonth.filter(m => m !== month)
                })
            }
        }

        return (
            <ThemeContext.Consumer>
                {context => (
            <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                {/*<Paper variant={"outlined"}  elevation={0}>*/}
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <h1 style={{
                            marginTop: 1,
                            color: context.selectedTheme.color,
                            fontWeight: 'normal'
                        }}>{_irsTitle}</h1>
                    </Grid>
                    <Grid item xs={6}>
                        {loadMonthsByYear && loadMonthsByYear.length !== 0 && (
                            <Box display="flex" justifyContent="flex-end">
                                <FormControlLabel
                                    control={
                                        <PurpleSwitch
                                            checked={securePDF}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color='secondary'
                                        />
                                    }

                                    label={<span style={{fontFamily: 'Roboto'}}>{_securedPDF}</span>}
                                />
                            </Box>
                        )}

                    </Grid>
                </Grid>
                <hr color="#f5f5f5"/>
                {year !== '' && (
                    <React.Fragment>
                        <Grid container spacing={1}>
                            <Grid item sm={6} xs={12}>
                                <p style={{
                                    color: context.selectedTheme.color
                                }}>{_irs}
                                    - {year} > </p>
                            </Grid>
                            <Grid item sm={6} xs={12} id="grid">
                                <Box display="flex" justifyContent="flex-end">
                                    <Tooltip title={_refreshBtn} aria-label="search">
                                        <Fab size={"small"}
                                             style={{
                                                 marginRight: 10,
                                                 backgroundColor: context.selectedTheme.color, color: '#fff'
                                             }}
                                             variant={"round"}
                                             onClick={() => {
                                                 dispatch(loadIRsByYear(true, true));
                                                 initMonths();
                                                 initYears();
                                                 this.setState({
                                                     year: '',
                                                     selectedMonth: []
                                                 })
                                             }}
                                        >
                                            <RefreshIcon/>
                                        </Fab>
                                    </Tooltip>
                                    <Tooltip title={_downloadBtn} aria-label="search">
                                        <Fab size={"small"}
                                             style={{
                                                 marginRight: 10,
                                                 backgroundColor: context.selectedTheme.color, color: '#fff'
                                             }}
                                             disabled={selectedMonth.length === 0 || loading}
                                             variant={"round"}
                                             onClick={downloadByMonthsOfYear}
                                        >
                                            <GetAppIcon/>
                                        </Fab>
                                    </Tooltip>
                                    <Tooltip title={_backBtn} aria-label="search">
                                        <Fab size={"small"}
                                             style={{
                                                 backgroundColor: context.selectedTheme.color, color: '#fff'
                                             }}
                                             variant={"round"}
                                             onClick={() => {
                                                 initMonths();
                                                 initYears();
                                                 this.setState({
                                                     year: '',
                                                     selectedMonth: []
                                                 })
                                             }}
                                        >
                                            <ForwardIcon/>
                                        </Fab>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                        <hr color="#f5f5f5"/>
                    </React.Fragment>)}
                {year === '' && loadMonthsByYear && loadMonthsByYear.length > 0 && (
                    <React.Fragment>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <p style={{
                                    //marginLeft: '37px',
                                    color: context.selectedTheme.color
                                }}>{_allIrs} > </p>
                            </Grid>
                            <Grid item xs={6} style={{paddingTop: 10, paddingRight: '20px'}}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Tooltip title={_refreshBtn} aria-label="search">
                                        <Fab size={"small"}
                                             style={{
                                                 marginRight: 10,
                                                 backgroundColor: context.selectedTheme.color, color: '#fff'
                                             }}
                                             variant={"round"}
                                             onClick={() => dispatch(loadIRsByYear(true, true))}
                                        >
                                            <RefreshIcon/>
                                        </Fab>
                                    </Tooltip>
                                    <Tooltip title={_downloadBtn} aria-label="search">
                                        <Fab size={"small"}
                                             style={{
                                                 backgroundColor: context.selectedTheme.color, color: '#fff'
                                             }}
                                             disabled={selectedYears.length === 0 || loading}
                                             variant={"round"}
                                             onClick={downloadByYear}
                                        >
                                            <GetAppIcon/>
                                        </Fab>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Grid>
                        <hr color="#f5f5f5"/>
                    </React.Fragment>)}

                {loadMonthsByYear && loadMonthsByYear.length === 0 && <NotFound msg={_notFoundIR}/>}
                <br/>
                {year !== "" ?
                    <React.Fragment>
                        <div style={{display: "flex", flexWrap: "wrap", marginLeft: -12}}>
                            {months && months.sort().map(month => (
                                <div style={{position: "relative", width: 150, height: 150, marginRight: 20}}>
                                    <div style={{position: "absolute"}}>
                                        <FolderIcon
                                            //onClick={() => handleClick(month)}
                                            style={{
                                                color: context.selectedTheme.color,
                                                width: 150,
                                                height: 150
                                            }}/>
                                    </div>
                                    <div style={{position: "absolute",  top: 19, left: 17}}>
                                        <FormControlLabel
                                            control={<Radios onChange={() => handleClick(month)} checked={this.state[month]} name="checkedG"/>}
                                        />
                                    </div>
                                    <div style={{
                                        position: "absolute", display: "flex", color: 'white',
                                        justifyContent: "center", alignItems: "center", height: 150, width: 150,
                                        fontFamily: 'Varela Round', cursor: "pointer"
                                    }}
                                        //onClick={() => handleClick(month)}
                                    >
                                        {
                                            handleMonth(month)
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </React.Fragment> :
                    <React.Fragment>
                        <div style={{display: "flex", flexWrap: "wrap", marginLeft: -12}}>
                            {loadMonthsByYear && loadMonthsByYear.map(item => (
                                <div style={{position: "relative", width: 150, height: 150, marginRight: 20}}>
                                    <div style={{position: "absolute"}}>
                                        <FolderIcon
                                            onClick={() => handleDirectory(item.year)}
                                            style={{
                                                color: context.selectedTheme.color,
                                                width: 150,
                                                height: 150
                                            }}/>
                                    </div>
                                    <div style={{position: "absolute",  top: 19, left: 17}}>
                                        <FormControlLabel
                                            control={<Radioss onChange={() => {
                                                this.setState({
                                                    [item.year]: !this.state[item.year]
                                                });
                                                if (!selectedYears.includes(item.year)) {
                                                    selectedYears.push(item.year)
                                                }
                                                if (this.state[item.year] && selectedYears.includes(item.year)) {
                                                    this.setState({
                                                        selectedYears: selectedYears.filter(m => m !== item.year)
                                                    })
                                                }
                                            }} checked={this.state[item.year]} name="checkedG"/>}
                                        />
                                    </div>
                                    <div style={{
                                        position: "absolute", display: "flex", color: 'white',
                                        justifyContent: "center", alignItems: "center", height: 150, width: 150,
                                        fontFamily: 'Varela Round', cursor: "pointer"
                                    }}
                                         onClick={() => handleDirectory(item.year)}
                                    >
                                        {
                                            item.year
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
                }
                <Loader color={context.selectedTheme.color} open={loading}/>
            </div>
                )}
            </ThemeContext.Consumer>
        );
    }
}

const
    mapStateToProps = state => {
        return {
            profile: state.auth.profile,
            loadMonthsByYear: state.salaryReducer.loadIRsMonthsByYear,
            pdfSecurity: state.salaryReducer.pdfSecurity,
            hasDefaultPassword: state.salaryReducer.hasDefaultPassword,
            loading: state.salaryReducer.loading,
            lang: state.salaryReducer.lang
        };
    };
export default connect(mapStateToProps)(withStyles(styles)(IRsComponent));
