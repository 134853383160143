import React, {useEffect, useState} from 'react';
import {Button, Collapse, Grid, Chip, Fab, Tooltip, Select, MenuItem} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {reduxForm} from "redux-form";
import Alert from '@material-ui/lab/Alert';import {connect, useDispatch} from "react-redux";
import Box from "@material-ui/core/Box";
import {useStyles} from "./Step1";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "../../assets/backspace-icon-6 copie.png";

const Step3 = ({handleNext, handleBack, handleSubmit, listCodes, reset}) => {
    const classes = useStyles();
    const [codes, setCodes] = useState([]);
    const [error, setError] = useState(false);
    const [list, setList] = useState(listCodes);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(getAllCounters());
    //     dispatch(getAllEVs());
    // }, []);

    const handleChange = async value => {
        if (codes.length > 0 && codes[codes.length - 1].id !== undefined) {
            return;
        }
        const val = {};
        val.id = value;
        val.value = value;
        const filted = list.filter(e => e.code !== value);
        await codes.push(val);
        await setCodes(codes);
        await setList(filted);
        reset()
    };
    const inClick = async value => {
        console.log("handleClick")
        if (codes.length > 0 && isAnOperation(value) && isAnOperation(codes[codes.length - 1].value)) {
            console.log("ee")
            return;
        }
        if (codes.length > 0 && codes[codes.length - 1].id !== undefined && value !== ")" && !isAnOperation(value)) {
            console.log("ee")
            return;
        }
        if (codes.length > 0 && codes[codes.length - 1].value === ")" && !isAnOperation(value)) {
            console.log("ee")
            return;
        }
        const val = {};
        val.value = value;
        await codes.push(val);
        await setCodes(codes);
        reset();
    };
    const isAnOperation = value => {
        return value === "+" || value === "-" || value === "/" || value === "*" || value === "%";
    };
    const handleDelete = () => {
        if (codes.length > 0 && codes[codes.length - 1].id !== undefined) {
            list.push({code: codes[codes.length - 1].value})
        }
        codes.splice(-1);
        reset();
    };
    const handleError = () => {
        let i = 0;
        let k = 0;
        codes.map(code => {
            if (code.value === "(")
                i++;

            if (code.value === ")")
                k++;
        });

        return i !== k;
    };

    const test = () => {
        if (handleError()) {
            setError(true);
        } else {
            let formule = "";
            codes.map(code => {
                formule = formule + code.value;
            });
            console.log(formule);
            // handleNext();
        }

    };

    const onSubmit = () => {
        console.log('onSubmit')
    };


    return (
        <div container>
            <br/>
            <form style={{padding: '20px'}} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{paddingLeft: '6px'}}>
                        <FormControl variant={"outlined"} fullWidth>
                            <InputLabel>
                                Codes compteurs/Variables
                            </InputLabel>
                            <Select
                                labelId="x"
                                id="x"
                                label="Codes compteurs/Variables"
                                variant={"outlined"}
                                onChange={e => {
                                    handleChange(e.target.value).then(() => reset())
                                }}

                            >
                                <MenuItem value={""}>
                                    <em>-- SÉLÉCTIONNER --</em>
                                </MenuItem>
                                {list && list.map(val =>
                                    <MenuItem key={val.code} value={val.code}>{val.code}</MenuItem>
                                )}
                            </Select>

                        </FormControl>
                        <br/><br/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={8}>

                        <Paper style={{minHeight: "270px"}} className={classes.paper}>
                            <Collapse in={error}>
                                <Alert onClose={() => setError(false)} severity="error">Format incorrect — Merci de vérifier !</Alert>
                            </Collapse>
                            <div style={{ padding: "29px 0 0 29px"}}>
                                {codes && codes.map((code, index) => code.id ? <Chip label={code.value}
                                                                                     style={{
                                                                                         fontWeight: 'bold',
                                                                                         color: "#fff",
                                                                                         backgroundColor: "#2F80ED",
                                                                                         marginLeft: isAnOperation(code.value)
                                                                                         || (index > 0 && isAnOperation(codes[index - 1].value))
                                                                                             ? "10px" : "0px"
                                                                                     }}/> :
                                    <span style={{
                                        fontWeight: 'bold',
                                        marginLeft: isAnOperation(code.value) || (index > 0 && isAnOperation(codes[index - 1].value))
                                            ? "10px" : "0px"
                                    }}>
                                {code.value} </span>)}
                            </div>

                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper style={{minHeight: "270px", padding: "29px 10px 12px 35px"}} className={classes.paper}>
                           <button className={classes.btnn} onClick={() => inClick("1")}>1</button>
                           <button className={classes.btnn} onClick={() => inClick("2")}>2</button>
                           <button className={classes.btnn} onClick={() => inClick("3")}>3</button>
                           <button className={classes.btnn} onClick={() => inClick("+")}>+</button>
                            <br/>
                           <button className={classes.btnn} onClick={() => inClick("4")}>4</button>
                           <button className={classes.btnn} onClick={() => inClick("5")}>5</button>
                           <button className={classes.btnn} onClick={() => inClick("6")}>6</button>
                           <button className={classes.btnn} onClick={() => inClick("*")}>*</button>
                            <br/>
                           <button className={classes.btnn} onClick={() => inClick("7")}>7</button>
                           <button className={classes.btnn} onClick={() => inClick("8")}>8</button>
                           <button className={classes.btnn} onClick={() => inClick("9")}>9</button>
                           <button className={classes.btnn} onClick={() => inClick("-")}>-</button>
                            <br/>
                           <button className={classes.btnn} onClick={() => inClick("(")}>(</button>
                           <button className={classes.btnn} onClick={() => inClick("0")}>0</button>
                           <button className={classes.btnn} onClick={() => inClick(")")}>)</button>
                           <button className={classes.btnn} onClick={() => inClick("/")}>/</button>
                            <br/>
                           <button className={classes.btn1} onClick={handleDelete}>
                               <img src={DeleteIcon} width="21px" height="20px" alt=""/>
                            </button>
                           <button style={{ position: 'relative', top: "-7px"}} className={classes.btnn} onClick={() => setCodes([])}>CE</button>
                           <button  style={{ position: 'relative', top: "-7px"}} className={classes.btnn} onClick={() => inClick("%")}>%</button>
                        </Paper>
                    </Grid>
                </Grid>
                <br/><br/>
                <Box display="flex" justifyContent="flex-end">
                    <Button style={{marginRight: '10px'}} hidden type="submit"/>
                   <Button style={{marginRight: '10px'}} size={"medium"} variant="contained"
                            className={classes.button} onClick={handleBack}>Retour
                    </Button>
                   <Button size={"medium"} variant="contained"
                            className={classes.button} onClick={test}>Valider
                    </Button>

                </Box>
            </form>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        counters: state.counterReducer.codes,
        evs: state.evsReducer.codes,
        listCodes: [
            {code: "ASZ"}, {code: "ZAS"}, {code: "QSA"}, {code: "SDD"}
        ]
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: 'typeBlocks',
    enableReinitialize: true
})(Step3));
