import {getAxios, getCurrentUser} from "./AuthActions";
import {API} from "../api/Endpoints";
import {
    GET_ALL_COUNTRIES_FAIL,
    GET_ALL_COUNTRIES_SUCCESS, GET_ALL_EVS, GET_ALL_EVS_FAIL, GET_ALL_EVS_SUCCESS,
    GET_ALL_POP_FAIL,
    GET_ALL_POP_SUCCESS,
    SET_PAGES,
    REMOVE_EVS,
    SAVE_EVS,
    SAVE_EVS_FAIL,
    SAVE_EVS_SUCCESS,
    GET_DATA,
    INIT_DATA,
    SET_CRITERIA, EXPORT_EVS, GET_USERS_UOs_SUCCESS,GET_USERS_UOs_FAIL, EXPORT_EVS_FAIL, GET_ALL_UOs_SUCCESS, GET_ALL_UOs_FAIL
} from "../types/EvsTypes";
import Swal from "sweetalert2";
import {Toast} from "../utilities/ToastAlert";

export const getAllCountries = () => async dispatch => {
    const axios = getAxios();
    axios({
        url: `${API}/api/country/findAll`,
        method: 'get',
    })
        .then(response => {
            dispatch({
                type: GET_ALL_COUNTRIES_SUCCESS,
                result: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: GET_ALL_COUNTRIES_FAIL,
                result: []
            });
        })
};
export const getAllEVs = (filter, page) => async dispatch => {
    const axios = getAxios();
    dispatch({
        type: GET_ALL_EVS
    });
    axios({
        url: `${API}/myPaie/variable/findAll?page=${page}&size=10`,
        method: 'post',
        data: filter,
    })
        .then(response => {
            dispatch({
                type: GET_ALL_EVS_SUCCESS,
                result: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: GET_ALL_EVS_FAIL,
                result: []
            });
        })
};
export const exportAllEVs = () => async dispatch => {
    const axios = getAxios();
    dispatch({
        type: EXPORT_EVS
    });
    axios({
        url: `${API}/myPaie/variable/export`,
        method: 'get'
    })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'export_evs.csv');
            document.body.appendChild(link);
            link.click();
            Swal.fire({
                icon: 'success',
                text: 'Le fichier a bien été exporté',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 3500
            });
        })
        .catch(() => {
            dispatch({
                type: EXPORT_EVS_FAIL,
                result: []
            });
        })
};
export const removeEVs = id => async dispatch => {
    const axios = getAxios();
    const profile = getCurrentUser();
    const {userName} = profile;

    dispatch({
        type: REMOVE_EVS
    });
    axios({
        url: `${API}/myPaie/variable/delete?id=${id}&username=${userName}`,
        method: 'get',
    })
        .then(() => {
            Swal.fire({
                icon: 'success',
                text: 'OPÉRATION RÉUSSITE',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 2000
            });
        })
        .catch(() => {
            Swal.fire({
                icon: 'error',
                text: 'OPÉRATION ÉCHOUÉE',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 2000
            });
        })
};
export const saveEVs = (evs, action) => async dispatch => {
    const axios = getAxios();
    dispatch({
        type: SAVE_EVS
    });
    axios({
        url: action === 'add' ? `${API}/myPaie/variable/save` : `${API}/myPaie/variable/update`,
        method: 'post',
        data: evs
    })
        .then(() => {
            dispatch({
                type: SAVE_EVS_SUCCESS
            });

            Toast.fire({
                icon: 'success',
                title: 'Signed in successfully'
            });

            // Swal.fire({
            //     icon: 'success',
            //     showConfirmButton: false,
            //     text: 'OPÉRATION RÉUSSITE',
            //     backdrop: 'rgba(0,0,0,0.25)',
            //     timer: 2000
            // });
        })
        .catch(() => {
            dispatch({
                type: SAVE_EVS_FAIL
            });
            Swal.fire({
                icon: 'error',
                showConfirmButton: false,
                text: 'OPÉRATION ÉCHOUÉE',
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 2000
            });
        })
};
export const getAllPopulations = code => async dispatch => {
    const axios = getAxios();
    axios({
        url: `${API}/myPaie/population/findAll?codeCountry=${code}`,
        method: 'get',
    })
        .then(response => {
            dispatch({
                type: GET_ALL_POP_SUCCESS,
                result: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: GET_ALL_POP_FAIL,
                result: []
            });
        })
};
export const affectEVs = data => async dispatch => {
    const axios = getAxios();
    axios({
        url: `${API}/myPaie/variable/affectation`,
        method: 'post',
        data: data
    })
        .then((res) => {
            console.log(res);
            Swal.fire({
                icon: 'success',
                text: 'OPÉRATION RÉUSSITE',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 2000
            });
        })
        .catch(() => {
            Swal.fire({
                icon: 'error',
                text: 'OPÉRATION ÉCHOUÉE',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.25)',
                timer: 2000
            });
        })
};
export const getUOsByCountryCode = (page, code) => async dispatch => {
    const axios = getAxios();
    axios({
        url: `${API}/myPaie/uo/getUoByCountryCode?countryCode=${code}&page=${page}&size=10`,
        method: 'get',
    })
        .then(response => {
            dispatch({
                type: GET_ALL_UOs_SUCCESS,
                result: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: GET_ALL_UOs_FAIL,
                result: []
            });
        })
};
export const getUsersOfUOs = uoIds => async dispatch => {
    const axios = getAxios();
    axios({
        url: `${API}/myPaie/employe/getEmployeeByUoIds?uoIds=${uoIds}`,
        method: 'get'
    })
        .then(response => {
            dispatch({
                type: GET_USERS_UOs_SUCCESS,
                result: response.data
            });
        })
        .catch(error => {
            dispatch({
                type: GET_USERS_UOs_FAIL,
                result: []
            });
        })
};
export const setPage = page => {
    return {
        type: SET_PAGES,
        page
    };
};
export const getRowData = data => {
    return {
        type: GET_DATA,
        result: data
    };
};
export const init = () => {
    return {
        type: INIT_DATA
    };
};
export const setCriteria = values => async dispatch => {
    dispatch({
        type: SET_CRITERIA,
        values
    });
};
