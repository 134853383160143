import React from 'react';
import Lock from "../../assets/images/403.png";
import {Button} from "@material-ui/core";
import {logout} from "../../actions/AuthActions";

const AccessDenied = () => {
    return (
        <div style={{textAlign: 'center', position: 'relative', top: 180, left: 20}}>
            <img src={Lock} height="150px" width="150px" alt={'403'}/>
            <h1>Accès non autorisé</h1>
            <p style={{textAlign: 'center', fontSize: '20px'}}>Vous n'êtes pas autorisé à
                accéder à myPay.<br/>
                Veuillez vérifier avec l'administrateur.</p>
            <Button onClick={logout}
                    style={{color: '#fff', backgroundColor: '#2F80ED'}}><b>Déconnexion</b></Button>
        </div>
    );
};

export default AccessDenied;
