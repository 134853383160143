export const LOAD_IRS = 'LOAD_IRS';
export const LOAD_IRS_SUCCESS = 'LOAD_IRS_SUCCESS';
export const LOAD_IRS_FAIL = 'LOAD_IRS_FAIL';

const initialState = {
    loading: false,
    loaded: false,
    pdfSecurity: false
};

export default function salaryReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_DEFAULT_PASSWORD":
            return {
                ...state,
                hasDefaultPassword: true
            };
        case "SET_LANGUAGE":
            return {
                ...state,
                lang: action.lang
            };
        case "LOAD_MONTHS":
            return {
                ...state,
                loading: true,
                loaded: false
            };
        case "LOAD_MONTHS_SUCCESS":
            return {
                ...state,
                loadMonthsByYear: action.result.years,
                hasDefaultPassword: action.result.hasDefaultPassword,
                pdfSecurity: action.result.pdfSecurity,
                loading: false,
                loaded: true
            };
        case "LOAD_MONTHS_FAIL":
            return {
                ...state,
                loadMonthsByYear: [],
                // loadMonthsByYear: [{
                //     year: 2018,
                //     months: ['01', '02', '03']
                // }, {
                //     year: 2019,
                //     months: ['01', '03', '04', '02']
                // }, {
                //     year: 2020,
                //     months: ['01', '02']
                // }, {
                //     year: 2021,
                //     months: ['01', '03', '05', '04', '07', '02', '06']
                // }],
                pdfSecurity: false,
                loading: false,
                loaded: true
            };

        case LOAD_IRS:
            return {
                ...state,
                loading: true
            };
        case LOAD_IRS_SUCCESS:
            return {
                ...state,
                loadIRsMonthsByYear: action.result.years,
                hasDefaultPassword: action.result.hasDefaultPassword,
                pdfSecurity: action.result.pdfSecurity,
                loading: false
            };
        case LOAD_IRS_FAIL:
            return {
                ...state,
                loadIRsMonthsByYear: [],
                pdfSecurity: false,
                loading: false,
                loaded: true
            };

        case "DOWNLOAD":
            return {
                ...state,
                loading: true,
                loaded: false
            };
        case "DOWNLOAD_SUCCESS":
            return {
                ...state,
                loading: false,
                loaded: true
            };
        case "DOWNLOAD_FAIL":
            return {
                ...state,
                loading: false,
                loaded: true
            };
        default:
            return state;
    }
}
