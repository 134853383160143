import React from 'react'
import {Route, Switch} from 'react-router-dom'
import SalaryComponent from "../components/salary/SalaryComponent";
import PayrollEngineComponent from "../components/moteur/PayrollEngineComponent";
import EVsComponent from "../components/evs/EVsComponent";
import MyshiftModifsComponent from "../components/myshift/MyshiftModifsComponent";
import CounterComponent from "../components/counter/CounterComponent";
import CounterChoiceComponent from "../components/choice/CounterChoiceComponent";
import PayDetailComponent from "../components/moteur/PayDetailComponent";
import VariableChoiceComponent from "../components/choice/VariableChoiceComponent";
import AddCounter from "../components/counter/AddCounter";
import Launcher from "../components/launcher/Launcher";
import Modulation from "../components/modulation/Modulation";
import History from "../components/modulation/History";
import ExtractionByUser from "../components/export/ExtractionByUser";
import ExcludedSites from "../components/exclus/ExcludedSites";
import ExcludedUsers from "../components/exclus/ExcludedUsers";
import LauncherPeriodicity from "../components/launcher/LauncherPeriodicity";
import PreparePay from "../components/modulation/PreparePay";
import IRsComponent from "../components/IRs/IRsComponent";

export default () => {
    return (
        <div style={{overflow: 'scroll', height: 'calc(100vh - 64px)', paddingBottom: 25, paddingTop: 0, paddingLeft: 0}}>
            <Switch>
                <Route exact path="/" component={SalaryComponent}/>
                <Route exact path="/salary" component={SalaryComponent}/>
                <Route exact path="/paie" component={PayrollEngineComponent}/>
                <Route exact path="/paie_:id" component={PayDetailComponent}/>
                <Route exact path="/evs" component={EVsComponent}/>
                <Route exact path="/myshiftModifications" component={MyshiftModifsComponent}/>
                <Route exact path="/counters" component={CounterComponent}/>
                <Route exact path="/add_counter" component={AddCounter}/>
                <Route exact path="/counters_choice" component={CounterChoiceComponent}/>
                <Route exact path="/variables_choice" component={VariableChoiceComponent}/>
                <Route exact path="/exclus"/>
                <Route exact path="/launcher" component={Launcher}/>
                <Route exact path="/periodic_launch" component={LauncherPeriodicity}/>
                <Route exact path="/export" component={ExtractionByUser}/>
                <Route exact path="/modulation" component={Modulation}/>
                <Route exact path="/mod_history" component={History}/>
                <Route exact path="/excluded_uos" component={ExcludedSites}/>
                <Route exact path="/excluded_users" component={ExcludedUsers}/>
                <Route exact path="/inject_file" component={PreparePay}/>
                <Route exact path="/irs" component={IRsComponent}/>
            </Switch>
        </div>
    );
}
