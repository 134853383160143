import React, {useState, useCallback, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {API} from "../../api/Endpoints";
import {getAxios} from "../../actions/AuthActions";
import {setInputVal} from "../../actions/ExcludeActions";
import {useDispatch} from "react-redux";
import {Avatar, Grid} from "@material-ui/core";
import {profile} from "../../styles/CommonStyles";
import {CORE_API, DEAFULT_AVATAR_CORE_SERVER} from "../../api/Endpoints";

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
}

const Asynchronous = ({type, placeHolder, success}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [inputSearch, setInputSearch] = useState("");
    const {bigAvatar, padd_top_10} = profile;
    const dispatch = useDispatch();
    const loading = open && options.length === 0;
    const axios = getAxios();

    const debounceOnChange = useCallback(
        debounce(value => {
            setInputSearch(value);
        }, 400),
        []
    );

    function handleChange(value) {
        setInputValue(value);
        dispatch(setInputVal(value));
        debounceOnChange(value);
    }

    useEffect(() => {
        if (success) {
            setInputValue("");
            dispatch(setInputVal(""));
            debounceOnChange("");
        }
    }, [success]);


    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        if (type === 'users') {
            (async () => {
                axios({
                    url: `${API}/api/employe/getAllEmployeeByUserNameLike?p=0&q=${inputValue}&s=10`,
                    method: 'get',
                })
                    .then(res => {
                        const countries = res.data;

                        if (active) {
                            setOptions(countries);
                        }
                    });

            })();
        }

    }, [type, inputSearch]);
    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            id="asynchronous-demo"
            style={{width: 300, position: "relative", marginBottom: "16px"}}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            getOptionLabel={(option) => option.userName}
            options={options}
            loading={loading}
            autoComplete
            inputValue={inputValue}
            includeInputInList
            disableOpenOnFocus
            onChange={(event, newValue) => {
                setInputValue(newValue ? newValue.userName : '');
                dispatch(setInputVal(newValue ? newValue.userName : ''));
                debounceOnChange(newValue ? newValue.userName : '');
            }}
            renderInput={params => (
                <TextField
                    style={{width: "253px", marginBottom: "10px"}}
                    {...params}
                    label={placeHolder}
                    variant="standard"
                    onChange={event => handleChange(event.target.value)}
                />
            )}
            renderOption={option => {
                return <Grid>
                    {/*<Grid item xs={6}>*/}
                    {/*    <Avatar>*/}
                    {/*        <img style={{height: 60, marginTop: 10}}*/}
                    {/*             src={`${CORE_API}/${DEAFULT_AVATAR_CORE_SERVER}/${option.userName}`} alt=""/>*/}
                    {/*    </Avatar>*/}
                    {/*</Grid>*/}
                    <Grid item xs={6}>
                        {option.userName}
                    </Grid>
                </Grid>;
            }}
        />
    );
};

export default Asynchronous;
