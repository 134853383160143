import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Grid,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import {Button} from "@material-ui/core";
import {Field, reduxForm} from 'redux-form';
import Box from "@material-ui/core/Box";
import {strings} from "../../i18n/Strings";
import {connect} from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {renderDate, renderTextField} from "../../layout/renderComponents";
import moment from "moment";
import {renderSelectPop} from "../evs/SearchComponent";
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
    alert: {
        color: '#fff',
        background: 'linear-gradient(-45deg, #2F80ED, #56CCF2) !important',
    },
    fab: {
        background: 'linear-gradient(-45deg, #2F80ED, #19bfe4) !important',
        color: "#fff"
    },
    selectEmpty: {
        paddingBottom: "14px"
    },
    buttonProgress: {
        position: 'absolute',
        left: '90%',
        marginTop: 6,
        marginLeft: -19,
    },
    button: {
        background: 'linear-gradient(-45deg, #2F80ED, #2F80ED) !important',
        color: '#fff'
    },
    btnn: {
        background: 'linear-gradient(-45deg, #2F80ED, #2fb8ed) !important',
        color: '#fff',
        marginRight: 6,
        border: 'initial',
        cursor: 'pointer',
        width: "56px",
        padding: 10,
        marginBottom: 6,
        fontWeight: 'bold'
    },
    btn1: {
        background: 'linear-gradient(-45deg, #2F80ED, #2fb8ed) !important',
        color: '#fff',
        fontWeight: 'bold',
        marginRight: 6,
        border: 'initial',
        width: "118px",
        cursor: 'pointer',
        padding: 10,
        marginBottom: 6,
        height: '36px',
        position: 'relative',
        top: '1px'
    },
    chip: {fontWeight: 'bold', color: "#fff", backgroundColor: "#2F80ED"}
}));
const validate = (values, props) => {
    const errors = {};
    const requiredFields = [
        'pays',
        'population',
        'data',
        'label',
        'code'
    ];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });

    return errors;
};
const GreenCheckbox = withStyles({
    root: {
        color: '#2F80ED',
        '&$checked': {
            color: '#2F80ED',
        },
    },
    track: {
        background: "orange"
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
const muiTheme = createMuiTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "#2F80ED",
            },
            track: {
                color: '#2F80ED'
            },
            rail: {
                color: '#2F80ED'
            }
        }
    }
});

const Step1 = props => {
    const classes = useStyles();
    const [selectedStartDate, setSelectedStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [selectedEndDate, setSelectedEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [value, setValue] = React.useState([20, 37]);
    const {handleSubmit, reset, handleNext} = props;

    const valuetext = (value) => {
        return `${value}`;
    };

    const onSubmit = () => {};

    const onValid = () => {
        handleNext();
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleStartDateChange = (date) => {
        setSelectedStartDate(moment(date).format("YYYY-MM-DD"));
    };
    const handleEndDateChange = (date) => {
        setSelectedEndDate(moment(date).format("YYYY-MM-DD"));
    };


    return (
        <div container>
            <br/>
            <form style={{padding: '20px'}} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Field name="startDate" component={renderDate} handleDateChange={handleStartDateChange}
                               selectedDate={selectedStartDate} label="Date validité début"/>
                        <br/><br/>
                    </Grid>
                    <Grid item xs={4}>
                        <Field name="dateAffectation" component={renderDate} handleDateChange={handleEndDateChange}
                               selectedDate={selectedEndDate} label="Date validité fin"/>
                        <br/><br/>
                    </Grid>
                    <Grid item xs={4} style={{position: "relative", top: "22px"}}>
                        <Typography style={{color: "grey"}} id="range-slider" gutterBottom>
                            Intervalle :
                        </Typography>
                        <ThemeProvider theme={muiTheme}>
                            <Slider
                                value={value}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                                getAriaValueText={valuetext}
                            />
                        </ThemeProvider>
                        <br/><br/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Field name="code" label="Code compteur" variant={"outlined"}
                               component={renderTextField}/>
                        <br/><br/><br/>
                    </Grid>
                    <Grid item xs={6}>
                        <Field name="libelle" label="Libellé" variant={"outlined"}
                               component={renderTextField}/>
                        <br/><br/><br/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                            <InputLabel>
                                Unité
                            </InputLabel>
                            <Field name="unit" label="Unité" variant={"outlined"} values={[]}
                                   component={renderSelectPop}
                            />
                        </FormControl>
                        <br/><br/><br/>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                            <InputLabel>
                                Periodicité
                            </InputLabel>
                            <Field name="periodicity" variant={"outlined"} label="Periodicité" values={[]}
                                   component={renderSelectPop}
                            />
                        </FormControl>
                        <br/><br/><br/>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                            <InputLabel>
                                Affichage résultat
                            </InputLabel>
                            <Field name="displayResult" label="Affichage résultat" values={[]}
                                   variant={"outlined"} component={renderSelectPop}
                            />
                        </FormControl>
                        <br/><br/><br/>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl size={"small"} variant={"outlined"} fullWidth>
                            <InputLabel>
                                Etat
                            </InputLabel>
                            <Field name="state" label="Etat" values={[]}
                                   variant={"outlined"} component={renderSelectPop}
                            />
                        </FormControl>
                        <br/><br/><br/>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={3}>
                        <FormControlLabel
                            style={{marginTop: "10px"}}
                            control={<GreenCheckbox
                                // onChange={() => {}}
                                //checked={true}
                                name="arrondi"/>}
                            label="Blocs validés (O/N)"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            style={{marginTop: "10px"}}
                            control={<GreenCheckbox
                                // onChange={() => {}}
                                //checked={true}
                                name="arrondi"/>}
                            label="Heure nuit (O/N)"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            style={{marginTop: "10px"}}
                            control={<GreenCheckbox
                                // onChange={() => {}}
                                //checked={true}
                                name="arrondi"/>}
                            label="Jour férié (O/N)"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControlLabel
                            style={{marginTop: "10px"}}
                            control={<GreenCheckbox
                                // onChange={() => {}}
                                //checked={true}
                                name="arrondi"/>}
                            label="Jour de repos (O/N)"
                        />
                    </Grid>
                </Grid>
                <br/><br/>
                <Box display="flex" justifyContent="flex-end">
                    <Button hidden style={{marginRight: '10px'}} type="submit"/>
                    <Button style={{marginRight: '10px'}} size={"medium"} variant="contained"
                            className={classes.button} onClick={reset}>Réinitialiser</Button>
                    <Button size={"medium"} variant="contained"
                            className={classes.button} onClick={onValid} >Valider</Button>
                </Box>
            </form>
        </div>
    );
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(reduxForm({
    form: 'AddCompteur',
    enableReinitialize: true,
    validate
})(Step1));
