import React, {useEffect} from 'react';
import {Box, Divider, Grid, Tooltip, withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/AccountCircleOutlined";
import Asynchronous from "../Autocomplete/Asynchronous";
import SearchIcon from "@material-ui/icons/GroupAddOutlined";
import SearchIconn from "@material-ui/icons/SearchRounded";
import {ThemeContext} from "../../App";
import {addExcludedUsers, getAllSites, getAllUsers} from "../../actions/ExcludeActions";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {strings} from "../../i18n/Strings";
import {connect, useDispatch} from "react-redux";
import {reduxForm} from "redux-form";
import {styles} from "../../styles/CommonStyles";
import Tablex from "./Tablex";
import Loader from "../../layout/Loader";


export const useStyles = makeStyles((theme) => ({
    rooot: {
        '& .Mui-selected': {
            color: '#fff',
            background: 'linear-gradient(-45deg, #2F80ED, #56CCF2) !important',
        }
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    dialog: {
        position: 'absolute',
        top: 1
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

const ExcludedUsers = ({
                           classes, loading, users, inputVal, usersPage, sitesPage, success, totalUsersElements
                       }) => {

    const [inputValue, setInputValue] = React.useState("");
    const classess = useStyles();
    const {_title, _agentSearch, _add, _list, _search} = strings.userExclusion;
    const {
        _actions,
        _creationDate,
        _email,
        _entryDate,
        _firstname,
        _matricule,
        _lastname
    } = strings.columns.excluderUser;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllUsers("", 0));
    }, []);

    useEffect(() => {
        if (success) {
            setInputValue("");
        }
    }, [success]);


    const searchExcludedUser = async () => {
        dispatch(getAllUsers(inputVal, 0));
    };

    const addExcludedUser = async () => {
        await dispatch(addExcludedUsers(inputVal));
    };

    return (
        <ThemeContext.Consumer>
            {context => (
                <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <h1 style={{
                                marginTop: 1,
                                color: context.selectedTheme.color,
                                fontWeight: 'normal'
                            }}>{_title}</h1>
                        </Grid>
                    </Grid>
                    <hr color="#f5f5f5"/>
                    <br/>
                    <Box display="flex" justifyContent="flex-end">
                        <form style={{paddingTop: '20px', paddingBottom: '20px'}}>
                            <Paper component="form" className={classess.root}>
                                <IconButton style={{color: context.selectedTheme.color}} className={classess.iconButton}
                                            aria-label="menu" onClick={() => dispatch(getAllUsers('', 0))}>
                                    <Tooltip title={_list} aria-label="add">
                                        <MenuIcon/>
                                    </Tooltip>
                                </IconButton>
                                <Asynchronous success={success} placeHolder={_agentSearch} type={"users"}/>
                                <IconButton disabled={inputVal === ''} style={{color: context.selectedTheme.color}} className={classess.iconButton}
                                            aria-label="add" onClick={addExcludedUser}>
                                    <Tooltip title={_add} aria-label="add">
                                        <SearchIcon/>
                                    </Tooltip>
                                </IconButton>
                                <Divider className={classess.divider} orientation="vertical"/>
                                <IconButton style={{color: context.selectedTheme.color}} className={classess.iconButton}
                                            aria-label="directions"
                                            onClick={searchExcludedUser}>
                                    <Tooltip title={_search} aria-label="add">
                                        <SearchIconn/>
                                    </Tooltip>
                                </IconButton>
                            </Paper>
                        </form>
                    </Box>

                    <Tablex classes={classes} type={"users"} sitesPage={sitesPage} usersPage={usersPage}
                            rows={users} color={context.selectedTheme.color} columns={
                        [
                            {headerName: ''},
                            {headerName: _matricule},
                            {headerName: _firstname},
                            {headerName: _lastname},
                            {headerName: _email},
                            {headerName: _creationDate},
                            {headerName: _entryDate},
                            {headerName: _actions}
                        ]
                    } totalUsersElements={totalUsersElements}/>
                    <Loader color={context.selectedTheme.color} open={loading}/>
                </div>
            )}
        </ThemeContext.Consumer>
    );

};

const mapStateToProps = state => {
    return {
        loading: state.exclusReducer.loading,
        users: state.exclusReducer.users,
        totalUsersElements: state.exclusReducer.totalUsersElements,
        usersPage: state.exclusReducer.usersPage,
        inputVal: state.exclusReducer.inputVal,
        success: state.exclusReducer.success,
        lang: state.salaryReducer.lang
    };
};
export default connect(mapStateToProps)(reduxForm({
    form: 'excludedUser',
    enableReinitialize: true
})(withStyles(styles)(ExcludedUsers)));