import React, {useEffect, useState} from 'react';
import {
    Grid,
    Button,
    Box,
    Chip,
    FormControl,
    InputLabel,
    withStyles,
    Paper, Collapse,
} from "@material-ui/core";
import {DropzoneArea} from "material-ui-dropzone";
import {Field, Form, reduxForm} from "redux-form";
import {connect, useDispatch} from "react-redux";
import ImportIcon from '../../assets/images/import.png'
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import {renderSelectField} from "../moteur/PayrollEngineComponent";
import {
    getSheetsFromFile,
    importModulation,
    initSheets
} from "../../actions/LauncherActions";
import Loader from "../../layout/Loader";
import {ThemeContext} from "../../App";
import {strings} from "../../i18n/Strings";
import {alertx} from "../../utilities/Alert";
import {styles} from "../../styles/CommonStyles";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    chip: {
        '& .MuiChip-deleteIcon': {
            //color: props => props.context && props.context.color,
            color: '#fff',
        },

        '& .MuiChip-label': {
            fontSize: 14
        }
    },
    smDropzone: {
        minHeight: '45vh',
    },
    paragraph: {
        fontFamily: 'Roboto',
        fontSize: 26,
        color: '#575757'
    },
    previewChip: {
        minWidth: 160,
        maxWidth: 210
    }
});

const Modulation = (props) => {

    const {submitting, sheets, loading, reset, handleSubmit, periods, years, context} = props;
    const classes = useStyles(props);

    const [file, setFile] = useState(null);
    const [disable, setDisable] = useState(true);
    const [period, setPeriod] = useState('');
    const [date, setDate] = useState('');
    const [data, setData] = useState([]);

    const {_title, _file, _import, _msg, _period, _reset, _year, _pending} = strings.modulation;
    const dispatch = useDispatch();

    useEffect(() => {
        setData(sheets);
    }, [sheets]);

    const handleSelectFile = async file => {
        await dispatch(getSheetsFromFile(file[0]));
        setDisable(false);
        setFile(file[0]);
    };

    const handleDelete = async sheet => {
        let index = -1;
        for (const i in sheets) {
            if (sheets[i] === sheet) {
                index = i;
                console.log(i);
            }
        }
        sheets.splice(index, 1);
        setData([...sheets]);
    }

    const onUpload = async () => {
        dispatch(importModulation(file, data, period, date));
        init();
        await alertx('success', _pending);
    };

    const handlePreviewIcon = () => {
        return (<img style={{position: 'relative', top: '5vh'}} src={ImportIcon} width={200} alt="ss"/>)
    };

    const getChips = (elements, color) => {
        let rows = [];
        for (const i in elements) {
            rows.push(<Chip
                style={{marginTop: 10, marginRight: 10, color: '#fff', backgroundColor: color, border: `1px solid ${color}`}}
                key={i}
                label={elements[i].toUpperCase()}
                className={classes.chip}
                onDelete={() => handleDelete(elements[i])}
                variant="outlined"/>)
        }
        return rows
    }

    const init = () => {
        reset();
        setPeriod('');
        setDate('');
        setFile('');
        setDisable(true);
        dispatch(initSheets());
    }

    return (
        <ThemeContext.Consumer>
            {context => (
                <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
                    <Grid container>
                        <Grid item xs={6}>
                            <h1 style={{
                                marginTop: 1,
                                color: context.selectedTheme.color,
                                fontWeight: 'normal'
                            }}>{_title}</h1>
                        </Grid>
                    </Grid>
                    <hr color="#f5f5f5"/>
                    <br/><br/>
                    <Paper style={{width: '100%', paddingBottom: 40, margin: 'auto'}} elevation={1}>
                        <hr style={{display: 'none', width: '100%'}}
                            color={context.selectedTheme.color}
                            size="12"
                        />
                        <br/>
                        <Grid container style={{padding: 30}} spacing={2}>
                            <Grid item xs={6}>
                                <FormControl size={"small"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        {_period}
                                    </InputLabel>
                                    <Field name="modulationTypesDTO" label={_period} variant={'outlined'}
                                           values={periods} value={period}
                                           onChange={e => setPeriod(e.target.value)}
                                           component={renderSelectField}/>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl size={"small"} variant={"outlined"} fullWidth>
                                    <InputLabel>
                                        {_year}
                                    </InputLabel>
                                    <Field name="year" label={_year} variant={'outlined'}
                                           values={years} value={date} onChange={e => setDate(e.target.value)}
                                           component={renderSelectField}/>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container style={{padding: '0px 30px 20px 30px'}} spacing={2}>
                            <Grid item xs={12}>
                                <DropzoneArea
                                    onDrop={(file) => handleSelectFile(file)}
                                    onDelete={() => {
                                        dispatch(initSheets());
                                        setFile('');
                                        setDisable(true);
                                    }}
                                    filesLimit={1}
                                    dropzoneText={_file}
                                    showPreviews={false}
                                    showFileNamesInPreview={false}
                                    showFileNames={false}
                                    showPreviewsInDropzone={false}
                                    acceptedFiles={['.xlsx']}
                                    useChipsForPreview
                                    previewGridProps={{
                                        container: {
                                            style: {paddingTop: '40px', backgroundColor: '#eee'},
                                            spacing: 1,
                                            direction: 'row'
                                        }
                                    }}
                                    classes={{
                                        root: classes.smDropzone,

                                    }}
                                    dropzoneParagraphClass={classes.paragraph}
                                    previewChipProps={{classes: {root: classes.previewChip}}}
                                    Icon={handlePreviewIcon}
                                    getDropRejectMessage={() => _msg}
                                    previewText={""}
                                    showAlerts={false}
                                />
                                <br/>
                                <Collapse in={data && data.length > 0} timeout={300}>
                                    <div
                                        style={{
                                            borderColor: '#e0e0e0',
                                            padding: '20px 20px 22px 20px',
                                            textAlign: 'center',
                                            borderStyle: 'dashed'
                                        }}>
                                        {getChips(data, context.selectedTheme.color)}
                                    </div>
                                    <br/>
                                </Collapse>

                            </Grid>
                        </Grid>
                        <Box display="flex" justifyContent="end" style={{marginRight: 26}}>
                            <Form onSubmit={handleSubmit(onUpload)}>
                                <Button
                                    style={{
                                        marginRight: 10,
                                        color: '#fff',
                                        background: context.selectedTheme.color,
                                        borderColor: 'transparent'
                                    }}
                                    size={"large"}
                                    onClick={init}
                                    startIcon={<CancelIcon/>}>{_reset}
                                </Button>
                                <Button
                                    size={"large"}
                                    style={{
                                        marginRight: '5px',
                                        color: '#fff',
                                        background: context.selectedTheme.color,
                                        borderColor: 'transparent'
                                    }}
                                    variant={"outlined"}
                                    onClick={onUpload}
                                    startIcon={<CheckCircleIcon/>}
                                    disabled={period === '' || date === '' || disable || submitting}>{_import}
                                </Button>
                            </Form>
                        </Box>

                    </Paper>
                    <Loader color={context.selectedTheme.color} open={loading}/>
                </div>
            )}
        </ThemeContext.Consumer>
    );
}

const mapStateToProps = state => {
    return {
        periods: state.PayrollEngineReducer.periods,
        list: state.PayrollEngineReducer.listHistory,
        criteria: state.PayrollEngineReducer.criteria,
        years: state.PayrollEngineReducer.years,
        loading: state.PayrollEngineReducer.loading,
        sheets: state.PayrollEngineReducer.sheets,
        context: state.auth.context,
        lang: state.salaryReducer.lang
    };
};

export default connect(mapStateToProps)(reduxForm({
    form: 'import'
})(Modulation));