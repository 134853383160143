export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';
export const GET_ALL_SITES = 'GET_ALL_SITES';
export const GET_ALL_SITES_SUCCESS = 'GET_ALL_SITES_SUCCESS';
export const GET_ALL_SITES_FAIL = 'GET_ALL_SITES_FAIL';
export const SET_INPUT_VAL = 'SET_INPUT_VAL';
export const SET_USERS_PAGE = 'SET_USERS_PAGE';
export const SET_SITES_PAGE = 'SET_SITES_PAGE';
export const ADD_SITE = 'ADD_SITE';
export const ADD_SITE_SUCCESS = 'ADD_SITE_SUCCESS';
export const ADD_SITE_FAIL = 'ADD_SITE_FAIL';
