import {
    LOAD_IRS,
    LOAD_IRS_FAIL,
    LOAD_IRS_SUCCESS,
    LOAD_MONTHS,
    LOAD_MONTHS_FAIL,
    LOAD_MONTHS_SUCCESS,
    SET_DEFAULT_PASSWORD,
    SET_LANGUAGE
} from "../types/salaryTypes";
import {API} from "../api/Endpoints";
import {getAxios, getCurrentUser} from "./AuthActions";
import Swal from 'sweetalert2'
import moment from "moment";
import {strings} from "../i18n/Strings";

const handleDownload = (hasDefaultPassword, securePDF, filter, userName, firstName, email, irs) => async dispatch => {
    const {_attention, _submitBtn, _code, _msg, _warning, _continueBtn, _cancelBtn} = strings;
    const axios = getAxios();
    const downloadRequest = {
        browser: getBrowserName(),
        date: moment(new Date()).format("YYYY-MM-DD"),
        dates: filter,
        defaultPassword: true,
        firstName: firstName,
        hour: moment(new Date()).format("hh:mm"),
        mail: email,
        password: null,
        securePDF: securePDF,
        token: null,
        irs: irs
    };
    if (!securePDF) {
        Swal.fire({
            title: `${_attention}`,
            html: `<p style="font-size: 18px">${_warning}<p/>`,
            icon: 'warning',
            backdrop: 'rgba(0,0,0,0.25)',
            showCancelButton: true,
            confirmButtonColor: '#f0ad4e',
            cancelButtonColor: '#56CCF2',
            confirmButtonText: `${_continueBtn}`,
            cancelButtonText: `${_cancelBtn}`,

            customClass: {
                confirmButton: 'sweet_confirmbuttonImportant',
                cancelButton: 'sweet_confirmbuttonImportant',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios({
                    url: `${API}/api/v1/salary/download`,
                    method: 'post',
                    data: downloadRequest,
                    responseType: 'blob'
                })
                    .then(response => {
                        const url = URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', irs ? `IRS_Declaration_${moment(new Date()).format("YYYY-MM-DD")}.pdf` :
                            `Bulletin_${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            backdrop: 'rgba(0,0,0,0.25)',
                            text: 'Opération échouée!',
                            showConfirmButton: false,
                            timer: 3000

                        })
                    })
            }
        });
    }
    else {
        if (!hasDefaultPassword) {
            Swal.fire({
                icon: "question",
                title: `${_code}`,
                text: `${_msg}`,
                input: 'password',
                inputAttributes: {
                    autocapitalize: 'off',
                    autocorrect: 'off'
                },
                showCancelButton: true,
                backdrop: 'rgba(0,0,0,0.25)',
                cancelButtonColor: '#f0ad4e',
                confirmButtonColor: '#56CCF2',
                confirmButtonText: `${_submitBtn}`,
                cancelButtonText: `${_cancelBtn}`,
                customClass: {
                    confirmButton: 'sweet_confirmbuttonImportant',
                    cancelButton: 'sweet_confirmbuttonImportant',
                },
                showLoaderOnConfirm: true,
                preConfirm: (pwd) => {
                    return axios(`${API}/api/v1/salary/definePassword?password=${pwd}`,
                        {
                            method: 'POST',
                            body: {},
                        })
                        .then(response => {
                            if (response.status === 200) {
                                dispatch({
                                    type: SET_DEFAULT_PASSWORD
                                });
                                axios({
                                    url: `${API}/api/v1/salary/download`,
                                    method: 'post',
                                    data: downloadRequest,
                                    responseType: 'blob'
                                })
                                    .then(response => {
                                        const url = URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', irs ? `IRS_Declaration_${moment(new Date()).format("YYYY-MM-DD")}.pdf` :
                                            `Bulletin_${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
                                        document.body.appendChild(link);
                                        link.click();
                                    })
                                    .catch(error => {
                                        Swal.fire({
                                            icon: 'error',
                                            backdrop: 'rgba(0,0,0,0.25)',
                                            text: 'Opération échouée!',
                                            showConfirmButton: false,
                                            timer: 3000
                                        })
                                    })
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    text: 'Mot de passe non enregistré',
                                    backdrop: 'rgba(0,0,0,0.25)',
                                    showConfirmButton: false,
                                    timer: 3000
                                })
                            }
                        })
                        .catch(error => {
                            Swal.fire({
                                icon: 'error',
                                text: 'Opération échouée!',
                                backdrop: 'rgba(0,0,0,0.25)',
                                showConfirmButton: false,
                                timer: 3000
                            })
                        })
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
                .then((result) => {
                    if (result.isConfirmed) {
                    }
                });
        } else {
            axios({
                url: `${API}/api/v1/salary/download`,
                method: 'post',
                data: downloadRequest,
                responseType: 'blob'
            })
                .then(response => {
                    const url = URL.createObjectURL(new Blob([response.data], {type: "application/pdf"}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', irs ? `IRS_Declaration_${moment(new Date()).format("YYYY-MM-DD")}.pdf` :
                        `Bulletin_${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        backdrop: 'rgba(0,0,0,0.25)',
                        text: 'Opération échouée!',
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
        }

    }
};

export const loadMonthsByYear = (irs, force) => async dispatch => {
    const axios = getAxios();
    dispatch({
        type: LOAD_MONTHS
    });

    axios.get(`${API}/api/v1/salary/list?irs=${irs}&force=${force}`)
        .then(res => {
            dispatch({
                type: LOAD_MONTHS_SUCCESS,
                result: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: LOAD_MONTHS_FAIL
            });
        });
};

export const loadIRsByYear = (irs, force) => async dispatch => {
    const axios = getAxios();
    dispatch({
        type: LOAD_IRS
    });

    axios.get(`${API}/api/v1/salary/list?irs=${irs}&force=${force}`)
        .then(res => {
            dispatch({
                type: LOAD_IRS_SUCCESS,
                result: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: LOAD_IRS_FAIL
            });
        });
};


export const downloadPerYears = (years, loadMonthsByYear, hasDefaultPassword, securePDF, irs) => async dispatch => {
    let filter = [];

    for (let k in years) {
        const item = loadMonthsByYear.filter(y => y.year === years[k]);
        for (let i in item) {
            item[i].months.map(month => filter.push(years[k] + '-' + month));
        }
    }

    const profile = getCurrentUser();
    const {userName, firstName, email} = profile;
    dispatch(handleDownload(hasDefaultPassword, securePDF, filter, userName, firstName, email, irs));

};

export const download = (year, months, hasDefaultPassword, securePDF, irs) => async dispatch => {
    let filter = [];

    if (months === null) {
        filter.push(year);
    } else {
        for (let i in months) {
            filter.push(year + '-' + months[i]);
        }
    }

    const profile = getCurrentUser();
    const {userName, firstName, email} = profile;

    dispatch(handleDownload(hasDefaultPassword, securePDF, filter, userName, firstName, email, irs));
};

export const setLang = lang => async dispatch => {
    dispatch({
        type: SET_LANGUAGE,
        lang
    })
};


const getBrowserName = () => {
    // CHROME
    if (navigator.userAgent.indexOf("Chrome") !== -1) {
        return "Google Chrome";
    }
    // FIREFOX
    else if (navigator.userAgent.indexOf("Firefox") !== -1) {
        return "Mozilla Firefox";
    }
    // INTERNET EXPLORER
    else if (navigator.userAgent.indexOf("MSIE") !== -1) {
        return "Internet Exploder";
    }
    // EDGE
    else if (navigator.userAgent.indexOf("Edge") !== -1) {
        return "Internet Exploder";
    }
    // SAFARI
    else if (navigator.userAgent.indexOf("Safari") !== -1) {
        return "Safari";
    }
    // OPERA
    else if (navigator.userAgent.indexOf("Opera") !== -1) {
        return "Opera";
    }
    // YANDEX BROWSER
    else if (navigator.userAgent.indexOf("YaBrowser") !== -1) {
        return "YaBrowser";
    }
    // OTHERS
    else {
        return "Others";
    }
};
