import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import GroupAddIcon from '@material-ui/icons/ViewQuilt';
import VideoLabelIcon from '@material-ui/icons/Airplay';
import StepConnector from '@material-ui/core/StepConnector';
import {Grid, LinearProgress} from "@material-ui/core";
import {connect} from "react-redux";
import PaperComponent from "../../layout/PaperComponent";
import {Typography, Button} from '@material-ui/core';
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            background:
                'linear-gradient(-45deg, #2F80ED, #2fb8ed)  !important',
        },
    },
    completed: {
        '& $line': {
            background:
                'linear-gradient(-45deg, #2F80ED, #2fb8ed)  !important',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        background: 'linear-gradient(-45deg, #2F80ED, #2fb8ed) !important',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        background: 'linear-gradient(-45deg, #2F80ED, #2fb8ed) !important',
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    const icons = {
        1: <SettingsIcon/>,
        2: <GroupAddIcon/>,
        3: <FormatIndentIncreaseIcon/>,
        4: <VideoLabelIcon/>,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Données Compteur', 'Type Blocs', 'Formule', "Récap"];
}


const AddCounter = ({loading}) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const getStepContent = step => {
        switch (step) {
            case 0:
                return <Step1  handleNext={handleNext}/>;
            case 1:
                return <Step2 handleBack={handleBack} handleNext={handleNext}/>;
            case 2:
                return <Step3 handleBack={handleBack} handleNext={handleNext}/>;
            case 3:
                return 'Select campaign settings 4';
            default:
                return 'Unknown step';
        }
    };
    return (
        <div style={{height: '90vh', overflow: 'scroll', padding: 25}}>
            <Grid container>
                <Grid item xs={6}>
                    <h1 style={{
                        marginTop: 1,
                        color: '#2460A7FF'
                    }}>AJOUTER UN COMPTEUR</h1>
                </Grid>
            </Grid>
            {loading ? <LinearProgress classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary
            }}/> : <hr color="#f5f5f5"/>}
            <br/>
            <div className={classes.root} style={{width: '85%', margin: 'auto', padding: '5px 35px 2px 35px'}}>

                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <PaperComponent>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Button onClick={handleReset} className={classes.button}>
                                    Reset
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                            </div>
                        )}
                    </div>
                </PaperComponent>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        loading: state.counterReducer.loading
    };
};
export default connect(mapStateToProps)(AddCounter);
