import {
    GET_LAUNCH_DETAIL,
    GET_LAUNCH_DETAIL_FAIL,
    GET_LAUNCH_DETAIL_SUCCESS,
    GET_ROWS,
    EXPORT,
    EXPORT_SUCCESS,
    GET_SHEETS,
    EXPORT_FAIL,
    GET_ROWS_FAIL,
    VALIDATE_SUCCESS,
    VALIDATE_FAIL,
    VALIDATE,
    GET_HISTORY_FAIL,
    GET_HISTORY_SUCCESS,
    GET_HISTORY,
    GET_ROWS_SUCCESS, SET_FILTER, GET_LAUNCH_BY_ID,
    GET_LAUNCH_BY_ID_SUCCESS, GET_LAUNCH_BY_ID_FAIL, GET_SHEETS_SUCCESS, GET_SHEETS_FAIL
} from "../types/PayrollEngineTypes";

const initialState = {
    loading: false,
    filters: [],
    loaded: false,
    data: {},
    states: [
        {
            id: 1,
            code: 'R',
            value: 'EN COURS'
        }, {
            id: 2,
            code: 'E',
            value: 'TERMINÉ'
        }
    ],
    periods: [
        {
            id: 1,
            value: 'Janvier',
            code: '1'
        }, {
            id: 2,
            value: 'Février',
            code: '2'
        }, {
            id: 3,
            value: 'Mars',
            code: '3'
        }, {
            id: 4,
            value: 'Avril',
            code: '4'
        }, {
            id: 5,
            value: 'Mai',
            code: '5'
        }, {
            id: 6,
            value: 'Juin',
            code: '6'
        }, {
            id: 7,
            value: 'Juillet',
            code: '7'
        }, {
            id: 8,
            value: 'Août',
            code: '8'
        }, {
            id: 9,
            value: 'Septembre',
            code: '9'
        }, {
            id: 10,
            value: 'Octobre',
            code: '10'
        }, {
            id: 11,
            value: 'Novembre',
            code: '11'
        }, {
            id: 12,
            value: 'Décembre',
            code: '12'
        }
    ],
    years: [
        {
            id: 1,
            value: '2018',
            code: '2018'
        }, {
            id: 2,
            value: '2019',
            code: '2019'
        }, {
            id: 3,
            value: '2020',
            code: '2020'
        }, {
            id: 4,
            value: '2021',
            code: '2021'
        },
    ],
    status: [
        {
            id: 1,
            code: 'CREATED',
            value: 'EN ATTENTE DE VALIDATION'
        },
        {
            id: 2,
            code: 'VALIDATED',
            value: 'VALIDÉ'
        },
        {
            id: 3,
            code: 'REJECTED',
            value: 'REJETÉ'
        },
        {
            id: 4,
            code: 'DELETED',
            value: 'SUPPRIMÉ'
        }
    ],
    filter: {
        etat: null,
        periode: null,
        population: null,
        titre: null
    }, criteria: {
        uploadedBy: null,
        etatUpload: null,
        statut: null,
        sheetsUploaded: null
    }
};

export default function PayrollEngineReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ROWS:
            return {
                ...state,
                loading: true

            };
        case GET_ROWS_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.result.content,
                totalElements: action.result.totalElements,

            };
        case GET_ROWS_FAIL:
            return {
                ...state,
                loading: false,
                list: [],
                totalElements: 0
            };
        case GET_LAUNCH_DETAIL:
            return {
                ...state,
                loading: true
            };
        case GET_LAUNCH_DETAIL_SUCCESS:
            return {
                ...state,
                details: action.result,
                columns: action.result.length > 0 ? Object.keys(action.result[0]) : []

            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: false
            };
        case 'INIT_DETAILS':
            return {
                ...state,
                details: undefined,
                columns: undefined,
                filter: {}
            };
        case GET_LAUNCH_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                details: []
            };
        case SET_FILTER:
            return {
                ...state,
                filter: action.data
            };

        case EXPORT:
            return {
                ...state,
                loading: true
            };
        case EXPORT_SUCCESS:
            return {
                ...state,
                loading: false,


            };
        case EXPORT_FAIL:
            return {
                ...state,
                loading: false
            };

        case VALIDATE:
            return {
                ...state,
                loading: true
            };
        case VALIDATE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case VALIDATE_FAIL:
            return {
                ...state,
                loading: false
            };
        case GET_LAUNCH_BY_ID:
            return {
                ...state,
            };
        case GET_LAUNCH_BY_ID_SUCCESS:
            return {
                ...state,
                data: action.result
            };
        case GET_LAUNCH_BY_ID_FAIL:
            return {
                ...state,
                data: {}
            };
        case GET_SHEETS_SUCCESS:
            return {
                ...state,
                loading: false,
                sheets: action.result
            };
        case GET_SHEETS:
            return {
                ...state,
                loading: true
            };
        case GET_SHEETS_FAIL:
            return {
                ...state,
                loading: false,
                sheets: []
            };
        case GET_HISTORY:
            return {
                ...state,
                loading: true
            };
        case GET_HISTORY_SUCCESS:
            return {
                ...state,
                loading: false,
                listHistory: action.result.content
            };
        case GET_HISTORY_FAIL:
            return {
                ...state,
                loading: false,
                listHistory: []
            };
        case 'GET_ALL_FILTERS_SUCCESS':
            return {
                ...state,
                filters: action.result
            };
        case 'GET_ALL_FILTERS_FAIL':
            return {
                ...state,
                filters: [],
            };
        case 'GET_ALL_BY_FILTER_SUCCESS':
            return {
                ...state,
                loading: false,
                details: action.result,
                columns: action.result.length > 0 ? Object.keys(action.result[0]) : []
            };
        case 'GET_ALL_BY_FILTER_FAIL':
            return {
                ...state,
                loading: false,
                details: [],
                columns: []
            };

        default:
            return state;
    }
}
